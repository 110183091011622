import Details from './VoucherDetails'
import Form from './VoucherForm'
import Search from './VouchersSearch/VouchersSearch'

const Vouchers = {
  Details,
  Form,
  Search,
}

export default Vouchers
