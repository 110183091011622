import RewardCampaignsDetail from './RewardCampaignsDetail/RewardCampaignsDetail'
import RewardCampaignsList from './RewardCampaignsList'
import RewardCampaignsEdit from './RewardCampaignsEdit'
import RewardCampaignsForm from './RewardCampaignsForm'

const RewardCampaigns = {
  List: RewardCampaignsList,
  Details: RewardCampaignsDetail,
  Edit: RewardCampaignsEdit,
  New: RewardCampaignsForm,
}

export default RewardCampaigns
