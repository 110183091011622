import React, { useEffect } from 'react'
import { Table, Checkbox, Form, Input, Button, message, Tooltip, InputNumber } from 'antd'
import { FormListFieldData } from 'antd/lib/form/FormList'
import { FormInstance } from 'antd/lib/form/Form'
import Styled from 'styled-components'
import { CopyOutlined, InfoCircleOutlined } from '@ant-design/icons'

import { CommunicationStyleType, DiscountValueType, VoucherSetting } from '../../apollo/generated/api'
import { Plan, VoucherVariantFormField } from '../../types'
import { DiscountCalculatorProps, InputCalculatorResult } from '../../utils/discountCalculatorUtils/types'
import { toFixDecimal } from '../../utils/numberUtils/numberUtils'

const StyledButton = Styled(Button)`
  Border: none;
  padding: 0;
  height: 19px;
  width: 10px !important;
  background-color: transparent;
  .ant-btn-icon {
    color: #FADC5C;
  }`

const defaultStyle = { marginBottom: 0 }
type VoucherSettingVariantForm = FormInstance<{
  voucherSettings?: VoucherSetting[] | undefined
  voucherSettingVariants?: VoucherVariantFormField[][] | undefined
}>
interface Props {
  plan: Plan
  formInstance: VoucherSettingVariantForm
  duplicate?: boolean
  voucherSettingsRowIndex: number
  calculateVariantParams?: (
    payload: Omit<DiscountCalculatorProps, 'planPriceData'>,
    noOfMeals: number,
    noOfPersons: number,
  ) => InputCalculatorResult | null
}

function VouchersSettingsVariantForm({
  plan,
  formInstance,
  voucherSettingsRowIndex,
  calculateVariantParams,
}: Props): React.ReactElement {
  const fieldName = ['voucherSettingVariants', plan.index || 0]
  // const [discountLimitVisibilityState, setDiscountLimitVisibilityState] = useState<FieldVisibilityMap>({})
  const updatedVoucherSettings = Form.useWatch('voucherSettings', formInstance)

  const handleFieldsChange = async (
    changedFields: Omit<DiscountCalculatorProps, 'planPriceData'>,
    noOfMeals: number,
    noOfPersons: number,
    variantFieldIndex: number,
  ) => {
    const { calculatorInputValue } = changedFields
    if (!Number.isNaN(calculatorInputValue) && calculateVariantParams) {
      const paramsFromCalculator = await calculateVariantParams(changedFields, noOfMeals, noOfPersons)
      const variants = formInstance.getFieldValue(fieldName)
      if (paramsFromCalculator) {
        variants[variantFieldIndex] = {
          ...variants[variantFieldIndex],
          discountBoxPrice: toFixDecimal(paramsFromCalculator.discountBoxPrice),
          discountServingPrice: toFixDecimal(paramsFromCalculator.discountServingPrice),
          percentageOff: toFixDecimal(paramsFromCalculator.percentageOff),
          monetaryValueOff: toFixDecimal(paramsFromCalculator.monetaryValueOff),
          numberOfMeals: noOfMeals,
          numberOfPersons: noOfPersons,
        }
        formInstance.setFieldValue(fieldName, variants)
      }
    }
  }

  const updateVoucherSettingsWithPlanInfo = (settings: VoucherSetting[]) => {
    let paramsFromCalculator: InputCalculatorResult | null = null
    const variants = settings.map((setting, index) => {
      /**
       * Typeof formInstance.getFieldValue(fieldName) = Array
       */
      const variantsInForm = formInstance.getFieldValue(fieldName) // Will be empty in Edit mode

      if (Array.isArray(variantsInForm) && variantsInForm.length > 0 && variantsInForm[index]) {
        const formFields = variantsInForm[index]
        if (calculateVariantParams) {
          paramsFromCalculator = calculateVariantParams(
            {
              calculatorInputType: DiscountValueType.Percentage
                ? CommunicationStyleType.PercentageOff
                : CommunicationStyleType.MonetaryValueOff,
              calculatorInputValue: formFields?.percentageOff,
            },
            parseInt(plan.numberOfMeals, 10),
            parseInt(plan.numberOfPersons, 10),
          )
        }

        return {
          id: formFields.id,
          discountBoxPrice: paramsFromCalculator?.discountBoxPrice ?? 0,
          discountServingPrice: paramsFromCalculator?.discountServingPrice ?? 0,
          percentageOff: paramsFromCalculator?.percentageOff ?? 0,
          monetaryValueOff: paramsFromCalculator?.monetaryValueOff ?? 0,
          freeShipping: formFields?.freeShipping ?? setting?.freeShipping,
          numberOfMeals: plan.numberOfMeals,
          numberOfPersons: plan.numberOfPersons,
          ordinality: setting.ordinality,
          valueLimit: formFields?.valueLimit,
        }
      }
      if (calculateVariantParams) {
        paramsFromCalculator = calculateVariantParams(
          {
            calculatorInputType:
              setting.valueType === DiscountValueType.Percentage
                ? CommunicationStyleType.PercentageOff
                : CommunicationStyleType.MonetaryValueOff,
            calculatorInputValue: setting.value,
          },
          parseInt(plan.numberOfMeals, 10),
          parseInt(plan.numberOfPersons, 10),
        )
      }

      // If there aren't existing variants, they should not have any ids
      return {
        discountBoxPrice: paramsFromCalculator?.discountBoxPrice ?? 0,
        discountServingPrice: paramsFromCalculator?.discountServingPrice,
        percentageOff: paramsFromCalculator?.percentageOff ?? 0,
        monetaryValueOff: paramsFromCalculator?.monetaryValueOff ?? 0,
        freeShipping: setting?.freeShipping,
        numberOfMeals: plan.numberOfMeals,
        numberOfPersons: plan.numberOfPersons,
        ordinality: setting.ordinality,
      }
    })
    formInstance.setFieldValue(fieldName, variants)
  }

  const flattenedVoucherSettingsDependency = `${voucherSettingsRowIndex}-${updatedVoucherSettings
    ?.map(
      setting =>
        `${setting.id}:${setting.voucherSettingVariants
          ?.map(variant => variant?.id)
          .sort()
          .join('|')}`,
    )
    .join(';')}`

  useEffect(() => {
    // Should not update if the voucher setting is deleted
    if (voucherSettingsRowIndex === -1 && updatedVoucherSettings) {
      updateVoucherSettingsWithPlanInfo(updatedVoucherSettings)
    }
    if (voucherSettingsRowIndex > -1) {
      const variants = formInstance.getFieldValue(fieldName)
      variants.splice(voucherSettingsRowIndex, 1)
      formInstance.setFieldValue(fieldName, variants)
    }
  }, [flattenedVoucherSettingsDependency])

  const copyToClipboard = (text: string) => {
    if (window.isSecureContext) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      navigator.clipboard.writeText(text).then(() => message.success('Copied'))
    }
  }

  const valueLimitTooltipText =
    // eslint-disable-next-line max-len
    'Please note that plan-based discounts are always saved as percentages and apply to premium recipes as well. The value limit is here to control the discount with a fixed limit for both box price and premium recipes. It is not the same as "$ off".'

  return (
    <Form.List name={fieldName}>
      {fields => (
        <Table key={fieldName.join('')} dataSource={fields} pagination={false}>
          <Table.Column
            title="Ordinal"
            key="testing"
            render={(_, value: FormListFieldData, index: number) => {
              return (
                <>
                  <Form.Item
                    {...value}
                    key={`${value.key}-numberOfMeals`}
                    name={[value.name, 'numberOfMeals']}
                    style={defaultStyle}
                    hidden
                  >
                    <Input value={plan.numberOfMeals} />
                  </Form.Item>

                  <Form.Item
                    {...value}
                    key={`${value.key}-numberOfPersons`}
                    name={[value.name, 'numberOfPersons']}
                    style={defaultStyle}
                    hidden
                  >
                    <Input value={plan.numberOfPersons} />
                  </Form.Item>

                  <Form.Item {...value} name={[value.name, 'ordinality']} style={defaultStyle} hidden>
                    <Input />
                  </Form.Item>
                  <span>Order #{index + 1}</span>
                </>
              )
            }}
          />
          <Table.Column
            title="Promotion box price"
            key="discountBoxPrice"
            render={(_, value: FormListFieldData, index: number) => (
              <Form.Item {...value} name={[value.name, 'discountBoxPrice']} style={defaultStyle} shouldUpdate>
                <InputNumber
                  onChange={value =>
                    handleFieldsChange(
                      {
                        calculatorInputType: CommunicationStyleType.DiscountBoxPrice,
                        calculatorInputValue: Number(value?.toFixed(2)),
                      },
                      parseInt(plan.numberOfMeals, 10),
                      parseInt(plan.numberOfPersons, 10),
                      index,
                    )
                  }
                  min={0}
                  style={{ width: '100%' }}
                  addonAfter={
                    <StyledButton
                      onClick={() => copyToClipboard(formInstance.getFieldValue(fieldName)?.[index].discountBoxPrice)}
                      icon={<CopyOutlined />}
                    />
                  }
                />
              </Form.Item>
            )}
          />
          <Table.Column
            title="Promotion Serving price"
            key="discountServingPrice"
            render={(_, value: FormListFieldData, index: number) => (
              <Form.Item {...value} name={[value.name, 'discountServingPrice']} shouldUpdate style={defaultStyle}>
                <InputNumber
                  onChange={value =>
                    handleFieldsChange(
                      {
                        calculatorInputType: CommunicationStyleType.DiscountServingPrice,
                        calculatorInputValue: Number(value?.toFixed(2)),
                      },
                      parseInt(plan.numberOfMeals, 10),
                      parseInt(plan.numberOfPersons, 10),
                      index,
                    )
                  }
                  min={0}
                  style={{ width: '100%' }}
                  addonAfter={
                    <StyledButton
                      onClick={() =>
                        copyToClipboard(formInstance.getFieldValue(fieldName)?.[index].discountServingPrice)
                      }
                      icon={<CopyOutlined />}
                    />
                  }
                />
              </Form.Item>
            )}
          />
          <Table.Column
            title="$ Off"
            key="monetaryValueOff"
            render={(_, value: FormListFieldData, index: number) => (
              <Form.Item {...value} name={[value.name, 'monetaryValueOff']} style={defaultStyle} shouldUpdate>
                <InputNumber
                  onChange={value =>
                    handleFieldsChange(
                      {
                        calculatorInputType: CommunicationStyleType.MonetaryValueOff,
                        calculatorInputValue: Number(value?.toFixed(2)),
                      },
                      parseInt(plan.numberOfMeals, 10),
                      parseInt(plan.numberOfPersons, 10),
                      index,
                    )
                  }
                  min={0}
                  style={{ width: '100%' }}
                  addonAfter={
                    <StyledButton
                      onClick={() => copyToClipboard(formInstance.getFieldValue(fieldName)?.[index].monetaryValueOff)}
                      icon={<CopyOutlined />}
                    />
                  }
                />
              </Form.Item>
            )}
          />
          <Table.Column
            title="% Off"
            key="percentageOff"
            render={(_, value: FormListFieldData, index: number) => (
              <Form.Item {...value} name={[value.name, 'percentageOff']} style={defaultStyle} shouldUpdate>
                <InputNumber
                  min={0}
                  onChange={value =>
                    handleFieldsChange(
                      {
                        calculatorInputType: CommunicationStyleType.PercentageOff,
                        calculatorInputValue: Number(value?.toFixed(2)),
                      },
                      parseInt(plan.numberOfMeals, 10),
                      parseInt(plan.numberOfPersons, 10),
                      index,
                    )
                  }
                  style={{ width: '100%' }}
                  addonAfter={
                    <StyledButton
                      onClick={() => copyToClipboard(formInstance.getFieldValue(fieldName)?.[index].percentageOff)}
                      icon={<CopyOutlined />}
                    />
                  }
                />
              </Form.Item>
            )}
          />
          <Table.Column
            title={
              <>
                Discount limit
                <Tooltip title={valueLimitTooltipText}>
                  <StyledButton style={{ margin: '0 2px' }} icon={<InfoCircleOutlined />} />
                </Tooltip>
              </>
            }
            key="valueLimit"
            render={(_, value: FormListFieldData, index: number) => (
              <Form.Item {...value} name={[value.name, 'valueLimit']} style={defaultStyle} shouldUpdate>
                <Input
                  min={0}
                  style={{ width: '100%' }}
                  addonAfter={
                    <StyledButton
                      onClick={() => copyToClipboard(formInstance.getFieldValue(fieldName)?.[index].valueLimit)}
                      icon={<CopyOutlined />}
                    />
                  }
                />
              </Form.Item>
            )}
          />
          <Table.Column
            title="Free shipping"
            align="center"
            render={(_, value: FormListFieldData) => (
              <Form.Item {...value} name={[value.name, 'freeShipping']} style={defaultStyle} valuePropName="checked">
                <Checkbox />
              </Form.Item>
            )}
          />
        </Table>
      )}
    </Form.List>
  )
}

export default VouchersSettingsVariantForm
