import Styled from 'styled-components'

export const Container = Styled.div`
  display: flex;
  flex-direction: column;
  .new-campaign-btn {
    width: fit-content;
    align-self: end;
    margin-top: 12px;
    margin-bottom: 12px;
    color: black;
  }
`
