/* eslint-disable no-unused-vars */
import jwtDecode from 'jwt-decode'
import axios from 'axios'

import config from '../../config'
import { CHILLI_USER_SESSION } from '../../constants/AuthConstants'
import { getLocalStorage, setLocalStorage, clearAppLocalStorageData } from '../localStorageUtils'
import Stage from '../../constants/StagesConstants'
import { UserRoleType } from '../../apollo/generated/api'

export enum SessionMessages {
  InvalidUserSession = "You aren't logged in or your session has expired.",
  UserLoggedOut = "You've logged out successfuly.",
  LoginFailed = "There was an error during your user's authentication.",
}

export type SessionInformation = {
  token: {
    value: string
    exp: number
  }
  user: {
    email: string
    givenName: string
    picture: string
  }
}

export type UserSession = {
  hasValidSession: () => boolean
  session: SessionInformation | null
}

export const getUserSession = (): UserSession => {
  const session = getLocalStorage<SessionInformation>({ key: CHILLI_USER_SESSION, parse: true })
  const now = Math.floor(new Date().getTime() / 1000)
  const hasValidSession = (): boolean => !!session && session?.token.exp > now

  return { hasValidSession, session }
}

type ApiTokenClaims = {
  exp: number
  scopes: string[]
}

const createUserSession = (apiToken: string, user: SessionInformation['user']): UserSession => {
  const token = jwtDecode<ApiTokenClaims>(apiToken)
  const userSession = {
    token: {
      value: apiToken,
      exp: token.exp,
    },
    user,
  }

  setLocalStorage<SessionInformation>({
    key: CHILLI_USER_SESSION,
    value: userSession,
    stringify: true,
  })

  return getUserSession()
}

export const destroyUserSession = (): void => clearAppLocalStorageData()

const REQUEST_TIMEOUT = 10000

export const authenticate = async (googleToken: string): Promise<UserSession> => {
  // eslint-disable-next-line camelcase
  const decoded = jwtDecode<{ email: string; given_name: string; picture: string; exp: number }>(googleToken)
  const user = {
    email: decoded.email,
    givenName: decoded.given_name,
    picture: decoded.picture,
  }
  const apiResponse = await axios.post<{ token: string }>(
    `${config.restApiBaseUrl}/auth`,
    { token: googleToken },
    { timeout: REQUEST_TIMEOUT },
  )
  const apiToken = apiResponse.data.token
  const userSession = createUserSession(apiToken, user)

  return userSession
}

export const getUserRoles = (): UserRoleType[] => {
  const apiToken = getUserSession().session?.token.value

  if (!apiToken) {
    return []
  }

  // eslint-disable-next-line camelcase
  const tokenData = jwtDecode<{ user_roles: string[] }>(apiToken)

  return tokenData.user_roles.map(role => role.toUpperCase() as UserRoleType)
}

export const canAccessRoleFeatures = (requiredRole: UserRoleType): boolean => {
  const isRestrictedEnv = String(config.stage) === Stage.PRODUCTION
  const userRoles = getUserRoles()
  // User is authorized for a feature on production if they are a super admin,
  // or if their roles include the role required to access that feature
  const userIsAuthorized = [UserRoleType.SuperAdmin, requiredRole].some(role => userRoles.includes(role))

  if (isRestrictedEnv && !userIsAuthorized) {
    return false
  }

  return true
}
