import { Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import { generatePath, useHistory } from 'react-router'

import { handleMutationResult } from '../../apollo'
import { useCreateCampaignTemplateMutation, VoucherBatchTemplateInput, VoucherInput } from '../../apollo/generated/api'
import { Routes } from '../../constants/RoutesConstants'
import { buildCreateCampaignTemplatePayload, CampaignTemplateFormData } from '../../utils/campaignTemplateUtils'
import CampaignsForm from '../Campaigns/CampaignsForm/CampaignsForm'
import { ErrorPayload } from '../Error/types'
import VoucherTemplate from '../Vouchers/VoucherTemplate'

interface Props {
  shouldBlockNavigation: (value: boolean) => void
}

const CampaignTemplateCreate: React.FC<Props> = ({ shouldBlockNavigation }) => {
  const history = useHistory()
  const [fieldErrors, setFieldErrors] = useState<ErrorPayload>()
  const [showModal, setShowModal] = useState<boolean>(false)
  const [campaignTemplateData, setCampaignTemplateData] = useState<CampaignTemplateFormData>()
  const [createCampaignTemplate, { loading: creating, data: createCampaignTemplateData }] =
    useCreateCampaignTemplateMutation()
  const handleSubmit = (values: CampaignTemplateFormData) => {
    shouldBlockNavigation?.(false)
    setCampaignTemplateData(values)
    setShowModal(true)
  }

  const insert = async (values: CampaignTemplateFormData) => {
    const mutation = createCampaignTemplate({ variables: { input: buildCreateCampaignTemplatePayload(values) } })

    const { data } = await handleMutationResult(mutation, 'createCampaignTemplate', {
      notifications: {
        success: {
          title: `Template Created`,
        },
      },
    })

    if (data?.createCampaignTemplate?.successful) {
      const id = data.createCampaignTemplate.result?.id

      if (id) {
        history.push(generatePath(Routes.CAMPAIGN_TEMPLATE_DETAIL, { id }))
      }
    }
  }

  const onVoucherCreateHandler = (value: VoucherInput | VoucherBatchTemplateInput, hasMultipleVouchers: boolean) => {
    if (campaignTemplateData) {
      if (hasMultipleVouchers) {
        void insert({ ...campaignTemplateData, voucherBatch: value as VoucherBatchTemplateInput })
      } else {
        void insert({ ...campaignTemplateData, voucher: value as VoucherInput })
      }
    }
  }

  const handleValueChange = (e: any) => {
    shouldBlockNavigation?.(true)

    if (fieldErrors) {
      delete fieldErrors[Object.keys(e)[0]]
    }
  }

  useEffect(() => {
    let errorMessages: ErrorPayload = {}
    const mutationResponse = createCampaignTemplateData?.createCampaignTemplate
    if (mutationResponse && !mutationResponse.successful) {
      mutationResponse.messages?.forEach(message => {
        if (message?.__typename === 'ValidationMessage') {
          errorMessages = {
            ...errorMessages,
            [(message.field as string) || '']: message.message || '',
          }
        }
      })
      setFieldErrors(errorMessages)
    }
  }, [createCampaignTemplateData])

  return (
    <>
      <CampaignsForm<CampaignTemplateFormData, undefined>
        onSubmitForm={handleSubmit}
        onValuesChange={handleValueChange}
        fieldErrors={fieldErrors}
        isLoading={creating}
        formType="Template"
      />
      <Modal
        title="Add vouchers"
        closable={false}
        open={showModal}
        onCancel={() => setShowModal(false)}
        footer={null}
        width="70%"
      >
        <VoucherTemplate handleSubmit={onVoucherCreateHandler} />
      </Modal>
    </>
  )
}

export default CampaignTemplateCreate
