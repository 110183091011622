import { PageHeader } from '@ant-design/pro-components'
import { Form, Row, Col, Input, Table, Descriptions, InputRef } from 'antd'
import React, { useState, useRef } from 'react'
import { generatePath, useHistory, useParams } from 'react-router'
import { useForm } from 'antd/lib/form/Form'
import Styled from 'styled-components'

import NotFound from '../../packages/UnauthorizedComponentError'
import {
  CampaignTemplate,
  DiscountValueType,
  Maybe,
  MultipleCampaign,
  MultipleCampaignInput,
  PlatformType,
  useCampaignTemplateQuery,
  useCreateMultipleCampaignsMutation,
} from '../../apollo/generated/api'
import { DatePicker } from '../Inputs'
import BreadCrumbs from '../Layout/breadcrumbs'
import { presentDeliveryDays } from '../../utils/campaignUtils'
import BrandDropdown from '../BrandDropdown'
import CountryDropdown from '../CountryDropdown'
import { PrimaryButton, SecondaryButton } from '../globalStyles'
import { handleMutationResult } from '../../apollo'
import { Routes } from '../../constants/RoutesConstants'

import CampaignsGeneratorErrorModal from './CampaignsGeneratorErrorModal'

const RedText = Styled.span`
&& {
  color: red;
}
`
type RowType = {
  campaignName: JSX.Element
  brand: JSX.Element
  country: JSX.Element
  startDate: JSX.Element
  endDate: JSX.Element
  prefix?: JSX.Element
  separator?: JSX.Element
  suffix?: JSX.Element
  voucherCode?: JSX.Element
}

const NewCampaignFields = (index: number, addVoucherBatch: boolean, voucherCode?: string): RowType => ({
  campaignName: (
    <Form.Item name={`name_${index}`} labelCol={{ span: 24 }} rules={[{ required: true, message: 'required' }]}>
      <Input placeholder="Campaign Name" />
    </Form.Item>
  ),
  brand: <BrandDropdown required index={index} label={null} />,
  country: <CountryDropdown required index={index} label={null} />,
  startDate: (
    <Form.Item name={`startDate_${index}`} labelCol={{ span: 24 }} rules={[{ required: true, message: 'required' }]}>
      <DatePicker
        aria-required
        style={{ width: '100%' }}
        className="start-date-calendar"
        placeholder="Select a Start Date"
        format="YYYY-MM-DD"
      />
    </Form.Item>
  ),
  endDate: (
    <Form.Item name={`endDate_${index}`} labelCol={{ span: 24 }} rules={[{ required: true, message: 'required' }]}>
      <DatePicker
        style={{ width: '100%' }}
        className="end-date-calendar"
        placeholder="Select a End Date"
        format="YYYY-MM-DD"
      />
    </Form.Item>
  ),
  ...(voucherCode && {
    voucherCode: (
      <Form.Item
        name={`voucherCode_${index}`}
        labelCol={{ span: 24 }}
        initialValue={voucherCode}
        rules={[{ required: true, message: 'required' }]}
      >
        <Input placeholder="Voucher Code" />
      </Form.Item>
    ),
  }),
  ...(addVoucherBatch && {
    prefix: (
      <Form.Item
        name={`voucherCodePrefix_${index}`}
        labelCol={{ span: 24 }}
        rules={[{ required: true, message: 'required' }]}
      >
        <Input placeholder="Prefix" />
      </Form.Item>
    ),
    separator: (
      <Form.Item
        name={`voucherCodeSeparator_${index}`}
        labelCol={{ span: 24 }}
        rules={[{ required: true, message: 'required' }]}
      >
        <Input placeholder="Separator" />
      </Form.Item>
    ),
    suffix: (
      <Form.Item
        name={`voucherCodeSuffix_${index}`}
        labelCol={{ span: 24 }}
        rules={[{ required: true, message: 'required' }]}
      >
        <Input placeholder="Suffix" />
      </Form.Item>
    ),
  }),
})

const CampaignsGenerator: React.FC = () => {
  const history = useHistory()
  const { id } = useParams<{ id: string }>()
  const [form] = useForm()
  const [campaigns, setCampaigns] = useState<RowType[]>([])
  const numberOfCampaigns = useRef<InputRef>(null)
  const campaignsDescription = useRef<InputRef>(null)
  const [multipleCampaignsMutationError, setMultipleCampaignsMutationError] = useState<MultipleCampaign>()

  const { data: campaignTemplateData, error: campaignTemplateError } = useCampaignTemplateQuery({
    variables: { id },
    fetchPolicy: 'cache-first',
  })
  const [createMultipleCampaigns] = useCreateMultipleCampaignsMutation()

  if (campaignTemplateError || (campaignTemplateData && campaignTemplateData.campaignTemplate === null)) {
    return <NotFound message="Campaign Template not found" />
  }

  const template = campaignTemplateData?.campaignTemplate
  const voucher = template?.voucher ?? undefined
  // eslint-disable-next-line no-unused-expressions
  template &&
    form.setFieldsValue({
      templateName: template?.name,
      targetGroup: template?.targetGroup,
      platform: template?.platform,
      ...(voucher && { voucherCode: voucher.code }),
      deliveryDays: presentDeliveryDays(template.deliveryDays as string[]),
    })

  const insertVoucherBatchIfExist = () =>
    template?.voucherBatch
      ? [
          {
            title: (
              <>
                Prefix <RedText>*</RedText>
              </>
            ),
            dataIndex: 'prefix',
            key: 'prefix',
          },
          {
            title: (
              <>
                separator <RedText>*</RedText>
              </>
            ),
            dataIndex: 'separator',
            key: 'separator',
          },
          {
            title: (
              <>
                Suffix <RedText>*</RedText>
              </>
            ),
            dataIndex: 'suffix',
            key: 'suffix',
          },
        ]
      : []

  const insertVouchdeCodeIfExist = () =>
    voucher?.code
      ? [
          {
            title: (
              <>
                Code <RedText>*</RedText>
              </>
            ),
            dataIndex: 'voucherCode',
            key: 'voucherCode',
          },
        ]
      : []

  const campaignsTableColumn = [
    {
      title: (
        <>
          Campaign name <RedText>*</RedText>
        </>
      ),
      dataIndex: 'campaignName',
      key: 'name',
    },
    {
      title: (
        <>
          Brand <RedText>*</RedText>
        </>
      ),
      dataIndex: 'brand',
      key: 'brand',
    },
    {
      title: (
        <>
          Country <RedText>*</RedText>
        </>
      ),
      dataIndex: 'country',
      key: 'country',
    },
    {
      title: (
        <>
          Start Date <RedText>*</RedText>
        </>
      ),
      dataIndex: 'startDate',
      key: 'startDate',
    },
    {
      title: (
        <>
          End Date <RedText>*</RedText>
        </>
      ),
      dataIndex: 'endDate',
      key: 'endDate',
    },
    ...insertVoucherBatchIfExist(),
    ...insertVouchdeCodeIfExist(),
  ]

  const rowBuilder = (n: number, voucherCode?: string) => {
    const rows: RowType[] = []
    if (n > campaigns.length) {
      const rowsToBeCreated = n - campaigns.length
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < rowsToBeCreated; i++) {
        rows.push(NewCampaignFields(campaigns.length + i + 1, !!template?.voucherBatch, voucherCode))
      }

      setCampaigns(prevState => [...prevState, ...rows])
    }
    if (n < campaigns.length) {
      const rowsTobeDeleted = campaigns.length - n
      const rows: RowType[] = [...campaigns]
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < rowsTobeDeleted; i++) {
        rows.pop()
      }

      setCampaigns(rows)
    }
  }

  const onFormSubmit = async (values: { [key: string]: string }) => {
    if (campaignTemplateData?.campaignTemplate) {
      const mutation = createMultipleCampaigns({
        variables: { input: { campaigns: buildMutationPayload(values, campaignTemplateData?.campaignTemplate) } },
      })

      const { data } = await handleMutationResult(mutation, 'createMultipleCampaign', {
        notifications: {
          success: {
            title: `Campaigns Created Created`,
          },
        },
      })

      if (data?.createMultipleCampaign?.successful) {
        history.push(generatePath(Routes.CAMPAIGNS))
      } else if (data?.createMultipleCampaign?.result) {
        setMultipleCampaignsMutationError(data?.createMultipleCampaign?.result)
      }
    }
  }

  const buildMutationPayload = (formValues: { [key: string]: string }, templateValues: CampaignTemplate) => {
    const payload: Maybe<Array<Maybe<MultipleCampaignInput>>> = []

    // eslint-disable-next-line no-plusplus
    for (let index = 1; index < campaigns.length + 1; index++) {
      payload.push({
        campaign: {
          name: formValues[`name_${index}`],
          description: campaignsDescription.current?.input?.value,
          brandId: formValues[`brandId_${index}`],
          countryId: formValues[`countryId_${index}`],
          startsAt: formValues[`startDate_${index}`],
          endsAt: formValues[`endDate_${index}`],
          platform: templateValues?.platform as PlatformType,
          targetGroup: templateValues?.targetGroup,
          campaignTypeId: templateValues.campaignTypeId,
          deliveryDays: templateValues.deliveryDays,
          ...(templateValues.voucherSettings && {
            voucherSettings: templateValues.voucherSettings.map(setting => ({
              ordinality: setting?.ordinality ?? 1,
              value: setting?.value,
              valueType: setting?.valueType ?? DiscountValueType.Fixed,
              valueLimit: setting?.valueLimit,
              freeShipping: setting?.freeShipping,
            })),
          }),
        },
        ...(templateValues.voucher && {
          voucher: {
            code: formValues[`voucherCode_${index}`],
            totalRedemptionLimit: templateValues?.voucher?.totalRedemptionLimit,
            redemptionLimitPerCustomer: templateValues?.voucher?.redemptionLimitPerCustomer,
          },
        }),
        ...(templateValues.voucherBatch && {
          voucherBatch: {
            quantity: templateValues.voucherBatch?.quantity,
            totalRedemptionLimit: templateValues?.voucherBatch?.totalRedemptionLimit,
            redemptionLimitPerCustomer: templateValues?.voucherBatch?.redemptionLimitPerCustomer,
            prefix: formValues[`voucherCodePrefix_${index}`],
            suffix: formValues[`voucherCodeSuffix_${index}`],
            separator: formValues[`voucherCodeSeparator_${index}`],
          },
        }),
      })
    }

    return payload
  }

  const buildSummary = (isVoucherExist: boolean) => (
    <Descriptions title={template?.name} style={{ fontWeight: 'bold' }} layout="vertical" column={4} bordered>
      {isVoucherExist ? (
        <>
          <Descriptions.Item label="Voucher Code">{template?.voucher?.code}</Descriptions.Item>
          <Descriptions.Item label="Usage Limit (Per Customer)">
            {template?.voucher?.redemptionLimitPerCustomer}
          </Descriptions.Item>
          <Descriptions.Item label="Usage Limit (Total)">{template?.voucher?.totalRedemptionLimit}</Descriptions.Item>
        </>
      ) : (
        <>
          <Descriptions.Item label="Quantity">{template?.voucherBatch?.quantity}</Descriptions.Item>
          <Descriptions.Item label="Usage Limit (Per Customer)">
            {template?.voucherBatch?.redemptionLimitPerCustomer}
          </Descriptions.Item>
          <Descriptions.Item label="Usage Limit (Total)">
            {template?.voucherBatch?.totalRedemptionLimit}
          </Descriptions.Item>
        </>
      )}
    </Descriptions>
  )

  return (
    <>
      <PageHeader
        title="Campaigns Generator"
        onBack={history.goBack}
        extra={[
          <Input key="campaign-number-input" ref={numberOfCampaigns} type="number" placeholder="No of Campaigns" />,
          <SecondaryButton
            key="build-campaign-action"
            style={{ width: '100%' }}
            onClick={() =>
              rowBuilder((numberOfCampaigns?.current?.input?.value as unknown as number) ?? 0, voucher?.code)
            }
          >
            Build Campaign Form
          </SecondaryButton>,
        ]}
      >
        <BreadCrumbs param={{ name: 'id', value: id }} />
      </PageHeader>
      {buildSummary(!!template?.voucher)}
      {campaigns.length > 0 && (
        <Form layout="vertical" form={form} onFinish={values => onFormSubmit(values)}>
          <Row gutter={[16, 16]} style={{ paddingBottom: '20px', paddingTop: '20px' }}>
            <Col xl={14}>
              <Input ref={campaignsDescription} placeholder="Campaign Description" />
            </Col>
            <Col xl={10}>
              <PrimaryButton style={{ float: 'right' }} htmlType="submit">
                Create Campaign
              </PrimaryButton>
            </Col>
          </Row>
          <Row style={{ width: '100%' }}>
            <Col xl={24}>
              <Form.Item>
                <Table dataSource={campaigns} columns={campaignsTableColumn} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      )}
      {multipleCampaignsMutationError && (
        <CampaignsGeneratorErrorModal
          title={multipleCampaignsMutationError.message ?? ''}
          errorMessages={multipleCampaignsMutationError.errorMessages}
        />
      )}
    </>
  )
}

export default CampaignsGenerator
