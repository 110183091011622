import React from 'react'
import { Button, Space } from 'antd'
import { LeftOutlined as LeftArrow, RightOutlined as RightArrow } from '@ant-design/icons'

import { PaginationContainer } from './styles'

type Props = {
  hasNextPage?: boolean
  hasPreviousPage?: boolean
  onNextPage?: () => void
  onPreviousPage?: () => void
}

const Pagination = (props: Props): JSX.Element => {
  return (
    <PaginationContainer role="navigation">
      <Space>
        <Button
          aria-label="Previous"
          data-testid="previous-page"
          onClick={props.onPreviousPage}
          disabled={!props.hasPreviousPage}
          icon={<LeftArrow />}
        />

        <Button
          data-testid="next-page"
          aria-label="Next"
          onClick={props.onNextPage}
          disabled={!props.hasNextPage}
          icon={<RightArrow />}
        />
      </Space>
    </PaginationContainer>
  )
}

Pagination.defaultProps = {
  hasNextPage: false,
  hasPreviousPage: false,
  onNextPage: undefined,
  onPreviousPage: undefined,
}

export default Pagination
