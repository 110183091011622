import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import React from 'react'

import * as Authentication from '../Authentication'

import { HeaderContainer, SideBarMenuIconStyle } from './styles'

type HeaderProps = {
  isCollapsed: boolean
  onMenuClick: (value: boolean) => void
}
const Header: React.FC<HeaderProps> = ({ isCollapsed, onMenuClick }) => {
  return (
    <HeaderContainer>
      <Button
        onClick={() => onMenuClick(!isCollapsed)}
        icon={
          isCollapsed ? (
            <MenuUnfoldOutlined style={SideBarMenuIconStyle} />
          ) : (
            <MenuFoldOutlined style={SideBarMenuIconStyle} />
          )
        }
      />

      <Authentication.Logout />
    </HeaderContainer>
  )
}

export default Header
