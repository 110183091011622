import Stage from '../../constants/StagesConstants'
import config from '../../config'

export const getFunnelSelectPlanURL = (
  brandCode: string | undefined,
  countryCode: string | undefined,
): string | null => {
  const baseUrl = getFunnelBaseURL(brandCode, countryCode)

  return !baseUrl ? null : `${baseUrl}/select-plan`
}

export const getFunnelBaseURL = (brandCode: string | undefined, countryCode: string | undefined): string | null => {
  // It is expected that this env variable is only used locally for cases
  // where we want to decide the funnel URL for development purposes
  const funnelOverrideUrl = process.env.REACT_APP_FUNNEL_DEV_URL
  if (funnelOverrideUrl && String(config.stage) === Stage.DEVELOPMENT) {
    return funnelOverrideUrl
  }

  if (!brandCode || !countryCode) {
    return null
  }

  const link = [funnelSubdomain(), funnelDomain(brandCode.toLowerCase(), countryCode.toLowerCase())]
    .filter(elem => Boolean(elem))
    .join('.')

  return `${funnelProtocol()}${link}`
}

const funnelProtocol = (): string => {
  if (config.stage === Stage.TEST) {
    return 'http://'
  }

  return 'https://'
}

const funnelSubdomain = (): string | undefined => {
  switch (String(config.stage)) {
    case Stage.PRODUCTION:
      return undefined
    case Stage.DEVELOPMENT:
    case Stage.STAGING:
      return 'staging'
    case Stage.TEST:
      return 'test'
    default:
      return 'dev'
  }
}

const funnelDomain = (brandCode: string, countryCode: string): string => {
  const sld = brandCode.toLowerCase() === 'dn' ? 'dinnerly' : 'marleyspoon'
  const tld = tldForCountry(countryCode)

  return `${sld}.${tld}`
}

const tldForCountry = (countryCode: string): string => {
  const parsedCountryCode = countryCode.toLowerCase()

  switch (parsedCountryCode) {
    case 'us':
      return 'com'
    case 'au':
      return 'com.au'
    default:
      return parsedCountryCode
  }
}
