import React, { useEffect, useState } from 'react'
import { PageHeader } from '@ant-design/pro-components'
import { Col, Form, notification, Row, Switch } from 'antd'
import { useHistory, useParams } from 'react-router'

import {
  buildCreateVoucherBatchPayload,
  buildCreateVoucherPayload,
  buildUpdateVoucherPayload,
  buildVoucherDefaultValues,
  VoucherBatchPayload,
  VoucherPayload,
} from '../../../utils/voucherUtils'
import { usageLimitField, customerUsageLimitField } from '../../../constants/VoucherFormConstants'
import {
  useCreateVoucherMutation,
  useCreateVouchersMutation,
  useUpdateVoucherMutation,
  useVoucherQuery,
} from '../../../apollo/generated/api'
import { handleMutationResult } from '../../../apollo'
import NotFoundError from '../../../packages/UnauthorizedComponentError'
import BreadCrumbs from '../../Layout/breadcrumbs'

import SingleVoucherFormFields from './SingleVoucherFormFields'
import MultipleVouchersFormFields from './MultipleVouchersFormFields'
import { SwitchLabel, SwitchWrapper } from './style'

function VoucherForm(): React.ReactElement {
  const { id } = useParams<{ id: string }>()
  const { campaignId } = useParams<{ campaignId: string }>()
  const [hasMultipleVouchers, setHasMultipleVouchers] = useState(false)
  const history = useHistory()
  const [form] = Form.useForm()
  const submitButtonText = id ? 'Update Voucher' : 'Create Voucher'
  const { data: voucherData, error } = useVoucherQuery({ variables: { id }, skip: !id })

  const [createVoucher, { loading: creating }] = useCreateVoucherMutation()
  const [createVouchers, { loading: creatingMultiple }] = useCreateVouchersMutation()
  const [updateVoucher, { loading: updating }] = useUpdateVoucherMutation()

  const isEditing = !!id
  const isLoading = creating || creatingMultiple || updating

  useEffect(() => {
    form.setFieldsValue(buildVoucherDefaultValues(voucherData))
  }, [voucherData])

  function handleSubmit(values: VoucherPayload | VoucherBatchPayload) {
    return hasMultipleVouchers
      ? handleCreateMultipleVouchers(values as VoucherBatchPayload)
      : handleSaveVoucher(values as VoucherPayload)
  }

  function handleCreateMultipleVouchers(values: VoucherBatchPayload) {
    const mutation = createVouchers({ variables: { input: buildCreateVoucherBatchPayload(campaignId, values) } })

    void handleMutationResult(mutation, 'createVouchers', {
      notifications: {
        success: {
          title: 'Vouchers batch created',
          description: "We're now processing your new vouchers. They will be available soon!",
        },
      },
    })
      .then(values => {
        const response = values.data?.createVouchers

        if (response?.successful) {
          history.goBack()
        } else {
          notification.destroy()
          throw new Error(response?.messages?.[0]?.message || 'Something went wrong.')
        }
      })
      .catch((error: Error) => {
        // This is only a temporary solution.
        // Error messages should be dependant on the field causing the error. This needs to be added //
        if (error.message === 'has invalid format') {
          notification.error({
            message: 'Error',
            description:
              'For prefix and suffix, only characters A-Z or digits 0-9 are allowed. Separator can only be / - _ or |',
          })
        } else {
          notification.error({
            message: 'Error',
            description: error.message,
          })
        }
      })
  }

  function handleSaveVoucher(values: VoucherPayload) {
    const [mutationName, mutation] = isEditing
      ? ['updateVoucher', updateVoucher({ variables: { input: buildUpdateVoucherPayload(id, values) } })]
      : ['createVoucher', createVoucher({ variables: { input: buildCreateVoucherPayload(campaignId, values) } })]

    // @ts-expect-error The types aren't being inferred correctly due to our shared usage when creating/updating.
    void handleMutationResult(mutation, mutationName, {
      notifications: {
        success: {
          title: `Voucher ${isEditing ? 'updated' : 'created'}`,
        },
      },
    })
      .then(values => {
        // @ts-expect-error The types aren't being inferred correctly due to our shared usage when creating/updating.
        const id = values.data?.[mutationName]?.result?.id

        if (id) {
          history.goBack()
        } else {
          notification.destroy()
          // @ts-expect-error The types aren't being inferred correctly due to our shared usage when creating/updating.
          throw new Error(values.data?.[mutationName]?.messages[0].message)
        }
      })
      .catch((error: Error) => {
        if (error.message === 'has invalid format') {
          notification.error({
            message: 'Error',
            description: 'Only characters A-Z, digits 0-9 and special characters / - _ or | are allowed',
          })
        } else {
          notification.error({
            message: 'Error',
            description: error.message,
          })
        }
      })
  }

  function toggleHasMultipleVouchers() {
    setHasMultipleVouchers(state => !state)
  }
  if (error) {
    return <NotFoundError />
  }

  const breadcrumbParams = { name: 'id', value: campaignId || id }

  return (
    <div>
      <PageHeader title={id ? 'Edit Voucher' : 'Create Voucher'} onBack={history.goBack}>
        <BreadCrumbs param={breadcrumbParams.value ? breadcrumbParams : undefined} />
      </PageHeader>

      <Row justify="center">
        <Col md={14} lg={12} xl={10}>
          <Form.Provider>
            <Form
              form={form}
              onFinish={values => handleSubmit(values)}
              name="voucher-form"
              layout="vertical"
              initialValues={{ [usageLimitField]: 1, [customerUsageLimitField]: 1 }}
            >
              {!isEditing && (
                <Row gutter={16}>
                  <Col span={24}>
                    <SwitchWrapper>
                      <SwitchLabel onClick={toggleHasMultipleVouchers}>
                        Do you want to create multiple vouchers?
                      </SwitchLabel>
                      <Switch checked={hasMultipleVouchers} onChange={setHasMultipleVouchers} />
                    </SwitchWrapper>
                  </Col>
                </Row>
              )}

              {hasMultipleVouchers ? (
                <MultipleVouchersFormFields isLoading={isLoading} formType="Campaign" />
              ) : (
                <SingleVoucherFormFields
                  isLoading={isLoading}
                  formType="Campaign"
                  submitButtonText={submitButtonText}
                />
              )}
            </Form>
          </Form.Provider>
        </Col>
      </Row>
    </div>
  )
}

export default VoucherForm
