import React, { useEffect } from 'react'
import { Row, Col, Typography, message as antMessage } from 'antd'
import { Redirect, RouteComponentProps } from 'react-router-dom'
import { StaticContext } from 'react-router'

import { getFullYear, getUserSession, SessionMessages } from '../../utils'
import { Routes } from '../../constants/RoutesConstants'
import * as Authentication from '../Authentication'
import Logo from '../../assets/logo.png'

import * as S from './styles'

const { Title, Text } = Typography
const subTitleStyle = { display: 'block', marginBottom: 30 }

const Login: React.FC<LoginProps> = ({ location }) => {
  const session = getUserSession()
  const message = location?.state?.message

  useEffect(() => {
    if (message) {
      void antMessage.success(message)
    }
  }, [message])

  if (session.hasValidSession()) {
    return <Redirect to={Routes.CAMPAIGNS} />
  }

  return (
    <>
      <Row>
        <Col span={12} offset={6}>
          <S.Hello>
            <img style={{ width: 200 }} src={Logo} alt="Breadcrumbs" />
            <Title level={2}>You bring out the best in other people</Title>

            <Text style={subTitleStyle}>
              Login with your Marley Spoon Google account to access the Breadcrumbs dashboard.
            </Text>

            <Authentication.GoogleLogin />
          </S.Hello>
        </Col>
      </Row>

      <S.Footer>&copy; Marley Spoon {getFullYear()}</S.Footer>
    </>
  )
}

type LoginProps = RouteComponentProps<{}, StaticContext, { message?: SessionMessages }> & {}

export default Login
