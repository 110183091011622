import { Button, Col, Divider, Row } from 'antd'
import React from 'react'

import { PrimaryButton, SecondaryButton } from '../globalStyles'

type Props = {
  hasBack?: boolean
  hasNext?: boolean
  isDisabled?: boolean
  isEditing?: boolean
  isLoading?: boolean
  onPreviousStep?: () => void
  onNextStep?: () => void
}

const FormWizardFooter: React.FC<Props> = props => {
  return (
    <>
      <Divider />

      <Row gutter={16} justify="center">
        {props.hasBack && (
          <Col span={8}>
            <SecondaryButton style={{ margin: '0 8px' }} onClick={props.onPreviousStep} block>
              Back
            </SecondaryButton>
          </Col>
        )}

        <Col span={8}>
          {props.hasNext ? (
            <PrimaryButton data-testid="next-step-btn" type="primary" onClick={props.onNextStep} block>
              Next step
            </PrimaryButton>
          ) : (
            <Button
              type="primary"
              htmlType="submit"
              data-testid="submit-btn"
              loading={props.isLoading}
              disabled={props.isDisabled}
              block
            >
              {props.isEditing ? `Update` : `Create`}
            </Button>
          )}
        </Col>
      </Row>
    </>
  )
}

export default FormWizardFooter
