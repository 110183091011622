import { Descriptions, Typography } from 'antd'
import humanizeString from 'humanize-string'
import React from 'react'
import { Link, generatePath } from 'react-router-dom'
import moment from 'moment'

import { Routes } from '../../../constants/RoutesConstants'
import { Campaign } from '../../../apollo/generated/api'
import { presentDeliveryDays } from '../../../utils/campaignUtils'

interface Props {
  campaign: Campaign
  sectionTitle?: string
  columnCount?: number
  includeMarketingCopy?: boolean
}

const ManagedCampaignDetails: React.FC<Props> = ({
  campaign,
  sectionTitle,
  columnCount,
  includeMarketingCopy = true,
}) => {
  return (
    <div>
      <Descriptions
        title={sectionTitle}
        style={{ fontWeight: 'bold' }}
        layout="vertical"
        column={columnCount || 4}
        bordered
      >
        <Descriptions.Item data-testid="campaign_name" label="Campaign name">
          <Link to={generatePath(Routes.CAMPAIGN_DETAIL, { id: campaign?.id })}>{campaign?.name}</Link>
        </Descriptions.Item>
        <Descriptions.Item label="Campaign description" span={2}>
          {campaign?.description}
        </Descriptions.Item>
        <Descriptions.Item label="Brand">{campaign?.brand.name}</Descriptions.Item>
        <Descriptions.Item label="Country">{campaign?.country.name}</Descriptions.Item>
        <Descriptions.Item label="Campaign type">{campaign?.campaignType.name}</Descriptions.Item>
        <Descriptions.Item label="Target group">
          {humanizeString(campaign?.targetGroup || 'All customers')}
        </Descriptions.Item>

        <Descriptions.Item label="Platform">
          {campaign?.platform ? humanizeString(campaign?.platform) : 'All'}
        </Descriptions.Item>

        <Descriptions.Item label="Campaign start date">{moment.utc(campaign?.startsAt).format('LL')}</Descriptions.Item>
        <Descriptions.Item label="Campaign end date">{moment.utc(campaign?.endsAt).format('LL')}</Descriptions.Item>
        <Descriptions.Item label="Delivery week start date">
          {campaign?.deliveryWeekStartsAt ? (
            moment.utc(campaign?.deliveryWeekStartsAt).format('LL')
          ) : (
            <Typography.Text type="secondary">Not set</Typography.Text>
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Delivery week end date">
          {campaign?.deliveryWeekEndsAt ? (
            moment.utc(campaign?.deliveryWeekEndsAt).format('LL')
          ) : (
            <Typography.Text type="secondary">Not set</Typography.Text>
          )}
        </Descriptions.Item>
        {includeMarketingCopy && (
          <Descriptions.Item label="Marketing copy">{campaign?.marketingCopy || 'N/A'}</Descriptions.Item>
        )}
        <Descriptions.Item label="Communication style">
          {campaign?.communicationStyle ? (
            humanizeString(campaign?.communicationStyle)
          ) : (
            <Typography.Text type="secondary">None set</Typography.Text>
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Vouchers">{campaign.vouchersCount}</Descriptions.Item>
        <Descriptions.Item label="Delivery days">
          {presentDeliveryDays(campaign.deliveryDays as string[])}
        </Descriptions.Item>
      </Descriptions>
    </div>
  )
}

export default ManagedCampaignDetails
