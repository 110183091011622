import { CampaignFormData, CampaignPayload, buildCreateCampaignPayload } from '../campaignUtils'
import { TargetGroupType } from '../../apollo/generated/api'

/* eslint-disable camelcase */
export type FunnelVoucherResponse = {
  applicable_orders: number[]
  code: string
  current_discount: number
  free_shipping: boolean
  value_limit: number | null
  is_first_order_only: boolean
  is_reactivation_only: boolean
  is_split_voucher: boolean
  marketing_copy: string
  name: string
  promotion_type: string
  remaining_discount: string
  split_settings: any[]
  multi_tier_settings: MultiTierPromoType[]
  type: PromoValueType
  valid: boolean
  value: number
  communication_style: string
}

export type MultiTierPromoType = {
  order_ordinal: number
  default_setting: {
    value: number
    value_type: PromoValueType
    value_limit: number | null
    free_shipping: boolean
  }
  plan_based_variants: PlanBasedVariantPromoType[]
}

export type PlanBasedVariantPromoType = {
  number_of_persons: number
  number_of_meals: number
  value: number
  value_type: 'fixed' | 'percentage'
  value_limit: number | null
  free_shipping: boolean
}

type PromoValueType = 'fixed' | 'percentage'
type PromoVoucherSetting = NonNullable<NonNullable<CampaignPayload['voucherSettings']>[number]>
type PromoVoucherSettingVariant = NonNullable<NonNullable<PromoVoucherSetting>['voucherSettingVariants']>[number]
/* eslint-enable camelcase */

export const generateFunnelVoucherResponse = (
  campaignForm: CampaignFormData,
  campaignTypeCode: string,
): FunnelVoucherResponse => {
  const campaignFormData = buildCreateCampaignPayload(campaignForm)
  const firstBoxSetting = campaignFormData.voucherSettings?.[0] as PromoVoucherSetting

  return {
    name: campaignFormData.name,
    code: 'BREADCRUMBS_PREVIEW',
    marketing_copy: campaignFormData.marketingCopy as string,
    communication_style: campaignFormData.communicationStyle as string,
    valid: true,
    type: firstBoxSetting?.valueType?.toLowerCase() as PromoValueType,
    value: firstBoxSetting?.value,
    value_limit: firstBoxSetting?.valueLimit,
    promotion_type: campaignTypeCode,
    free_shipping: firstBoxSetting?.freeShipping || false,
    is_split_voucher: isSplitVoucher(campaignFormData.voucherSettings),
    is_reactivation_only: isReactivationOnly(campaignFormData),
    is_first_order_only: isFirstOrderOnly(campaignFormData),
    current_discount: firstBoxSetting?.value,
    remaining_discount: String(remainingDiscount(campaignFormData)),
    applicable_orders: applicableOrders(campaignFormData),
    split_settings: splitSettings(campaignFormData),
    multi_tier_settings: multiTierSettings(campaignFormData),
  }
}

const isSplitVoucher = (settings: CampaignPayload['voucherSettings']): boolean => !!settings && settings.length > 1

const isReactivationOnly = (campaignFormData: CampaignPayload): boolean =>
  campaignFormData.targetGroup === TargetGroupType.ReactivatingCustomers

const isFirstOrderOnly = (campaignFormData: CampaignPayload): boolean =>
  campaignFormData.targetGroup === TargetGroupType.NewCustomers

const remainingDiscount = (campaignFormData: CampaignPayload) => {
  if (!campaignFormData.voucherSettings) {
    return 0.0
  }

  return campaignFormData.voucherSettings
    .filter(setting => setting?.valueType === 'FIXED')
    .reduce((acc, setting) => acc + +setting?.value, 0)
}

const applicableOrders = (campaignFormData: CampaignPayload): number[] => {
  if (!campaignFormData.voucherSettings) {
    return []
  }

  return (campaignFormData.voucherSettings as PromoVoucherSetting[]).map(setting => setting?.ordinality)
}

const splitSettings = (campaignFormData: CampaignPayload) => {
  const nextSettings = campaignFormData.voucherSettings?.slice(1) || []

  if (!nextSettings) {
    return []
  }

  return nextSettings.map(setting => ({
    value: setting?.value,
    valueType: setting?.valueType,
    valueLimit: setting?.valueLimit,
    freeShipping: setting?.freeShipping,
    orderOrdinal: setting?.ordinality,
  }))
}

const multiTierSettings = (campaignFormData: CampaignPayload) =>
  (campaignFormData.voucherSettings as PromoVoucherSetting[]).map((setting: PromoVoucherSetting) =>
    voucherSettingMultiTierSetting(setting),
  ) || []

const voucherSettingMultiTierSetting = (voucherSetting: NonNullable<PromoVoucherSetting>) => {
  const variants = (voucherSetting.voucherSettingVariants || []) as NonNullable<PromoVoucherSettingVariant>[]

  return {
    order_ordinal: voucherSetting.ordinality,
    default_setting: {
      value: voucherSetting.value,
      value_type: voucherSetting.valueType.toLowerCase() as PromoValueType,
      value_limit: voucherSetting.valueLimit,
      free_shipping: voucherSetting.freeShipping as boolean,
    },
    plan_based_variants: variants.map(variant => ({
      number_of_persons: variant.numberOfPersons,
      number_of_meals: variant.numberOfMeals,
      value: variant.value,
      value_type: variant.valueType.toLowerCase() as PromoValueType,
      value_limit: variant.valueLimit,
      free_shipping: variant.freeShipping as boolean,
    })),
  }
}
