import { Card, Col, Form, Input, Row } from 'antd'
import { FormInstance } from 'antd/lib/form'
import React, { useState } from 'react'
import { Store } from 'antd/lib/form/interface'
import { FilterFilled, SearchOutlined } from '@ant-design/icons'

import BrandDropdown from '../../BrandDropdown'
import CountryDropdown from '../../CountryDropdown'
import { FormActionsContainer, ClearSearchFiltersButton } from '../../Forms'
import { deleteFromLocalStorage, persistLocalStorage } from '../../../utils/localStorageUtils'
import {
  LAST_SEARCHED_VOUCHER_FILTERS,
  VOUCHERS_SEARCH_FORM_FILTERS,
  VOUCHER_EMPTY_SEARCH_DATA,
} from '../../../constants/AppConstants'
import { getCachedVoucherSearchFilters } from '../../../utils/voucherUtils'
import { PrimaryButton, SecondaryButton } from '../../globalStyles'

interface Props {
  form?: FormInstance
  onSubmit?: (data: Store) => void
}

function VouchersSearchForm({ form, onSubmit }: Props): JSX.Element {
  const getCachedFilters = () => getCachedVoucherSearchFilters(VOUCHERS_SEARCH_FORM_FILTERS)
  const [defaultSearchFilters, setDefaultSearchFilters] = useState(getCachedFilters())
  const [showAdvanceFilters, setShowAdvanceFilters] = useState(false)
  const validFiltersPresent = () => {
    const searchFilters = getCachedFilters() || {}
    const filterValues = Object.values(searchFilters)
    const validFilterValues = filterValues.filter(val => val)

    return validFilterValues.length > 0
  }

  const [searchFiltersPresent, setSearchFiltersPresent] = useState(validFiltersPresent())

  const updateFilterState = () => setSearchFiltersPresent(validFiltersPresent())

  const handleFormValuesChange = (_changedValues: object, filters: Store) => {
    persistLocalStorage(VOUCHERS_SEARCH_FORM_FILTERS, filters)
    setDefaultSearchFilters(filters)
    updateFilterState()
  }

  const handleClearForm = (): void => {
    deleteFromLocalStorage([VOUCHERS_SEARCH_FORM_FILTERS, LAST_SEARCHED_VOUCHER_FILTERS])
    form?.setFieldsValue(VOUCHER_EMPTY_SEARCH_DATA)
    setDefaultSearchFilters({})
    setSearchFiltersPresent(false)
    if (onSubmit) onSubmit({})
  }

  const onClickShowAdvanceFilter = () => setShowAdvanceFilters(!showAdvanceFilters)

  const onClearField = (fieldName: string) => {
    if (form && onSubmit) {
      const formInputs = {
        ...form.getFieldsValue(),
      }
      formInputs[fieldName] = undefined
      form.setFieldsValue(formInputs)
      onSubmit(form.getFieldsValue())
    }
  }
  /**
   *
   * Input Component doesn't provide us onClear function, So we have to capture onChange separately
   */
  const onChangeInputHandler = (e: React.ChangeEvent<HTMLInputElement> | undefined) => {
    if (e?.target.value === '') {
      onClearField(e.target.name)
    }
  }

  return (
    <Card style={{ marginBottom: 25 }}>
      <Form
        form={form}
        initialValues={defaultSearchFilters}
        onValuesChange={handleFormValuesChange}
        onFinish={onSubmit}
        layout="vertical"
      >
        <Row gutter={16}>
          <Col xs={24} xl={16}>
            <Row gutter={16} align="top">
              <Col xs={24} md={24}>
                <Form.Item name="code" label="Code">
                  <Input data-testid="code-input" allowClear onChange={onChangeInputHandler} />
                </Form.Item>
              </Col>
            </Row>
            {showAdvanceFilters && (
              <Row gutter={16}>
                <Col xs={24} md={12}>
                  <CountryDropdown onClear={onClearField} />
                </Col>

                <Col xs={24} md={12}>
                  <BrandDropdown onClear={onClearField} />
                </Col>
              </Row>
            )}
          </Col>

          <Col xs={24} xl={8}>
            <FormActionsContainer gutter={[16, 16]}>
              <Col xs={24} md={12}>
                <SecondaryButton icon={<FilterFilled />} onClick={onClickShowAdvanceFilter} block>
                  Advanced Filters
                </SecondaryButton>
              </Col>

              <Col xs={24} md={12}>
                <PrimaryButton icon={<SearchOutlined />} htmlType="submit" data-testid="voucher-search-submit" block>
                  Search
                </PrimaryButton>
              </Col>
            </FormActionsContainer>
          </Col>

          {searchFiltersPresent && <ClearSearchFiltersButton onClick={handleClearForm} />}
        </Row>
      </Form>
    </Card>
  )
}

VouchersSearchForm.defaultProps = {
  form: undefined,
  onSubmit: undefined,
}

export default VouchersSearchForm
