import { Form, Select } from 'antd'
import React from 'react'

import { useCountriesQuery } from '../../apollo/generated/api'

interface Props {
  label?: string | null
  required?: boolean
  disabled?: boolean
  onClear?: (fieldName: string) => void
  index?: number
}

const PAGE_SIZE = 25

function CountryDropdown({ label, required, disabled, onClear, index }: Props): JSX.Element {
  const { data } = useCountriesQuery({ variables: { first: PAGE_SIZE }, fetchPolicy: 'cache-first' })

  const options = data?.countries?.edges || []
  const onClearHandler = () => {
    if (onClear) {
      onClear('countryId')
    }
  }

  return (
    <Form.Item
      labelCol={{ span: 24 }}
      name={index ? `countryId_${index}` : 'countryId'}
      label={label}
      rules={[{ required, message: 'Please select a country!' }]}
    >
      <Select
        style={{ width: '100%' }}
        placeholder="Select Country"
        optionFilterProp="children"
        data-testid="country-dropdown"
        disabled={disabled}
        showSearch
        allowClear
        onClear={onClearHandler}
      >
        {options.map(country => (
          <Select.Option value={country?.node?.id || ''} key={country?.node?.id}>
            {country?.node?.name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}

CountryDropdown.defaultProps = {
  label: 'Country',
  required: false,
  disabled: false,
  onClear: undefined,
  index: undefined,
}

export default CountryDropdown
