import { DiscountCalculatorProps, BoxDiscountProps, PlanType } from './types'

export const calculateFreeMeals = (planPriceData: PlanType, allBoxDiscounts: BoxDiscountProps[]): number => {
  const totalWithoutFreeShipping = calculateTotalWithoutFreeShipping(allBoxDiscounts)

  return Math.floor(totalWithoutFreeShipping / planPriceData.pricePerPortion)
}

export const calculateTotalDiscount = (planPriceData: PlanType, allBoxDiscounts: BoxDiscountProps[]): number => {
  const totalWithoutFreeShipping = calculateTotalWithoutFreeShipping(allBoxDiscounts)

  const totalShippingDiscount = calculateTotalFreeShippingDiscount(planPriceData.shippingCost, allBoxDiscounts)

  return totalWithoutFreeShipping + totalShippingDiscount
}

const calculateTotalFreeShippingDiscount = (shippingCost: number, allBoxDiscounts: BoxDiscountProps[]): number => {
  const totalFreeShippingCount = allBoxDiscounts.filter(box => !!box.freeShipping).length

  return totalFreeShippingCount * shippingCost
}

const calculateTotalWithoutFreeShipping = (allBoxDiscounts: BoxDiscountProps[]): number =>
  allBoxDiscounts.reduce((acc, box): number => acc + box.monetaryValueOff, 0)

export const getTotalPortions = (props: DiscountCalculatorProps): number => {
  const { planPriceData } = props
  const { numberOfMeals, numberOfPortions } = planPriceData

  return numberOfMeals * numberOfPortions
}

export const percentageDiscountFromFixed = (fixedDiscount: number, otherProps: DiscountCalculatorProps): number => {
  const { planPriceData } = otherProps
  const { total: boxPrice } = planPriceData

  return +((fixedDiscount / boxPrice) * 100).toFixed(2)
}

export const fixedDiscountFromPercentage = (
  percentageDiscount: number,
  otherProps: DiscountCalculatorProps,
): number => {
  const { planPriceData } = otherProps
  const { total: boxPrice } = planPriceData

  return (percentageDiscount / 100) * boxPrice
}

export const fixedDiscountFromBoxPrice = (discountBoxPrice: number, otherProps: DiscountCalculatorProps): number => {
  const { planPriceData } = otherProps
  const { total: boxPrice } = planPriceData

  return boxPrice - discountBoxPrice
}

export const discountBoxPriceFromFixedDiscount = (
  fixedDiscount: number,
  otherProps: DiscountCalculatorProps,
): number => {
  const { planPriceData } = otherProps
  const { total: boxPrice } = planPriceData

  return boxPrice - fixedDiscount
}

export const discountBoxPriceFromServingPrice = (
  discountServingPrice: number,
  otherProps: DiscountCalculatorProps,
): number => {
  const totalPortions = getTotalPortions(otherProps)

  return discountServingPrice * totalPortions
}

export const discountServingPriceFromBoxPrice = (discountBoxPrice: number, otherProps: DiscountCalculatorProps) => {
  const totalPortions = getTotalPortions(otherProps)

  return +(discountBoxPrice / totalPortions).toFixed(2)
}
