import { PageHeader } from '@ant-design/pro-components'
import { Form } from 'antd'
import { Store } from 'antd/lib/form/interface'
import React from 'react'

import { useVouchersQuery, Voucher } from '../../../apollo/generated/api'
import { LAST_SEARCHED_VOUCHER_FILTERS } from '../../../constants/AppConstants'
import useNetworkStatus from '../../../hooks/useNetworkStatus'
import { handleError } from '../../../utils/errorUtils/errorUtils'
import { persistLocalStorage } from '../../../utils/localStorageUtils'
import { parseEmpty } from '../../../utils/textUtils/textUtils'
import { getCachedVoucherSearchFilters } from '../../../utils/voucherUtils'
import { ContentMargin } from '../../Layout/styles'
import { VouchersSearchForm } from '../VouchersSearchForm'

import VoucherList from './VoucherList'

type RemoteVoucher = { node: Voucher }

const PAGE_SIZE = 25

const VouchersSearch: React.FC = () => {
  const [form] = Form.useForm()

  const lastSearchedFilters = getCachedVoucherSearchFilters(LAST_SEARCHED_VOUCHER_FILTERS)

  const { data, fetchMore, refetch, networkStatus } = useVouchersQuery({
    variables: { first: PAGE_SIZE, filters: lastSearchedFilters || {} },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  })

  const { isLoading, isLoadingMore } = useNetworkStatus(networkStatus)

  const entries = (data?.vouchers?.edges || []) as RemoteVoucher[]
  const pageInfo = data?.vouchers?.pageInfo
  const canLoadMore = !!entries?.length && pageInfo?.hasNextPage

  const handleFilter = (filters: Store) => {
    const variables = {
      first: PAGE_SIZE,
      filters: {
        code: parseEmpty(filters.code),
        brandId: filters.brandId,
        countryId: filters.countryId,
      },
    }

    persistLocalStorage(LAST_SEARCHED_VOUCHER_FILTERS, variables.filters)

    void handleError(() => refetch?.(variables))
  }

  const handleLoadMore = () => {
    if (pageInfo?.hasNextPage && pageInfo?.endCursor) {
      void handleError(() => fetchMore?.({ variables: { after: pageInfo.endCursor } }))
    }
  }

  return (
    <>
      <PageHeader title="Vouchers" />

      <div style={ContentMargin}>
        <VouchersSearchForm form={form} onSubmit={handleFilter} />

        <VoucherList vouchers={entries} connectionData={{ isLoading, canLoadMore, handleLoadMore, isLoadingMore }} />
      </div>
    </>
  )
}

export default VouchersSearch
