import { PageHeader } from '@ant-design/pro-components'
import { Row, Space, Tabs } from 'antd'
import React, { useMemo } from 'react'
import { generatePath, useHistory, useParams } from 'react-router'
import { Link } from 'react-router-dom'

import { Routes } from '../../../constants/RoutesConstants'
import { handleMutationResult } from '../../../apollo'
import {
  ReferralProgramQuery,
  useReferralProgramQuery,
  UserRoleType,
  useUpdateReferralProgramMutation,
  VoucherSetting,
} from '../../../apollo/generated/api'
import Breadcrumbs from '../../Layout/breadcrumbs'
import { ContentMargin } from '../../Layout/styles'
import VoucherSettings from '../../VoucherSettings'
import { PrimaryButton } from '../../globalStyles'
import { canAccessRoleFeatures } from '../../../utils/authUtils/authUtils'

import ReferralProgramForm, { FormValues } from './ReferralProgramForm'

function ReferralProgramsList(): JSX.Element {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()

  const { data, loading, error } = useReferralProgramQuery({ variables: { id }, fetchPolicy: 'network-only' })
  const [updateReferralProgram, { loading: updatingReferralProgram }] = useUpdateReferralProgramMutation()

  const referralProgram = data?.referralProgram

  const referralProgramInitialValues = useMemo(() => getProgramInitialValues(referralProgram), [referralProgram])

  const isReferralsAdmin = canAccessRoleFeatures(UserRoleType.ReferralsAdmin)

  function handleReferralProgramUpdate(values: FormValues) {
    const result = updateReferralProgram({
      variables: { input: { id, ...values } },
    })

    void handleMutationResult(result, 'updateReferralProgram', {
      notifications: {
        success: { title: 'Referral program updated!' },
      },
    })
  }

  return (
    <>
      <PageHeader
        title={
          referralProgram
            ? `Referral Program: ${referralProgram?.brand.name} (${referralProgram?.country.name})`
            : 'Referral Program'
        }
        onBack={history.goBack}
      >
        <Breadcrumbs param={{ name: 'id', value: id }} />
      </PageHeader>

      <div style={ContentMargin}>
        <Tabs defaultActiveKey="referral-structure">
          {isReferralsAdmin && (
            <Tabs.TabPane tab={<span>Program Settings</span>} key="settings">
              <ReferralProgramForm
                disabled={loading || !!error || updatingReferralProgram}
                initialValues={referralProgramInitialValues}
                onSubmit={handleReferralProgramUpdate}
              />
            </Tabs.TabPane>
          )}
          <Tabs.TabPane tab={<span>Referral Discount Structure</span>} key="referral-structure">
            <Space direction="vertical" size="large" style={{ display: 'flex' }}>
              <VoucherSettings
                voucherSettings={referralProgram?.referralCampaign?.voucherSettings as VoucherSetting[]}
              />
              {isReferralsAdmin && (
                <Row justify="end">
                  {referralProgram?.referralCampaign?.id && (
                    <Link
                      key="edit-campaign-link"
                      to={generatePath(Routes.CAMPAIGN_EDIT, { id: referralProgram.referralCampaign.id })}
                    >
                      <PrimaryButton>Edit Campaign</PrimaryButton>
                    </Link>
                  )}
                </Row>
              )}
            </Space>
          </Tabs.TabPane>

          <Tabs.TabPane tab={<span>Rewards Discount Structure</span>} key="rewards-structure">
            <VoucherSettings
              voucherSettings={referralProgram?.referralRewardCampaign?.voucherSettings as VoucherSetting[]}
            />
          </Tabs.TabPane>
        </Tabs>
      </div>
    </>
  )
}

function getProgramInitialValues(referralProgram: ReferralProgramQuery['referralProgram']) {
  if (!referralProgram) {
    return undefined
  }

  return {
    maxReferralCredits: referralProgram.maxReferralCredits || undefined,
    minCreditToRefer: referralProgram.minCreditToRefer || undefined,
    expiresIn: referralProgram.expiresIn || undefined,
    referralRewardExpiresIn: referralProgram.referralRewardExpiresIn || undefined,
    referralRewardEnabled: referralProgram.referralRewardEnabled || false,
  }
}

export default ReferralProgramsList
