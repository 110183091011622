import { Popconfirm, Table } from 'antd'
import moment from 'moment'
import React from 'react'
import { Link, generatePath } from 'react-router-dom'

import { BlockedCampaignUsage } from '../../apollo/generated/api'
import { Routes } from '../../constants/RoutesConstants'

interface BlockedCampaignProps {
  blockedCampaigns: BlockedCampaignUsage[]
  onDelete?: (id: string) => void
}

const BlockedCampaigns: React.FC<BlockedCampaignProps> = ({ blockedCampaigns, onDelete }) => {
  const linkStyle = { color: 'red' }

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Campaign',
      dataIndex: 'campaign',
      key: 'campaignName',
      render: (campaign: { id: string; name: string }) => (
        <Link to={generatePath(Routes.CAMPAIGN_DETAIL, { id: campaign?.id })}>{campaign?.name}</Link>
      ),
    },
    {
      title: 'Date of block',
      dataIndex: 'insertedAt',
      key: 'insertedAt',
      render: (insertedAt: moment.MomentInput) => moment.utc(insertedAt).format('LL'),
    },
    {
      title: 'Actions',
      align: 'right' as const,
      render: (record: BlockedCampaignUsage) => (
        <Popconfirm
          title="Are you sure you want to delete this blocked campaign usage?"
          onConfirm={() => onDelete?.(record.id)}
          okText="Yes"
          cancelText="No"
        >
          <Link to="#" style={linkStyle} data-testid="delete-button">
            Remove Block
          </Link>
        </Popconfirm>
      ),
    },
  ]

  return (
    <>
      <h1>Blocked campaigns for customer</h1>
      <Table dataSource={blockedCampaigns || []} columns={columns} pagination={false} />
    </>
  )
}

export default BlockedCampaigns
