import {
  percentageDiscountFromFixed,
  fixedDiscountFromPercentage,
  fixedDiscountFromBoxPrice,
  discountBoxPriceFromFixedDiscount,
  discountBoxPriceFromServingPrice,
  discountServingPriceFromBoxPrice,
} from './shared'
import { InputCalculatorResult, DiscountCalculatorProps } from './types'

export function calculateByMonetaryValueOff(props: DiscountCalculatorProps): InputCalculatorResult {
  const { calculatorInputValue } = props
  const discountBoxPrice = discountBoxPriceFromFixedDiscount(calculatorInputValue, props)
  const discountServingPrice = discountServingPriceFromBoxPrice(discountBoxPrice, props)

  const percentageOff = percentageDiscountFromFixed(calculatorInputValue, props)

  return {
    discountBoxPrice,
    discountServingPrice,
    monetaryValueOff: calculatorInputValue,
    percentageOff,
  }
}

export function calculateByPercentageOff(props: DiscountCalculatorProps): InputCalculatorResult {
  const { calculatorInputValue } = props
  const monetaryValueOff = fixedDiscountFromPercentage(calculatorInputValue, props)

  const discountBoxPrice = discountBoxPriceFromFixedDiscount(monetaryValueOff, props)
  const discountServingPrice = discountServingPriceFromBoxPrice(discountBoxPrice, props)

  return {
    discountBoxPrice,
    discountServingPrice,
    monetaryValueOff,
    percentageOff: calculatorInputValue,
  }
}

export function calculateByDiscountBoxPrice(props: DiscountCalculatorProps): InputCalculatorResult {
  const { calculatorInputValue } = props

  const monetaryValueOff = fixedDiscountFromBoxPrice(calculatorInputValue, props)
  const percentageOff = percentageDiscountFromFixed(monetaryValueOff, props)

  const discountServingPrice = discountServingPriceFromBoxPrice(calculatorInputValue, props)

  return {
    discountBoxPrice: calculatorInputValue,
    discountServingPrice,
    monetaryValueOff,
    percentageOff,
  }
}

export function calculateByDiscountServingPrice(props: DiscountCalculatorProps): InputCalculatorResult {
  const { calculatorInputValue } = props

  const discountBoxPrice = discountBoxPriceFromServingPrice(calculatorInputValue, props)

  const monetaryValueOff = fixedDiscountFromBoxPrice(discountBoxPrice, props)
  const percentageOff = percentageDiscountFromFixed(monetaryValueOff, props)

  return {
    discountBoxPrice,
    discountServingPrice: calculatorInputValue,
    monetaryValueOff,
    percentageOff,
  }
}
