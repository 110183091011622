import {
  CampaignTemplateQuery,
  CreateCampaignTemplateInput,
  DeliveryDayType,
  UpdateCampaignTemplateInput,
  UpdateVoucherSettingInput,
  VoucherSettingInput,
} from '../../apollo/generated/api'
import { defaultSetting } from '../../components/VoucherSettings/VoucherSettingsForm'

export type CampaignTemplatePayload = CreateCampaignTemplateInput
export type CampaignTemplateFormData = Omit<CreateCampaignTemplateInput, 'startsAt' | 'endsAt' | 'deliveryDays'> & {
  deliveryDays: null | []
}

export function buildCreateCampaignTemplatePayload(values: CampaignTemplateFormData): CreateCampaignTemplateInput {
  return buildCampaignTemplatePayload(values)
}

function buildCampaignTemplatePayload(values: CampaignTemplateFormData): CampaignTemplatePayload {
  const voucherSettings = (values.voucherSettings || []) as VoucherSettingInput[]
  const { voucher } = values

  return {
    ...values,
    description: values.description,
    voucherSettings: voucherSettings.map(setting => ({
      ordinality: setting.ordinality,
      value: setting.value,
      valueType: setting.valueType,
      valueLimit: setting.valueLimit,
      freeShipping: setting.freeShipping,
    })),
    voucherBatch: values.voucherBatch,
    voucher,
  }
}

export function buildUpdateCampaignTemplatePayload(
  id: string,
  values: CampaignTemplateFormData,
): UpdateCampaignTemplateInput {
  const voucherSettings = (values.voucherSettings || []) as UpdateVoucherSettingInput[]
  const { voucher } = values

  function checkDeliveryDays(deliveryDays: DeliveryDayType[] | null) {
    const quantityOfSelectedDays = deliveryDays?.length

    if (quantityOfSelectedDays === 7 || quantityOfSelectedDays === 0) {
      return null
    }

    return deliveryDays
  }

  return {
    ...values,
    deliveryDays: checkDeliveryDays(values.deliveryDays),
    voucher,
    voucherBatch: values.voucherBatch,
    voucherSettings: voucherSettings.map(setting => ({
      id: setting.id,
      ordinality: setting.ordinality,
      value: setting.value,
      valueType: setting.valueType,
      valueLimit: setting.valueLimit,
      freeShipping: setting.freeShipping,
    })),
    id,
  }
}

export function buildCampaignTemplateDefaultValues(
  data?: CampaignTemplateQuery | null,
): Partial<CampaignTemplateFormData> {
  if (!data?.campaignTemplate) {
    return { voucherSettings: [defaultSetting] }
  }

  const { campaignTemplate } = data

  return {
    name: campaignTemplate.name,
    description: campaignTemplate.description,
    marketingCopy: campaignTemplate.marketingCopy,
    campaignTypeId: campaignTemplate.campaignType.id,
    platform: campaignTemplate.platform,
    voucherSettings: campaignTemplate.voucherSettings,
    voucher: campaignTemplate.voucher,
    voucherBatch: campaignTemplate.voucherBatch,
    ...(campaignTemplate.deliveryDays && { deliveryDays: campaignTemplate.deliveryDays as [] }),
    targetGroup: campaignTemplate.targetGroup || undefined,
  }
}
