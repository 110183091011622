import { Skeleton, Typography } from 'antd'
import React from 'react'
import styled from 'styled-components'

interface Props {
  name?: string
  description?: string
}

function RewardCardPreview({ name, description }: Props) {
  return (
    <Card>
      <CardIconPlaceholder />

      {name ? <CardTitle>{name}</CardTitle> : <CardTextPlaceholder />}
      {description ? <CardDescription>{description}</CardDescription> : <CardTextPlaceholder />}
    </Card>
  )
}

const Card = styled.div`
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 16px;
`

const CardIconPlaceholder = styled(Skeleton.Avatar).attrs({ size: 120 })`
  align-self: center;
  margin-bottom: 16px;
`

const CardTextPlaceholder = styled(Skeleton.Input).attrs({ style: { width: '100%', marginTop: 4 } })``

const CardTitle = styled(Typography.Title).attrs({ level: 5 })`
  margin: 0 0 4px 0 !important;
`

const CardDescription = styled(Typography.Paragraph)`
  margin-bottom: 0 !important;
`

export default RewardCardPreview
