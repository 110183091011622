import { Store } from 'antd/lib/form/interface'
import moment from 'moment'

import {
  CampaignFiltersInput,
  CampaignQuery,
  CreateCampaignInput,
  DeliveryDayType,
  DiscountValueType,
  UpdateCampaignInput,
  UpdateVoucherSettingInput,
  VoucherSetting,
  VoucherSettingInput,
  VoucherSettingVariant,
} from '../../apollo/generated/api'
import { defaultSetting } from '../../components/VoucherSettings/VoucherSettingsForm'
import { capitalize, parseBoolean, parseEmpty } from '../textUtils'
import { VoucherVariantFormField } from '../../types'

type VoucherSettingVariantPayload = VoucherVariantFormField & { ordinality: number }

export type CampaignPayload = CreateCampaignInput | UpdateCampaignInput
export type CampaignFormData = Omit<
  CreateCampaignInput,
  'startsAt' | 'endsAt' | 'deliveryDays' | 'deliveryWeekStartsAt' | 'deliveryWeekEndsAt'
> & {
  startsAt: moment.Moment
  endsAt: moment.Moment
  deliveryWeekStartsAt: moment.Moment
  deliveryWeekEndsAt: moment.Moment
  deliveryDays: null | []
  voucherSettingVariants?: VoucherSettingVariantPayload[][]
}

export function buildCreateCampaignPayload(values: CampaignFormData): CreateCampaignInput {
  return buildCampaignPayload(values)
}

export function buildUpdateCampaignPayload(id: string, values: CampaignFormData): UpdateCampaignInput {
  const { voucherSettingVariants, ...payloadValues } = values
  const voucherSettings = (values.voucherSettings || []) as UpdateVoucherSettingInput[]
  const voucherSettingVariantsPerOrdinality = getSettingVariantsPerOrdinality(
    voucherSettings,
    voucherSettingVariants || [],
  )
  function checkDeliveryDays(deliveryDays: DeliveryDayType[] | null) {
    const quantityOfSelectedDays = deliveryDays?.length

    if (quantityOfSelectedDays === 7 || quantityOfSelectedDays === 0) {
      return null
    }

    return deliveryDays
  }

  return {
    ...payloadValues,
    deliveryDays: checkDeliveryDays(values.deliveryDays),
    startsAt: moment.utc(values.startsAt).startOf('day').toISOString(),
    endsAt: moment.utc(values.endsAt).endOf('day').toISOString(),
    ...(values.deliveryWeekStartsAt && {
      deliveryWeekStartsAt: moment.utc(values.deliveryWeekStartsAt).startOf('day').toISOString(),
    }),
    ...(values.deliveryWeekEndsAt && {
      deliveryWeekEndsAt: moment.utc(values.deliveryWeekEndsAt).endOf('day').toISOString(),
    }),
    voucherSettings: voucherSettings.map(setting => ({
      id: setting.id,
      ordinality: setting.ordinality,
      value: setting.value,
      valueType: setting.valueType,
      valueLimit: setting.valueLimit,
      freeShipping: setting.freeShipping,
      voucherSettingVariants: voucherSettingVariantsPerOrdinality[setting.ordinality],
    })),
    id,
  }
}

export function buildCampaignDefaultValues(data?: CampaignQuery | null): Partial<CampaignFormData> {
  if (!data?.campaign) {
    return { voucherSettings: [defaultSetting] }
  }

  const { campaign } = data

  const defaultVoucherSettingVariants = getSettingVariantsPerPlan(campaign.voucherSettings as VoucherSetting[])

  return {
    name: campaign.name,
    description: campaign.description,
    marketingCopy: campaign.marketingCopy,
    brandId: campaign.brand.id,
    campaignTypeId: campaign.campaignType.id,
    countryId: campaign.country.id,
    platform: campaign.platform,
    voucherSettings: campaign.voucherSettings,
    voucherSettingVariants: defaultVoucherSettingVariants,
    ...(campaign.deliveryWeekStartsAt && { deliveryWeekStartsAt: moment.utc(campaign.deliveryWeekStartsAt) }),
    ...(campaign.deliveryWeekEndsAt && { deliveryWeekEndsAt: moment.utc(campaign.deliveryWeekEndsAt) }),
    targetGroup: campaign.targetGroup || undefined,
    ...(campaign.endsAt && { endsAt: moment.utc(campaign.endsAt) }),
    ...(campaign.startsAt && { startsAt: moment.utc(campaign.startsAt) }),
    communicationStyle: campaign.communicationStyle,
  }
}

function buildCampaignPayload(values: CampaignFormData): CampaignPayload {
  const { voucherSettingVariants, ...payloadValues } = values

  const voucherSettings = (values.voucherSettings || []) as VoucherSettingInput[]
  const voucherSettingVariantsPerOrdinality = getSettingVariantsPerOrdinality(
    voucherSettings,
    voucherSettingVariants || [],
  )

  return {
    ...payloadValues,
    startsAt: moment.utc(values.startsAt).startOf('day').toISOString(),
    endsAt: moment.utc(values.endsAt).endOf('day').toISOString(),
    ...(values.deliveryWeekStartsAt && {
      deliveryWeekStartsAt: moment.utc(values.deliveryWeekStartsAt).startOf('day').toISOString(),
    }),
    ...(values.deliveryWeekEndsAt && {
      deliveryWeekEndsAt: moment.utc(values.deliveryWeekEndsAt).endOf('day').toISOString(),
    }),
    voucherSettings: voucherSettings.map(setting => ({
      ordinality: setting.ordinality,
      value: setting.value,
      valueType: setting.valueType,
      valueLimit: setting.valueLimit,
      freeShipping: setting.freeShipping,
      voucherSettingVariants: voucherSettingVariantsPerOrdinality[setting.ordinality]?.map(variant => {
        return {
          numberOfMeals: variant?.numberOfMeals ?? 0,
          numberOfPersons: variant?.numberOfPersons ?? 0,
          value: variant?.value,
          // We do not want zero value limit
          valueLimit: Number(variant?.valueLimit) || null,
          valueType: variant?.valueType ?? ('PERCENTAGE' as DiscountValueType),
          freeShipping: variant?.freeShipping,
        }
      }),
    })),
  }
}

export function parseCampaignSearchFilters(filters: Store): CampaignFiltersInput {
  return {
    country: filters.countryId,
    brand: filters.brandId,
    name: parseEmpty(filters.name),
    campaignType: filters.campaignTypeId,
    targetGroup: filters.targetGroup,
    platform: filters.platform,
    splitVoucher: parseBoolean(filters.hasSplitVouchers),
  }
}

export function presentDeliveryDays(deliveryDays: string[]): any {
  const daysMessage = new Map()

  const DELIVERY_DAYS_ALL = 'All Days'

  daysMessage.set(7, DELIVERY_DAYS_ALL)
  daysMessage.set(0, DELIVERY_DAYS_ALL)

  const message = daysMessage.get(deliveryDays?.length || 0)

  return message || deliveryDays?.map(day => capitalize(day)).join(', ')
}

function getSettingVariantsPerPlan(voucherSettings?: VoucherSetting[]) {
  const orderedVoucherSettings: VoucherSetting[] = [...(voucherSettings || [])]?.sort(setting => setting.ordinality)
  const variantsOnTheFirstVoucherSetting = orderedVoucherSettings[0]?.voucherSettingVariants
  const variantsPerPlan: Record<string, VoucherSettingVariantPayload[]> = {}

  /**
   * This initial iteration over the first voucher setting initializes the plans object. E.g:
   *   {'3-meals-2-people': [{...}], '3-meals-4-people': [{...}]}
   */
  variantsOnTheFirstVoucherSetting?.forEach(variant => {
    if (variant) {
      variantsPerPlan[buildPlanIndex(variant)] = [buildVariantDefaultValue(variant, orderedVoucherSettings[0])]
    }
  })

  // Starting on the second voucher stage, we push the variants for each setting on their respective plan.
  for (let i = 1; i < orderedVoucherSettings.length; i += 1) {
    orderedVoucherSettings[i]?.voucherSettingVariants?.forEach(variant => {
      if (variant) {
        variantsPerPlan[buildPlanIndex(variant)].push(buildVariantDefaultValue(variant, orderedVoucherSettings[i]))
      }
    })
  }

  return Object.values(variantsPerPlan)
}

function getSettingVariantsPerOrdinality(
  voucherSettings: VoucherSettingInput[],
  voucherSettingVariants: VoucherSettingVariantPayload[][],
): Record<number, UpdateVoucherSettingInput['voucherSettingVariants']> {
  if (!voucherSettingVariants) {
    return {}
  }

  const variantsPerOrdinality: Record<number, UpdateVoucherSettingInput['voucherSettingVariants']> = {}
  const variants = voucherSettingVariants.flat()

  voucherSettings.forEach(setting => {
    variantsPerOrdinality[setting.ordinality] = []
  })

  variants.forEach(variant => {
    variantsPerOrdinality[variant.ordinality]?.push({
      ...(variant.id && { id: variant.id }),
      numberOfMeals: parseInt(variant.numberOfMeals, 10),
      numberOfPersons: parseInt(variant.numberOfPersons, 10),
      value: variant.percentageOff?.toFixed(2),
      valueType: DiscountValueType.Percentage,
      // We do not want zero value limit
      valueLimit: Number(variant?.valueLimit) || null,
      freeShipping: variant.freeShipping,
    })
  })

  return variantsPerOrdinality
}

function buildPlanIndex(variant: VoucherSettingVariant): string {
  return `${variant.numberOfMeals}-meals-${variant.numberOfPersons}-people`
}

function buildVariantDefaultValue(
  variant: VoucherSettingVariant,
  voucherSetting: VoucherSetting,
): VoucherSettingVariantPayload {
  return {
    ...variant,
    discountBoxPrice: 0,
    discountServingPrice: 0,
    percentageOff: variant.value,
    monetaryValueOff: 0,
    numberOfMeals: String(variant.numberOfMeals),
    numberOfPersons: String(variant.numberOfPersons),
    ordinality: voucherSetting.ordinality,
    valueLimit: variant.valueLimit,
  }
}
