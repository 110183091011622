import React, { useEffect, useMemo, useState } from 'react'
import { PageHeader } from '@ant-design/pro-components'
import { Button, Col, Divider, Form, Input, Row, Steps, Switch, Popconfirm } from 'antd'
import type { CheckboxValueType } from 'antd/es/checkbox/Group'
import { useParams } from 'react-router'
import { useForm, useWatch } from 'antd/lib/form/Form'
import { useHistory } from 'react-router-dom'

import { CampaignTypeSelect, DatePicker, TargetGroupSelect } from '../../Inputs'
import BrandDropdown from '../../BrandDropdown'
import CountryDropdown from '../../CountryDropdown'
import PlatformSelect from '../../Inputs/PlatformSelect'
import DeliveryDaysCheckbox from '../../DeliveryDaysCheckbox'
import BreadCrumbs from '../../Layout/breadcrumbs'
import Error from '../../Error'
import { ErrorPayload } from '../../Error/types'
import FunnelPreviewModal from '../../FunnelPreviewModal'
import { PrimaryButton, SecondaryButton } from '../../globalStyles'
import { useBrandsQuery, useCampaignTypesQuery, useCountriesQuery } from '../../../apollo/generated/api'
import VouchersSettingsForm, { defaultSetting } from '../../VoucherSettings/VoucherSettingsForm'
import VoucherSettingsVariantManager from '../../VoucherSettings/VoucherSettingsVariantManager'
import CampaignCommunicationParameters from '../CampaignCommunicationParameters'
import {
  getFunnelSelectPlanURL,
  FunnelVoucherResponse,
  generateFunnelVoucherResponse,
} from '../../../utils/funnelUtils'
import { usePlans } from '../../../hooks/usePlans'
import { calculateDiscountValues, calculateTotalDiscountAndFreeMeals } from '../../../utils/discountCalculatorUtils'
import {
  BoxDiscountProps,
  DiscountCalculatorProps,
  InputCalculatorResult,
  PlanType,
  TotalDiscountAndFreeMeals,
} from '../../../utils/discountCalculatorUtils/types'
import { DiscountSummary, VoucherVariantFormField } from '../../../types'
import { toFixDecimal } from '../../../utils/numberUtils/numberUtils'
import { LoadingIndicator } from '../../LoadingIndicator/LoadingIndicator'

interface Props<T, DefaultValueType> {
  onSubmitForm: (values: T) => Promise<void> | void
  fieldErrors: ErrorPayload | undefined
  onValuesChange: (e: any) => void
  isLoading: boolean
  defaultValues?: DefaultValueType
  hasVouchers?: boolean
  formType: 'Campaign' | 'Template' | 'Duplicate'
}

// eslint-disable-next-line no-unused-vars
enum FORM_TYPE {
  // eslint-disable-next-line no-unused-vars
  Campaign = 'Campaign',
  // eslint-disable-next-line no-unused-vars
  CampaignTemplate = 'Template',
  // eslint-disable-next-line no-unused-vars
  CampaignDuplicate = 'Duplicate',
}
const { Step } = Steps
const { TextArea } = Input

function CampaignsForm<T, DefaultValueType>({
  onValuesChange,
  onSubmitForm,
  fieldErrors,
  isLoading,
  defaultValues,
  formType,
  hasVouchers,
}: Props<T, DefaultValueType>): React.ReactElement {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const [form] = useForm()
  const [currentStep, setCurrentStep] = useState(0)
  const [formData, setFormdata] = useState<T>()
  const marketingCopyContent = Form.useWatch('marketingCopy', form)
  const voucherVariants: VoucherVariantFormField[][] = Form.useWatch('voucherSettingVariants', form)
  const [discountSummary, setDiscountSummary] = useState<DiscountSummary>(new Map())

  const [marketingCopyEnabled, setMarketingCopyEnable] = useState(false)
  const [showFunnelPreview, setShowFunnelPreview] = useState(false)
  const [voucherSettingsRowCursor, setVoucherSettingsRowCursor] = useState<number>(-1)

  const { data: countriesQueryResult } = useCountriesQuery({ variables: { first: 15 }, fetchPolicy: 'cache-first' })
  const { data: brandsQueryResult } = useBrandsQuery({ variables: { first: 5 }, fetchPolicy: 'cache-first' })
  const { data: campaignTypeData } = useCampaignTypesQuery({ variables: { first: 200 } })

  const formBrandId = useWatch('brandId', form) || form.getFieldValue('brandId')
  const formCountryId = useWatch('countryId', form) || form.getFieldValue('countryId')

  const brandCode = useMemo(() => {
    const brands = brandsQueryResult?.brands?.edges || []
    const selectedBrandId = formBrandId

    return brands.find(brand => brand?.node?.id === selectedBrandId)?.node?.code || ''
  }, [formBrandId, brandsQueryResult])

  const countryCode = useMemo(() => {
    const countries = countriesQueryResult?.countries?.edges || []
    const selectedCountryId = formCountryId

    return countries.find(country => country?.node?.id === selectedCountryId)?.node?.code || ''
  }, [formCountryId, countriesQueryResult])

  const { data: plansData, isLoading: plansLoading } = usePlans(brandCode, countryCode)

  useEffect(() => {
    if (marketingCopyContent?.length > 0) {
      setMarketingCopyEnable(true)
    }
  }, [marketingCopyContent])

  const calculateTotalDiscount = (
    plansFromAPI: PlanType[],
    totalDiscountFreeMeals: BoxDiscountProps[],
    numberOfMeals: number,
    numberOfPersons: number,
  ): { key: string; totalDiscountAndFreeMeal: TotalDiscountAndFreeMeals } | null => {
    const selectedPlanForCal = plansFromAPI.find(
      p => p.numberOfMeals === numberOfMeals && p.numberOfPortions === numberOfPersons,
    )
    if (selectedPlanForCal) {
      return {
        totalDiscountAndFreeMeal: calculateTotalDiscountAndFreeMeals(selectedPlanForCal, totalDiscountFreeMeals),
        key: `${numberOfMeals}-${numberOfPersons}`,
      }
    }

    return null
  }

  const flattenedVoucherVariants =
    voucherVariants
      ?.map(variant =>
        variant
          ?.map(
            (v, ordinal) =>
              // eslint-disable-next-line max-len
              `${ordinal}-${v.numberOfMeals}-${v.numberOfPersons}-${v.monetaryValueOff}-${v.percentageOff}-${v.freeShipping}-${v.valueLimit}`,
          )
          .join(';'),
      )
      .sort()
      .join('-') ?? ''

  useMemo(() => {
    if (!flattenedVoucherVariants || !brandCode || !countryCode || plansLoading || !plansData) {
      return
    }
    const totalDiscountFreeMealsMap = new Map()
    voucherVariants.forEach(variant => {
      const meals = variant && variant[0] ? Number(variant[0]?.numberOfMeals) : 0
      const persons = variant && variant[0] ? Number(variant[0]?.numberOfPersons) : 0
      const totalDiscountFreeMeals: BoxDiscountProps[] = variant?.map(({ monetaryValueOff, freeShipping }) => ({
        monetaryValueOff,
        freeShipping,
      }))

      const discountSummary = calculateTotalDiscount(plansData, totalDiscountFreeMeals, meals, persons)
      if (discountSummary) {
        totalDiscountFreeMealsMap.set(discountSummary.key, discountSummary.totalDiscountAndFreeMeal)
      }
    })
    setDiscountSummary(totalDiscountFreeMealsMap)
  }, [flattenedVoucherVariants, plansLoading, brandCode, countryCode])

  const confirm = () => {
    form.setFieldValue('marketingCopy', null)
    setMarketingCopyEnable(false)
  }

  const variantParams = (
    { calculatorInputValue, calculatorInputType }: Omit<DiscountCalculatorProps, 'planPriceData'>,
    noOfMeals: number,
    noOfPersons: number,
  ): InputCalculatorResult | null => {
    if (plansData && !plansLoading) {
      const selectedPlanForCal = plansData.find(
        p => p.numberOfMeals === noOfMeals && p.numberOfPortions === noOfPersons,
      )

      if (selectedPlanForCal) {
        const calResults: InputCalculatorResult | null = calculateDiscountValues({
          planPriceData: selectedPlanForCal,
          calculatorInputValue,
          calculatorInputType,
        })
        if (calResults) {
          return {
            discountBoxPrice: toFixDecimal(calResults.discountBoxPrice),
            discountServingPrice: toFixDecimal(calResults.discountServingPrice),
            percentageOff: toFixDecimal(calResults.percentageOff),
            monetaryValueOff: toFixDecimal(calResults.monetaryValueOff),
          }
        }
      }
    }

    return null
  }

  function toggleMarketingCopy() {
    if (marketingCopyContent == null || marketingCopyContent === '') {
      setMarketingCopyEnable(value => !value)
    }
  }

  const getSelectedCampaignTypeCode = (): string | undefined => {
    const selectedCampaignTypeId = form.getFieldValue('campaignTypeId')

    return campaignTypeData?.campaignTypes?.edges?.find(c => c && c?.node?.id === selectedCampaignTypeId)?.node?.code
  }

  const getFunnelPreviewUrl = (): string | null => {
    return getFunnelSelectPlanURL(brandCode, countryCode)
  }

  const getFunnelVoucherData = (): FunnelVoucherResponse =>
    generateFunnelVoucherResponse({ ...formData, ...form.getFieldsValue() }, getSelectedCampaignTypeCode() as string)

  const isEditing = !!id

  const DisabledFields = () => {
    if (formType === FORM_TYPE.CampaignDuplicate) {
      return {
        brand: false,
        country: false,
        startsAt: false,
        endsAt: false,
      }
    }

    return {
      brand: hasVouchers ? true : formType === 'Template',
      country: hasVouchers ? true : formType === 'Template',
      startsAt: formType === 'Template',
      endsAt: formType === 'Template',
    }
  }

  function deliveryDays(selectedDays: string[]) {
    return selectedDays
  }

  const onChangeDeliveryDays = (checkedValues: CheckboxValueType[]) => {
    form.setFieldsValue({ deliveryDays: checkedValues })
  }

  const onChangeCommunicationParameters = (value: string) => {
    form.setFieldsValue({ communicationStyle: value })
  }

  const getCommunicationParameterFormValue = () => form.getFieldValue('communicationStyle')

  const labelForSubmitButton = () => {
    if (formType === FORM_TYPE.CampaignDuplicate) {
      return 'Duplicate Campaign'
    }

    return isEditing ? `Update ${formType}` : `Create ${formType}`
  }

  useEffect(() => {
    if (!defaultValues) return
    form.setFieldsValue(defaultValues)
  }, [defaultValues])

  const nextStep = () => {
    void form
      .validateFields()
      .then(() => {
        setFormdata(prevState => ({ ...prevState, ...form.getFieldsValue() }))
        setCurrentStep(currentStep + 1)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const previousStep = () => {
    setCurrentStep(currentStep - 1)
  }

  const toggleFunnelPreview = () => {
    setShowFunnelPreview(!showFunnelPreview)
  }

  const FormFragment = [
    <>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            labelCol={{ span: 24 }}
            name="name"
            label={`${formType} Name`}
            rules={[{ required: true, message: 'Please type a name for your campaign.' }]}
          >
            <Input placeholder={`${formType} Name`} data-testid="campaign-name" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item labelCol={{ span: 24 }} name="description" label={`${formType} Description`}>
            <TextArea placeholder={`${formType} Description`} data-testid="campaign-description" allowClear rows={4} />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item labelCol={{ span: 24 }} name="marketingCopy" label="Marketing copy">
            <TextArea
              placeholder="Marketing Copy"
              data-testid="marketing-copy"
              allowClear
              rows={4}
              disabled={!marketingCopyEnabled}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12} />
        <Col span={12}>
          Use Marketing Copy?
          <Popconfirm
            title="Please confirm"
            description="It will delete the content of Marketing Copy. Proceed?"
            onConfirm={confirm}
            okText="Yes"
            cancelText="No"
            disabled={marketingCopyContent == null || marketingCopyContent === ''}
          >
            <Switch checked={marketingCopyEnabled} style={{ marginLeft: 10 }} onClick={toggleMarketingCopy} />
          </Popconfirm>
        </Col>
      </Row>
    </>,
    <>
      <Row gutter={16}>
        <Col span={8}>
          <CampaignTypeSelect required />
        </Col>

        <Col span={8}>
          <TargetGroupSelect required />
        </Col>

        <Col span={8}>
          <BrandDropdown required={!DisabledFields().brand} disabled={DisabledFields().brand} />
        </Col>
      </Row>

      <Row gutter={16} style={{ height: 96 }}>
        <Col span={8}>
          <CountryDropdown required={!DisabledFields().brand} disabled={DisabledFields().country} />
        </Col>

        <Col span={4}>
          <Form.Item
            labelCol={{ span: 24 }}
            name="startsAt"
            label="Start date"
            rules={[{ required: !DisabledFields().startsAt, message: 'Please select a start date.' }]}
          >
            <DatePicker
              style={{ width: '100%' }}
              data-testid="start-date-calendar"
              className="start-date-calendar"
              placeholder="Select a Start Date"
              format="YYYY-MM-DD"
              disabled={DisabledFields().startsAt}
            />
          </Form.Item>
          {fieldErrors && fieldErrors.startsAt && (
            <>
              {currentStep !== 1 && setCurrentStep(1)}
              <Error message={fieldErrors.startsAt} fieldName="Start date" />
            </>
          )}
        </Col>

        <Col span={4}>
          <Form.Item
            labelCol={{ span: 24 }}
            name="endsAt"
            label="End date"
            rules={[{ required: !DisabledFields().startsAt, message: 'Please select an end date.' }]}
          >
            <DatePicker
              disabled={DisabledFields().endsAt}
              style={{ width: '100%' }}
              data-testid="expiration-date-calendar"
              className="expiration-date-calendar"
              placeholder="Select an End Date"
              format="YYYY-MM-DD"
            />
          </Form.Item>
          {fieldErrors && fieldErrors.endsAt && (
            <>
              {currentStep !== 1 && setCurrentStep(1)}
              <Error message={fieldErrors.endsAt} fieldName="End date" />
            </>
          )}
        </Col>

        <Col span={8}>
          <Form.Item name="platform">
            <PlatformSelect />
          </Form.Item>
        </Col>
      </Row>

      <Divider orientation="left">Optional Restrictions</Divider>

      <Row gutter={16}>
        <Col>
          <Form.Item
            name="deliveryDays"
            valuePropName="checked"
            label="Delivery Day"
            labelCol={{ span: 24 }}
            tooltip="For which delivery day this campaign is valid. Leave it blank if it has no restriction."
          >
            <DeliveryDaysCheckbox
              selectedDeliveryDays={defaultValues ? deliveryDays((defaultValues as any).deliveryDays as any[]) : []}
              onChange={onChangeDeliveryDays}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col>
          <Form.Item
            name="deliveryWeeks"
            valuePropName="checked"
            label="Delivery Week"
            labelCol={{ span: 24 }}
            tooltip="Delivery period in which the campaign's voucher can be used. Leave blank if it has no restriction."
          >
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item
                  labelCol={{ span: 24 }}
                  name="deliveryWeekStartsAt"
                  label="Start date"
                  rules={[
                    ({ getFieldValue }) => ({
                      required: !!getFieldValue('deliveryWeekEndsAt'),
                      message: 'Please select a start date.',
                    }),
                  ]}
                >
                  <DatePicker
                    style={{ width: '100%' }}
                    data-testid="start-week-calendar"
                    className="start-week-calendar"
                    placeholder="Select a Start Date"
                    format="YYYY-MM-DD"
                  />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  labelCol={{ span: 24 }}
                  name="deliveryWeekEndsAt"
                  label="End date"
                  rules={[
                    ({ getFieldValue }) => ({
                      required: !!getFieldValue('deliveryWeekStartsAt'),
                      message: 'Please select an end date.',
                    }),
                  ]}
                >
                  <DatePicker
                    style={{ width: '100%' }}
                    data-testid="expiration-week-calendar"
                    className="expiration-week-calendar"
                    placeholder="Select an End Date"
                    format="YYYY-MM-DD"
                  />
                </Form.Item>
                {fieldErrors && fieldErrors.deliveryWeekEndsAt && (
                  <>
                    {currentStep !== 1 && setCurrentStep(1)}
                    <Error message={fieldErrors.deliveryWeekEndsAt} fieldName="Delivery week end date" />
                  </>
                )}
              </Col>
            </Row>
          </Form.Item>
        </Col>
      </Row>
    </>,
    <>
      <Row>
        <Form.List name="voucherSettings">
          {(fields, props) => (
            <VouchersSettingsForm
              formlistFields={fields}
              formlistOperation={props}
              formInstance={form}
              duplicated={formType === FORM_TYPE.CampaignDuplicate}
              onRowChange={index => setVoucherSettingsRowCursor(index)}
            />
          )}
        </Form.List>
      </Row>
      {brandCode !== 'BM' && (
        <>
          <Row>
            {plansData && !plansLoading ? (
              <VoucherSettingsVariantManager
                key={`${countryCode}-${brandCode}`}
                formInstance={form}
                duplicate={formType === FORM_TYPE.CampaignDuplicate}
                voucherSettingsRowIndex={voucherSettingsRowCursor}
                onRowChange={() => setVoucherSettingsRowCursor(-1)}
                calculateVariantParams={variantParams}
                discountSummary={discountSummary}
              />
            ) : (
              <Col span={24}>
                <LoadingIndicator text="Loading plans" />
              </Col>
            )}
          </Row>

          <Form.Item name="communicationStyle">
            <CampaignCommunicationParameters onChange={onChangeCommunicationParameters} />
          </Form.Item>

          <Row justify="center">
            <Col>
              <PrimaryButton onClick={toggleFunnelPreview} block>
                Preview funnel
              </PrimaryButton>
            </Col>

            {!!getFunnelPreviewUrl() && (
              <FunnelPreviewModal
                showFunnelPreview={showFunnelPreview}
                toggleFunnelPreview={toggleFunnelPreview}
                getFunnelUrl={getFunnelPreviewUrl}
                getFunnelVoucherData={getFunnelVoucherData}
                onChangeCommunicationParameters={onChangeCommunicationParameters}
                getCommunicationParameterFormValue={getCommunicationParameterFormValue}
              />
            )}
          </Row>
        </>
      )}
    </>,
  ]

  const formBuilder = (formType: string): JSX.Element | JSX.Element[] => {
    if (formType === FORM_TYPE.CampaignDuplicate) {
      return (
        <>
          {FormFragment[0]}
          {FormFragment[1]}
          {FormFragment[2]}
        </>
      )
    }

    return FormFragment
  }

  return (
    <>
      <PageHeader title={id ? `Edit ${formType}` : `Create ${formType}`} onBack={history.goBack}>
        <BreadCrumbs param={id ? { name: 'id', value: id } : undefined} />
      </PageHeader>

      <Row justify="center">
        <Col md={24} lg={18}>
          <Form.Provider>
            <Form
              form={form}
              onValuesChange={onValuesChange}
              onFinish={values => onSubmitForm({ ...formData, ...values })}
            >
              {formType !== FORM_TYPE.CampaignDuplicate && (
                <Steps size="small" current={currentStep} style={{ margin: '20px 0 40px' }}>
                  <Step title="General" />
                  <Step title="Restrictions" />
                  <Step title="Settings" />
                </Steps>
              )}
              {formType !== FORM_TYPE.CampaignDuplicate && (formBuilder(formType) as JSX.Element[])[currentStep]}
              {formType === FORM_TYPE.CampaignDuplicate && formBuilder(formType)}
              <Divider />
              <Row gutter={16} justify="center">
                {currentStep > 0 && (
                  <Col span={8}>
                    <SecondaryButton onClick={previousStep} block>
                      Back
                    </SecondaryButton>
                  </Col>
                )}

                <Col span={8}>
                  {formType !== FORM_TYPE.CampaignDuplicate && currentStep < FormFragment.length - 1 ? (
                    <PrimaryButton type="primary" onClick={nextStep} block>
                      Next step
                    </PrimaryButton>
                  ) : (
                    <Button type="primary" htmlType="submit" loading={isLoading} block>
                      {labelForSubmitButton()}
                    </Button>
                  )}
                </Col>
              </Row>
              <Divider />
            </Form>
          </Form.Provider>
        </Col>
      </Row>
    </>
  )
}
export default CampaignsForm
CampaignsForm.defaultProps = {
  defaultValues: { voucherSettings: [defaultSetting] },
}
