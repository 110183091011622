import { Button, Form, Col, Row, Table, Space, Switch } from 'antd'
import { Store } from 'antd/lib/form/interface'
import React from 'react'
import { generatePath, Link } from 'react-router-dom'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'

import {
  CampaignTemplate,
  useCampaignTemplatesQuery,
  useUpdateCampaignTemplateMutation,
} from '../../apollo/generated/api'
import { ScreenBreakpoints } from '../../constants/LayoutConstants'
import { useNetworkStatus } from '../../hooks'
import { handleError } from '../../utils/errorUtils'
import { parseEmpty } from '../../utils/textUtils'
import { persistLocalStorage } from '../../utils/localStorageUtils'
import { LAST_SEARCHED_CAMPAIGN_TEMPLATE_FILTERS } from '../../constants/AppConstants'
import { handleMutationResult } from '../../apollo'
import { Routes } from '../../constants/RoutesConstants'

import CampaignTemplateSearch from './CampaignTemplateSearch'

const PAGE_SIZE = 25

type RemoteCampaignTemplate = { node: CampaignTemplate }

const CampaignTemplateList: React.FC = () => {
  const [form] = Form.useForm()
  const { data, refetch, fetchMore, networkStatus } = useCampaignTemplatesQuery({
    variables: { first: PAGE_SIZE },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  })

  const [updateCampaignTemplate, { loading: updating }] = useUpdateCampaignTemplateMutation()

  const entries = (data?.campaignTemplates?.edges || []) as RemoteCampaignTemplate[]
  const { isLoading, isLoadingMore } = useNetworkStatus(networkStatus)
  const pageInfo = data?.campaignTemplates?.pageInfo
  const canLoadMore = !!entries?.length && pageInfo?.hasNextPage

  const handleLoadMore = () => {
    if (pageInfo?.hasNextPage && pageInfo?.endCursor) {
      void handleError(() => fetchMore?.({ variables: { after: pageInfo.endCursor } }))
    }
  }

  const handleFilter = (filters: Store) => {
    const variables = {
      first: PAGE_SIZE,
      filters: {
        name: parseEmpty(filters.name),
        campaignType: filters.campaignTypeId,
        targetGroup: filters.targetGroup,
        platform: filters.platform,
        isActive: filters.isActive,
      },
    }

    persistLocalStorage(LAST_SEARCHED_CAMPAIGN_TEMPLATE_FILTERS, filters)
    void handleError(() => refetch?.(variables))
  }

  const onTemplateStatusChange = async (id: string, isActive: boolean) => {
    const mutation = updateCampaignTemplate({
      variables: {
        input: {
          id,
          isActive,
        },
      },
    })
    const { data } = await handleMutationResult(mutation, 'updateCampaignTemplate', {
      notifications: {
        success: {
          title: 'Status Updated',
        },
      },
    })

    if (data?.updateCampaignTemplate?.successful) {
      void refetch()
    }
  }

  return (
    <>
      <CampaignTemplateSearch form={form} onSubmit={handleFilter} />

      <Table
        rowKey="node.id"
        scroll={{ x: ScreenBreakpoints.SM }}
        dataSource={entries}
        loading={isLoading}
        columns={[
          { title: '#', dataIndex: ['node', 'id'] },
          { title: 'Name', dataIndex: ['node', 'name'] },
          { title: 'Description', dataIndex: ['node', 'description'] },
          {
            title: 'Campaign Type',
            render: ({ node: { campaignType } }: RemoteCampaignTemplate): JSX.Element => <p>{campaignType.name}</p>,
          },

          {
            title: 'Target Group',
            render: ({ node: { targetGroup } }: RemoteCampaignTemplate): JSX.Element => <p>{targetGroup}</p>,
          },
          {
            title: 'Active',
            render: ({ node: { id, isActive } }: RemoteCampaignTemplate): JSX.Element => (
              <Switch
                disabled={updating}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                checked={isActive || false}
                onChange={() => onTemplateStatusChange(id, !isActive)}
              />
            ),
          },
          {
            title: '',
            render: ({ node: { id } }: RemoteCampaignTemplate): JSX.Element => (
              <Space size="middle">
                <Link to={generatePath(Routes.CAMPAIGN_TEMPLATE_DETAIL, { id })}>View</Link>
                <Link to={generatePath(Routes.CAMPAIGN_TEMPLATE_EDIT, { id })}>Edit</Link>
              </Space>
            ),
          },
        ]}
        pagination={false}
      />

      {canLoadMore && (
        <Row justify="center" style={{ paddingTop: '1.5rem' }}>
          <Col>
            <Button onClick={handleLoadMore} loading={isLoadingMore}>
              Load More
            </Button>
          </Col>
        </Row>
      )}
    </>
  )
}

export default CampaignTemplateList
