import React, { useState } from 'react'
import { Layout as AntLayout } from 'antd'
import Styled from 'styled-components'

import { getFullYear } from '../../utils'

import SideBar from './sidebar'
import Header from './header'

const { Content } = AntLayout

type LayoutProps = {
  children: React.ReactNode
}
const Footer = Styled(AntLayout.Footer)`
  color: rgba(0, 0, 0, 0.5);
  font-size: 11px;
  text-align: center;
`
const Layout: React.FC<LayoutProps> = ({ children }) => {
  const mediaQuery = window.matchMedia('(max-width: 1200px)')
  const [isMenuCollapsed, setIsMenuCollapsed] = useState(mediaQuery.matches)
  const onMenuClick = (value: boolean) => setIsMenuCollapsed(value)

  return (
    <AntLayout style={{ minHeight: '100vh' }}>
      <SideBar isMenuCollapsed={isMenuCollapsed} />
      <AntLayout>
        <Header isCollapsed={isMenuCollapsed} onMenuClick={onMenuClick} />
        <Content style={{ margin: 15, background: 'white', height: '100%' }}>{children}</Content>
        <Footer>&copy; {getFullYear()} Marley Spoon</Footer>
      </AntLayout>
    </AntLayout>
  )
}

export default Layout
