export const getLocalStorage = <T>({ key, parse = false }: { key: string; parse?: boolean }): T | null => {
  const value = localStorage.getItem(key)
  const parsedValue = parse && value ? JSON.parse(value) : value

  return parsedValue ? (parsedValue as T) : parsedValue
}

export const deleteFromLocalStorage = (keys: string[]): void => {
  return keys.forEach(key => localStorage.removeItem(key))
}

export const persistLocalStorage = <T extends object>(key: string, data: T): void => {
  const currentValue = getLocalStorage<T>({ key, parse: true })
  const extendedValue = { ...currentValue, ...data }

  localStorage.setItem(key, JSON.stringify(extendedValue))
}

interface ToString {
  toString(): string
}

export const setLocalStorage = <T extends ToString>({
  key,
  value,
  stringify = false,
}: {
  key: string
  value: T
  stringify?: boolean
}): void => {
  const formattedValue = stringify ? JSON.stringify(value) : value.toString()

  localStorage.setItem(key, formattedValue)
}

export const clearAppLocalStorageData = (): void => {
  return localStorage.clear()
}
