import React from 'react'
import { useParams } from 'react-router'

import RewardCampaignsForm from '../RewardCampaignsForm'
import { Campaign, useCampaignQuery } from '../../../apollo/generated/rewards'

const RewardCampaignsEdit: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const { data } = useCampaignQuery({ variables: { id }, fetchPolicy: 'cache-and-network' })

  const campaign = data?.campaign

  return <>{campaign && <RewardCampaignsForm editMode campaign={campaign as Campaign} />}</>
}

export default RewardCampaignsEdit
