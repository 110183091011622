import { Menu, Row, Image, MenuProps } from 'antd'
import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { SoundFilled, UserOutlined, TrophyOutlined } from '@ant-design/icons'

import { Routes } from '../../constants/RoutesConstants'
import Logo from '../../assets/logo.png'
import config from '../../config'
import Stage from '../../constants/StagesConstants'

import { SidebarContainer } from './styles'

type MenuItem = Required<MenuProps>['items'][number]

const SideBar: React.FC<{ isMenuCollapsed: boolean }> = ({ isMenuCollapsed }) => {
  const { pathname } = useLocation()
  const selectedMenu = () => {
    const path: { [key: string]: string } = {
      '/campaigns': '1',
      '/templates': '2',
      '/referral-programs': '3',
      '/vouchers': '4',
      '/rewards/campaigns': '5',
      '/users': '6',
    }

    return path[pathname]
  }
  function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group',
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label,
      type,
    } as MenuItem
  }

  const rootSubmenuKeys = ['promotions_sub_menu', 'rewards_sub_menu']
  const [openKeys, setOpenKeys] = useState([rootSubmenuKeys[0]])

  const items: MenuItem[] = [
    getItem('Promotions', rootSubmenuKeys[0], <SoundFilled />, [
      getItem(<Link to={Routes.CAMPAIGNS}>Campaigns</Link>, '1', undefined, undefined),
      getItem(<Link to={Routes.CAMPAIGN_TEMPLATE}>Campaign Templates</Link>, '2', undefined, undefined),
      getItem(<Link to={Routes.REFERRAL_PROGRAMS}>Referral Programs</Link>, '3', undefined, undefined),
      getItem(<Link to={Routes.VOUCHER_SEARCH}>Vouchers</Link>, '4', undefined, undefined),
    ]),
    config.stage !== Stage.PRODUCTION
      ? getItem('Rewards', rootSubmenuKeys[1], <TrophyOutlined />, [
          getItem(<Link to={Routes.REWARD_CAMPAIGNS}>Reward Campaigns</Link>, '5', undefined, undefined),
        ])
      : null,
    getItem(<Link to={Routes.MANAGE_USERS}>Users</Link>, '6', <UserOutlined />),
  ]

  const onOpenChange: MenuProps['onOpenChange'] = keys => {
    const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1)
    if (rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
      setOpenKeys(keys)
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : [])
    }
  }

  const Branding = () => (
    <Row style={{ margin: 0, justifyContent: 'center', display: 'flex' }}>
      <Link to={Routes.CAMPAIGNS}>
        <Image
          src={Logo}
          alt="Breadcrumbs"
          className="logo"
          width={isMenuCollapsed ? 51 : 120}
          height={isMenuCollapsed ? 66 : 100}
          preview={false}
        />
      </Link>
    </Row>
  )

  return (
    <SidebarContainer trigger={null} breakpoint="xl" collapsible collapsed={isMenuCollapsed} width={250}>
      <Branding />
      <Menu
        mode="inline"
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        items={items}
        selectedKeys={[selectedMenu()]}
      />
    </SidebarContainer>
  )
}

export default SideBar
