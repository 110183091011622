import React, { useEffect, useState } from 'react'
import { generatePath, useHistory, useParams } from 'react-router'
import { Spin } from 'antd'

import { handleMutationResult } from '../../apollo'
import { useCampaignQuery, useCreateCampaignMutation } from '../../apollo/generated/api'
import { Routes } from '../../constants/RoutesConstants'
import NotFoundError from '../../packages/UnauthorizedComponentError'
import { buildCampaignDefaultValues, buildCreateCampaignPayload, CampaignFormData } from '../../utils/campaignUtils'
import { ErrorPayload } from '../Error/types'

import CampaignsForm from './CampaignsForm'

interface Props {
  shouldBlockNavigation: (value: boolean) => void
}

const CampaignDuplicate: React.FC<Props> = ({ shouldBlockNavigation }) => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const [fieldErrors, setFieldErrors] = useState<ErrorPayload>()
  const [defaultValues, setDefaultValues] = useState<Partial<CampaignFormData>>()

  const { data: campaignData, error: campaignQueryError, loading } = useCampaignQuery({ variables: { id }, skip: !id })

  const [createCampaign, { loading: updating, data: duplicateCampaignData }] = useCreateCampaignMutation()

  useEffect(() => {
    setDefaultValues(buildCampaignDefaultValues(campaignData))
  }, [campaignData])

  if (campaignQueryError) {
    return <NotFoundError />
  }

  const handleSubmit = async (values: CampaignFormData) => {
    shouldBlockNavigation?.(false)

    const mutation = createCampaign({ variables: { input: buildCreateCampaignPayload(values) } })
    const { data } = await handleMutationResult(mutation, 'createCampaign', {
      notifications: {
        success: {
          title: `Campaign Duplicated`,
        },
      },
    })
    if (data?.createCampaign?.successful) {
      const id = data.createCampaign.result?.id
      // form.resetFields()
      if (id) {
        history.push(generatePath(Routes.CAMPAIGN_DETAIL, { id }))
      }
    }
  }

  const handleValueChange = (e: any) => {
    shouldBlockNavigation?.(true)

    if (fieldErrors) {
      delete fieldErrors[Object.keys(e)[0]]
    }
  }

  useEffect(() => {
    let errorMessages: ErrorPayload = {}
    const mutationResponse = duplicateCampaignData?.createCampaign
    if (mutationResponse && !mutationResponse.successful) {
      mutationResponse.messages?.forEach(message => {
        if (message?.__typename === 'ValidationMessage') {
          errorMessages = {
            ...errorMessages,
            [(message.field as string) || '']: message.message || '',
          }
        }
      })
      setFieldErrors(errorMessages)
    }
  }, [duplicateCampaignData])

  return (
    <>
      {loading && (
        <div style={{ display: 'grid', placeItems: 'center', height: '100vh' }}>
          <Spin tip="Loading" />
        </div>
      )}
      {!loading && (
        <CampaignsForm<CampaignFormData, Partial<CampaignFormData>>
          onSubmitForm={handleSubmit}
          onValuesChange={handleValueChange}
          fieldErrors={fieldErrors}
          isLoading={updating}
          formType="Duplicate"
          defaultValues={defaultValues}
        />
      )}
    </>
  )
}

export default CampaignDuplicate
