import List from './CampaignTemplates'
import Create from './CampaignTemplateCreate'
import Details from './CampaignTemplateDetails/CampaignTemplateDetails'
import Update from './CampaignTemplateUpdate'
import GenerateCampaigns from './CampaignsGenerator'

const CampaignTemplates = {
  List,
  Create,
  Details,
  Update,
  GenerateCampaigns,
}

export default CampaignTemplates
