export const camelize = (text: string): string => {
  const txt = text.replace(/[-_\s.]+(.)?/g, (_, c: string) => (c ? c.toUpperCase() : ''))

  return txt.substr(0, 1).toLowerCase() + txt.substr(1)
}

export const normalize = (value: string): string => value.normalize(`NFD`).replace(/[\u0300-\u036f]/g, ``)

export const parseBoolean = (value: string): boolean | undefined => (value ? value === 'true' : undefined)

export const parseEmpty = (value?: string): string | undefined => {
  if (!value) {
    return undefined
  }

  const trimmedValue = value.trim()

  return trimmedValue === '' ? undefined : trimmedValue
}

export const capitalize = (text: string): string => `${text.charAt(0).toUpperCase()}${text.slice(1).toLowerCase()}`
