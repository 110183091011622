import { Form, Select } from 'antd'
import humanizeString from 'humanize-string'
import React from 'react'

import { PlatformType } from '../../apollo/generated/api'

interface Props {
  label?: string
  required?: boolean
  onClear?: (fieldName: string) => void
}

function PlatformSelect({ label, required, onClear }: Props): JSX.Element {
  const onClearHandler = () => {
    if (onClear) {
      onClear('platform')
    }
  }

  return (
    <Form.Item
      labelCol={{ span: 24 }}
      label={label}
      name="platform"
      rules={[{ required, message: 'Please select a platform.' }]}
    >
      <Select
        style={{ width: '100%' }}
        placeholder="Select Platform"
        data-testid="platform"
        optionFilterProp="children"
        allowClear
        showSearch
        onClear={onClearHandler}
      >
        {Object.values(PlatformType).map(value => (
          <Select.Option value={value} key={value}>
            {humanizeString(value)}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}

PlatformSelect.defaultProps = {
  label: 'Platform',
  required: false,
  onClear: undefined,
}

export default PlatformSelect
