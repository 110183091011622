import { Store } from 'antd/lib/form/interface'
import moment from 'moment'

import {
  CreateVoucherBatchInput,
  CreateVoucherInput,
  Maybe,
  Scalars,
  UpdateVoucherInput,
  VoucherFiltersInput,
  VoucherQuery,
} from '../../apollo/generated/api'
import { getLocalStorage, persistLocalStorage } from '../localStorageUtils'
import { usageLimitField, customerUsageLimitField, validatableFields } from '../../constants/VoucherFormConstants'

export type VoucherBatchPayload = {
  quantity: number
  prefix: string
  separator?: string
  suffix?: string
  redemptionLimitPerCustomer?: number
  totalRedemptionLimit?: number
}

export type VoucherPayload = {
  code: Scalars['String']
  redemptionLimitPerCustomer?: Maybe<number> | undefined
  totalRedemptionLimit?: Maybe<number> | undefined
  expiresAt?: Maybe<string> | undefined
}

export type VoucherFormData = Omit<VoucherPayload, 'expiresAt'> & {
  expiresAt: moment.Moment
}

export function usageLimitValidator(
  currentField: string,
  currentFieldValue: number,
  valueToCompare: number,
): Promise<void> {
  if (
    (currentField === usageLimitField && currentFieldValue < valueToCompare) ||
    (currentField === customerUsageLimitField && currentFieldValue > valueToCompare)
  ) {
    return Promise.reject(new Error(validatableFields[currentField]))
  }

  return Promise.resolve()
}

export function buildCreateVoucherBatchPayload(
  campaignId: string,
  values: VoucherBatchPayload,
): CreateVoucherBatchInput {
  return { campaignId, ...values }
}

export function buildCreateVoucherPayload(campaignId: string, values: VoucherPayload): CreateVoucherInput {
  return {
    campaignId,
    ...values,
    ...(values.expiresAt && { expiresAt: moment.utc(values.expiresAt).endOf('day').toISOString() }),
  }
}

export function buildUpdateVoucherPayload(id: string, values: VoucherPayload): UpdateVoucherInput {
  return {
    id,
    ...values,
    ...(values.expiresAt && { expiresAt: moment.utc(values.expiresAt).endOf('day').toISOString() }),
  }
}

export function buildVoucherDefaultValues(data?: VoucherQuery | null): Partial<VoucherFormData> {
  if (!data?.voucher) {
    return {}
  }

  const { voucher } = data

  return {
    code: voucher.code,
    redemptionLimitPerCustomer: voucher.redemptionLimitPerCustomer,
    totalRedemptionLimit: voucher.totalRedemptionLimit,
    ...(voucher.expiresAt && { expiresAt: moment.utc(voucher.expiresAt) }),
  }
}

export const getCachedVoucherSearchFilters = (key: string): VoucherFiltersInput => {
  return getLocalStorage({
    key,
    parse: true,
  }) as VoucherFiltersInput
}

export const getCachedVoucherCodeFilter = (key: string, campaignId: string): VoucherFiltersInput => {
  const filters =
    (getLocalStorage({
      key,
      parse: true,
    }) as { [campaignId: string]: VoucherFiltersInput }) || {}

  return filters[campaignId] || {}
}

export const cacheVoucherCodeFilter = (key: string, filters: Store): void => {
  persistLocalStorage(key, { [filters.campaignId]: { code: filters.code } })
}
