import { Form, Select } from 'antd'
import React from 'react'

interface Props {
  label: string
  name: string
  testId?: string
  required?: boolean
  onClear?: (fieldName: string) => void
}

function BooleanSelect({ label, name, required, testId, onClear }: Props): JSX.Element {
  const onClearHandler = () => {
    if (onClear) {
      onClear(name)
    }
  }

  return (
    <Form.Item label={label} name={name} rules={[{ required, message: 'Please select an option.' }]}>
      <Select
        style={{ width: '100%' }}
        placeholder="All"
        optionFilterProp="children"
        data-testid={testId}
        allowClear
        showSearch
        onClear={onClearHandler}
      >
        <Select.Option data-testid="option" value="true">
          Yes
        </Select.Option>
        <Select.Option data-testid="option" value="false">
          No
        </Select.Option>
      </Select>
    </Form.Item>
  )
}

BooleanSelect.defaultProps = {
  required: false,
  testId: undefined,
  onClear: undefined,
}

export default BooleanSelect
