/* eslint-disable @typescript-eslint/no-unsafe-call */
import { InMemoryCache, ApolloClient, NormalizedCacheObject, HttpLink, DocumentNode, ApolloLink } from '@apollo/client'
import { getOperationName, relayStylePagination } from '@apollo/client/utilities'

import config from '../config'
import { getUserSession } from '../utils'

import introspectionResult from './generated/introspection'

const customFetch = (uri: RequestInfo, options?: RequestInit): Promise<Response> => {
  const headers = new Headers({
    ...options?.headers,
    authorization: getAuthorization(),
  })
  const config = options ?? {}

  config.headers = headers

  return fetch(uri, config)
}

const rewardsCustomFetch = (uri: RequestInfo, options?: RequestInit): Promise<Response> => {
  const headers = new Headers({
    ...options?.headers,
    authorization: getAuthorization(),
  })

  const fetchConfig = options ?? {}

  fetchConfig.headers = headers

  return fetch(uri, fetchConfig)
}

const getAuthorization = (): string => {
  const token = getUserSession().session?.token.value

  return token ? `Bearer ${token}` : ''
}

const cache = new InMemoryCache({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  possibleTypes: introspectionResult.possibleTypes,
  typePolicies: {
    Query: {
      fields: {
        campaigns: relayStylePagination(),
        vouchers: relayStylePagination(),
        campaignTemplates: relayStylePagination(),
      },
    },
  },
})
const link = new HttpLink({
  uri: config.promotionsGraphqlApiEndpoint,
  fetch: customFetch,
})

const rewardsLink = new HttpLink({
  uri: config.rewardsGraphQlApiEndpoint,
  fetch: rewardsCustomFetch,
})

export const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  cache,
  link: ApolloLink.split(
    operation => {
      const [, serviceName] = operation.operationName.split('_')

      return serviceName === 'Rewards'
    },
    rewardsLink,
    link,
  ),
})

export const queryNames = (...documents: DocumentNode[]): string[] => {
  const names = documents.map(doc => getOperationName(doc))

  return names.filter((name): name is string => name != null)
}
