import { Descriptions } from 'antd'
import humanizeString from 'humanize-string'
import React from 'react'

import { CampaignTemplate } from '../../../apollo/generated/api'
import { presentDeliveryDays } from '../../../utils/campaignUtils'

interface CampaignTemplateSummaryProps {
  campaignTemplate: CampaignTemplate
}
const CampaignTemplateSummary: React.FC<CampaignTemplateSummaryProps> = ({ campaignTemplate }) => {
  return (
    <div>
      <Descriptions style={{ fontWeight: 'bold' }} layout="vertical" column={4} bordered>
        <Descriptions.Item label="Template name">{campaignTemplate?.name}</Descriptions.Item>
        <Descriptions.Item label="Template description" span={2}>
          {campaignTemplate?.description}
        </Descriptions.Item>
        <Descriptions.Item label="Campaign type">{campaignTemplate?.campaignType.name}</Descriptions.Item>
        <Descriptions.Item label="Target group">
          {humanizeString(campaignTemplate?.targetGroup || 'All customers')}
        </Descriptions.Item>

        <Descriptions.Item label="Platform">
          {campaignTemplate?.platform ? humanizeString(campaignTemplate?.platform) : 'All'}
        </Descriptions.Item>
        <Descriptions.Item label="Marketing Copy">{campaignTemplate?.marketingCopy || 'N/A'}</Descriptions.Item>
        <Descriptions.Item label="Delivery Days">
          {presentDeliveryDays(campaignTemplate.deliveryDays as string[])}
        </Descriptions.Item>
      </Descriptions>
    </div>
  )
}

export default CampaignTemplateSummary
