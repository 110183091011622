import { Modal } from 'antd'
import React, { useEffect, useState } from 'react'

import { PrimaryButton } from '../globalStyles'
import { FunnelVoucherResponse } from '../../utils/funnelUtils'
import CampaignCommunicationParameters from '../Campaigns/CampaignCommunicationParameters'

import FunnelPreviewContainer from './FunnelPreviewContainer'

interface Props {
  showFunnelPreview: boolean
  toggleFunnelPreview: () => void
  getFunnelUrl: () => string | null
  getFunnelVoucherData: () => FunnelVoucherResponse
  onChangeCommunicationParameters: (value: string) => void
  getCommunicationParameterFormValue: () => string
}

function FunnelPreviewModal({
  showFunnelPreview,
  toggleFunnelPreview,
  getFunnelUrl,
  getFunnelVoucherData,
  onChangeCommunicationParameters,
  getCommunicationParameterFormValue,
}: Props): JSX.Element {
  const [iframeKey, setIframeKey] = useState(0)
  const [iframeVoucherData, setIframeVoucherData] = useState({} as FunnelVoucherResponse)
  const [selectedPrameter, setSelectedParameter] = useState('')

  const reloadIframe = () => {
    setIframeKey(iframeKey + 1)
  }

  const onClosePreview = () => {
    toggleFunnelPreview()
    reloadIframe()
  }

  const onSaveParameter = () => {
    onClosePreview()
    onChangeCommunicationParameters(selectedPrameter)
  }

  const onChangeParameter = (value: string): void => {
    setIframeVoucherData({ ...iframeVoucherData, communication_style: value })
    setSelectedParameter(value)
    reloadIframe()
  }

  useEffect(() => {
    if (showFunnelPreview) {
      const voucherData = getFunnelVoucherData()

      setIframeVoucherData(voucherData)
    }

    setSelectedParameter(getCommunicationParameterFormValue())
  }, [showFunnelPreview])

  return (
    <Modal
      className="funnel-preview-modal"
      open={showFunnelPreview}
      onCancel={onClosePreview}
      footer={[
        <PrimaryButton key="save-parameters-btn" onClick={onSaveParameter}>
          Save parameter
        </PrimaryButton>,
      ]}
    >
      <div style={{ height: '15%' }}>
        <CampaignCommunicationParameters onChange={onChangeParameter} value={selectedPrameter} />
      </div>

      <div style={{ height: '85%' }}>
        <FunnelPreviewContainer
          iframeKey={iframeKey}
          getFunnelUrl={getFunnelUrl}
          iframeVoucherData={iframeVoucherData}
        />
      </div>
    </Modal>
  )
}

export default FunnelPreviewModal
