import { Button, Col, Row, Table } from 'antd'
import React from 'react'
import { generatePath, Link } from 'react-router-dom'

import { ReferralProgramEdge, useReferralProgramsQuery } from '../../../apollo/generated/api'
import { Routes } from '../../../constants/RoutesConstants'
import { handleError } from '../../../utils/errorUtils'

import ReferralProgramsFilter, { FormData } from './ReferralProgramsFilter'

const PAGE_SIZE = 200

function ReferralProgramsList(): JSX.Element {
  const { data, loading, refetch, fetchMore } = useReferralProgramsQuery({
    variables: { first: PAGE_SIZE },
    fetchPolicy: 'cache-and-network',
  })

  const entries = (data?.referralPrograms?.edges || []) as ReferralProgramEdge[]
  const pageInfo = data?.referralPrograms?.pageInfo
  const canLoadMore = !!entries?.length && pageInfo?.hasNextPage

  const handleFilter = ({ brandId, countryId }: FormData) => {
    const filters = {
      ...(brandId ? { brand: brandId } : {}),
      ...(countryId ? { country: countryId } : {}),
    }

    refetch({ filters }).catch(error => {
      console.error(error)
    })
  }

  const handleLoadMore = () => {
    if (pageInfo?.hasNextPage && pageInfo?.endCursor) {
      void handleError(() => fetchMore?.({ variables: { after: pageInfo.endCursor } }))
    }
  }

  return (
    <>
      <ReferralProgramsFilter onSubmit={handleFilter} />

      <Table
        rowKey={entry => entry.node?.id || ''}
        dataSource={entries}
        loading={loading}
        columns={[
          { title: 'Brand', dataIndex: ['node', 'brand', 'name'] },
          { title: 'Country', dataIndex: ['node', 'country', 'name'] },
          { title: 'Min. credits to refer', dataIndex: ['node', 'minCreditToRefer'] },
          { title: 'Max. referral credits', dataIndex: ['node', 'maxReferralCredits'] },
          { title: 'Referral expires in (days)', dataIndex: ['node', 'expiresIn'] },
          // {
          //   title: 'Rewards',
          //   render: ({ node: { referralRewardEnabled } }): JSX.Element => (
          //     <Tag color={referralRewardEnabled ? 'success' : 'default'}>
          //       {referralRewardEnabled ? 'Enabled' : 'Disabled'}
          //     </Tag>
          //   ),
          // },
          {
            title: '',
            render: ({ node: { id } }): JSX.Element => (
              <Link key={id} to={generatePath(Routes.REFERRAL_PROGRAM_EDIT, { id })}>
                View details
              </Link>
            ),
          },
        ]}
        pagination={false}
      />

      {canLoadMore && (
        <Row justify="center" style={{ paddingTop: '1.5rem' }}>
          <Col>
            <Button onClick={handleLoadMore} loading={loading}>
              Load More
            </Button>
          </Col>
        </Row>
      )}
    </>
  )
}

export default ReferralProgramsList
