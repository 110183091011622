import React from 'react'
import ReactDOM from 'react-dom'
import { ApolloProvider } from '@apollo/client'
import { BrowserRouter as Router } from 'react-router-dom'

import './styles/base.css'

import App from './components/App'
import { client } from './apollo'
import { QueryProvider } from './services/QueryProvider'

ReactDOM.render(
  <ApolloProvider client={client}>
    <QueryProvider>
      <Router>
        <App />
      </Router>
    </QueryProvider>
  </ApolloProvider>,
  document.getElementById('root'),
)
