import { Form, Select } from 'antd'
import React from 'react'

import { useCampaignTypesQuery } from '../../apollo/generated/api'

interface Props {
  label?: string
  required?: boolean
  onClear?: (fieldName: string) => void
}

const PAGE_SIZE = 200

function CampaignTypeSelect({ label, required, onClear }: Props): JSX.Element {
  const { data } = useCampaignTypesQuery({ variables: { first: PAGE_SIZE } })

  const options = data?.campaignTypes?.edges || []
  const onClearHandler = () => {
    if (onClear) {
      onClear('campaignTypeId')
    }
  }

  return (
    <Form.Item
      labelCol={{ span: 24 }}
      label={label}
      name="campaignTypeId"
      rules={[{ required, message: 'Please select a campaign type.' }]}
    >
      <Select
        allowClear
        showSearch
        placeholder="Select Campaign Type"
        optionFilterProp="children"
        style={{ width: '100%' }}
        onClear={onClearHandler}
      >
        {options.map(option => {
          return (
            option?.node?.name !== 'Referral' &&
            option?.node?.name !== 'Referrer' && (
              <Select.Option value={option?.node?.id || ''} key={option?.node?.id}>
                {option?.node?.name}
              </Select.Option>
            )
          )
        })}
      </Select>
    </Form.Item>
  )
}

CampaignTypeSelect.defaultProps = {
  label: 'Campaign type',
  required: false,
  onClear: undefined,
}

export default React.memo(CampaignTypeSelect)
