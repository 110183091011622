import { Card, Popconfirm, Space, Table, Typography } from 'antd'
import Search from 'antd/lib/input/Search'
import React, { useEffect } from 'react'
import humanizeString from 'humanize-string'

import { queryNames } from '../../apollo/client'
import {
  Account,
  AccountsDocument,
  AccountsQueryVariables,
  Scalars,
  UserRoleType,
  useAccountsLazyQuery,
  useDeleteAccountMutation,
} from '../../apollo/generated/api'
import { handleMutationResult } from '../../apollo/mutations'
import { ScreenBreakpoints } from '../../constants/LayoutConstants'
import useRelayPagination from '../../hooks/useRelayPagination'
import { ContentMargin } from '../Layout/styles'
import { canAccessRoleFeatures } from '../../utils/authUtils/authUtils'

import Pagination from './Pagination'

const { Link } = Typography

const PAGE_SIZE = 10
const { Column } = Table

const UsersTable: React.FC<{
  handleUpdate: (userData: Account) => void
}> = ({ handleUpdate }) => {
  const { currentCursor, hasPreviousPage, handleNextPage, handlePreviousPage } = useRelayPagination()
  const [getAccounts, { loading: loadingAccounts, data }] = useAccountsLazyQuery({
    variables: { first: PAGE_SIZE, after: currentCursor.current || null },
    fetchPolicy: 'cache-and-network',
  })
  const [deleteAccount, { loading: loadingDeleteAccount }] = useDeleteAccountMutation()

  useEffect(() => {
    void getAccounts()
  }, [])

  const onSearch = async (value: string) => {
    const queryVariables: AccountsQueryVariables = {
      filters: { email: value },
      first: PAGE_SIZE,
    }

    await getAccounts({ variables: queryVariables })
  }

  const onDeleteAction = async (id: Scalars['ID']): Promise<void> => {
    await handleMutationResult(
      deleteAccount({
        variables: { input: { id } },
        refetchQueries: queryNames(AccountsDocument),
        awaitRefetchQueries: true,
      }),
      'deleteAccount',
    )
  }
  const edges = data?.accounts?.edges || []
  const pageInfo = data?.accounts?.pageInfo
  const accounts = edges.map(e => e?.node as Account)

  const onNextPage = () => {
    if (pageInfo?.hasNextPage) {
      handleNextPage(pageInfo?.endCursor)
    }
  }

  return (
    <div style={ContentMargin}>
      <Card style={{ marginBottom: 20 }}>
        <Search data-testid="search" placeholder="Search" allowClear size="middle" onSearch={onSearch} />
      </Card>

      <Table
        dataSource={accounts}
        loading={loadingAccounts}
        scroll={{ x: ScreenBreakpoints.SM }}
        rowKey="id"
        style={{ minHeight: 500 }}
        pagination={false}
      >
        <Column title="Email" align="left" render={({ email }: Account) => email} />
        {canAccessRoleFeatures(UserRoleType.SuperAdmin) && (
          <>
            <Column
              title="Role"
              align="left"
              render={({ userRoles }: Account) => {
                const formattedRoles = userRoles?.map(role => (role ? humanizeString(role) : ''))

                return formattedRoles?.join(', ')
              }}
            />
            <Column
              title="Actions"
              align="right"
              render={({ id, ...userData }: Account) => (
                <Space size="middle">
                  <Popconfirm
                    title="Are you sure you want to delete this user?"
                    placement="right"
                    okText="Yes"
                    onConfirm={() => onDeleteAction(id || '')}
                  >
                    <Link type="danger" data-testid="delete-button" disabled={loadingDeleteAccount}>
                      Delete
                    </Link>
                  </Popconfirm>
                  <Link data-testid="edit-button" onClick={() => handleUpdate({ id, ...userData })}>
                    Edit
                  </Link>
                </Space>
              )}
            />
          </>
        )}
      </Table>

      <Pagination
        hasNextPage={pageInfo?.hasNextPage}
        hasPreviousPage={hasPreviousPage}
        onNextPage={onNextPage}
        onPreviousPage={handlePreviousPage}
      />
    </div>
  )
}

export default UsersTable
