import styled from 'styled-components'

export const SwitchWrapper = styled.div`
  background-color: rgb(247, 249, 251);
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  margin-bottom: 1.5rem;
`

export const SwitchLabel = styled.label`
  font-weight: 500;
`
