import { PageHeader } from '@ant-design/pro-components'
import { Button, Modal } from 'antd'
import React, { useState } from 'react'

import { Account } from '../../apollo/generated/api'

import UserForm from './UserForm'
import UsersTable from './UsersTable'

const ManageUsers: React.FC<{}> = () => {
  const [userFormVisible, setUserFormVisible] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [userData, setUserData] = useState<Account | null>(null)

  const toggleUserModal = (editing = false, userData: Account | null = null): void => {
    setUserFormVisible(!userFormVisible)
    setIsEditing(editing)
    setUserData(userData)
  }

  const handleUpdate = (userData: Account): void => {
    toggleUserModal(true, userData)
  }

  return (
    <div>
      <Modal
        title={isEditing ? 'Edit user' : 'Create new user'}
        open={userFormVisible}
        onCancel={() => toggleUserModal()}
        footer={null}
      >
        <UserForm onToggle={toggleUserModal} isEditing={isEditing} userData={userData} />
      </Modal>
      <PageHeader
        title="Users"
        extra={[
          <Button key="new-user" type="primary" onClick={() => toggleUserModal()}>
            New user
          </Button>,
        ]}
      />
      <UsersTable handleUpdate={handleUpdate} />
    </div>
  )
}

export default ManageUsers
