import axios from 'axios'
import { useQuery } from 'react-query'

import config from '../config'
import { PlanType } from '../utils/discountCalculatorUtils/types'

const query = `query GetPlans($country: CountryEnum!, $brand: BrandEnum!, $segment: [SegmentEnum!]) {
  plans(segments: $segment, country: $country, brand: $brand) {
    allowVegetarian
    displayTitle
    planIdentifier
    isDefault
    numberOfMeals
    numberOfPortions
    pricePerPortion
    segment
    segmentDisplayTitle
    shippingCost
    total
    totalWithShipping
  }
}`

const fetchFromAPI = async (brand: string, country: string): Promise<PlanType[]> => {
  const headers = {
    'content-type': 'application/json',
  }
  const body = {
    operationName: 'GetPlans',
    query,
    variables: {
      brand,
      country,
      segment: ['COUPLES', 'FAMILIES'],
    },
  }
  let plansByBrandCountry: PlanType[] = []

  const url = config.publicGraphQlApiEndpoint

  if (!url) throw new Error('No API endpoint provided')

  const { data } = (await axios.post<{ data: { plans: PlanType[] } }>(url, body, { headers })).data
  plansByBrandCountry = data.plans

  return plansByBrandCountry
}

export const usePlans = (brand: string, country: string) => {
  return useQuery(['plans', brand, country], () => fetchFromAPI(brand, country), { enabled: !!brand && !!country })
}
