import React, { useEffect, useState } from 'react'
import { PageHeader } from '@ant-design/pro-components'
import { Col, Form, Row, Steps } from 'antd'
import { generatePath, useHistory } from 'react-router'

import {
  Campaign,
  useCountriesQuery,
  useCreateCampaignMutation,
  useUpdateCampaignMutation,
} from '../../../apollo/generated/rewards'
import { handleMutationResult } from '../../../apollo'
import { Routes } from '../../../constants/RoutesConstants'
import BreadCrumbs from '../../Layout/breadcrumbs'
import FormWizardFooter from '../../Forms/FormWizardFooter'
import { ContentMargin } from '../../Layout/styles'
import { Container } from '../../Campaigns/styles'
import { buildInitialValues, buildPayload } from '../../../utils/rewardUtils'

import { FormData } from './types'
import RewardCampaignsGeneralStep from './RewardCampaignsGeneralStep'
import RewardCampaignsConditionsStep from './RewardCampaignsConditionsStep'

const RewardCampaignsForm: React.FC<{ editMode: boolean; campaign: Campaign }> = ({ editMode, campaign }) => {
  const history = useHistory()
  const [form] = Form.useForm()
  const [formData, setFormdata] = useState<FormData>()
  const [currentStep, setCurrentStep] = useState(0)

  const { data: countriesData } = useCountriesQuery({ fetchPolicy: 'cache-first' })
  const [createCampaign, { loading: creatingCampaign }] = useCreateCampaignMutation()
  const [updateCampaign, { loading: updatingCampaign }] = useUpdateCampaignMutation()

  const selectedCountryId = Form.useWatch('countryId', form) ?? campaign?.country?.id
  const selectedCountry = (countriesData?.countries ?? []).find(country => country?.id === selectedCountryId)
  const selectedTimezone = selectedCountry?.timezone ?? 'UTC'

  useEffect(() => {
    form.setFieldsValue(buildInitialValues(campaign))
  }, [campaign])

  const handleSubmit = async (currentValues: FormData) => {
    const values = { ...formData, ...currentValues }
    const persistValues = editMode ? handleUpdate : handleCreate

    await persistValues(values)
  }

  const handleCreate = async (values: FormData) => {
    const mutation = createCampaign({ variables: { campaign: buildPayload(values, selectedTimezone) } })

    const { data } = await handleMutationResult(mutation, 'createCampaign', {
      notifications: {
        error: {
          title: 'Something went wrong while creating the campaign.',
        },
      },
    })
    if (data?.createCampaign?.successful) {
      history.push(generatePath(Routes.REWARD_CAMPAIGNS))
    }
  }

  const handleUpdate = async (values: FormData) => {
    const mutation = updateCampaign({
      variables: {
        id: campaign.id || '',
        campaign: buildPayload(values, selectedTimezone),
      },
    })

    const { data } = await handleMutationResult(mutation, 'updateCampaign', {
      notifications: {
        error: {
          title: 'Something went wrong while updating the campaign.',
        },
      },
    })

    if (data?.updateCampaign?.successful) {
      history.push(generatePath(Routes.REWARD_CAMPAIGNS))
    }
  }

  const previousStep = () => {
    setCurrentStep(currentStep - 1)
  }

  const nextStep = () => {
    void form
      .validateFields()
      .then(() => {
        setFormdata(state => ({ ...state, ...form.getFieldsValue() }))
        setCurrentStep(currentStep + 1)
      })
      .catch(error => console.log(error))
  }

  const stepsContent = [
    <RewardCampaignsGeneralStep key={0} timezone={selectedTimezone} />,
    <RewardCampaignsConditionsStep key={1} previousValues={formData} />,
  ]

  return (
    <>
      <PageHeader title={editMode ? `Edit Reward Campaign` : `New Reward Campaign`} onBack={history.goBack}>
        <BreadCrumbs param={campaign?.id ? { name: 'id', value: campaign.id } : undefined} />
      </PageHeader>

      <Container style={ContentMargin}>
        <Row justify="center">
          <Col md={24} lg={18}>
            <Form.Provider>
              <Form form={form} layout="vertical" onFinish={handleSubmit}>
                <Steps
                  current={currentStep}
                  items={[{ title: 'General' }, { title: 'Conditions & Rewards' }]}
                  size="default"
                  style={{ margin: '20px 0 40px' }}
                />

                {stepsContent[currentStep]}

                <FormWizardFooter
                  hasBack={currentStep > 0}
                  hasNext={currentStep < stepsContent.length - 1}
                  isEditing={editMode}
                  onPreviousStep={previousStep}
                  onNextStep={nextStep}
                  isLoading={creatingCampaign || updatingCampaign}
                />
              </Form>
            </Form.Provider>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default RewardCampaignsForm
