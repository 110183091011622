import { Row } from 'antd'
import styled from 'styled-components'

import { ScreenBreakpoints } from '../../constants/LayoutConstants'

const FormActionsContainer = styled(Row)`
  @media (min-width: ${ScreenBreakpoints.LG}px) {
    padding-top: 1.85rem;
  }
`

export default FormActionsContainer
