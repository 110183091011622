import React, { useState } from 'react'
import { Card, Col, Form, Input, InputNumber, Row, Typography } from 'antd'
import styled from 'styled-components'
import { RuleObject } from 'antd/lib/form'

import { usageLimitField, customerUsageLimitField } from '../../../constants/VoucherFormConstants'
import { usageLimitValidator } from '../../../utils/voucherUtils'
import { PrimaryButton } from '../../globalStyles'

interface Props {
  isLoading?: boolean
  submitButtonText?: string
  formType: 'Campaign' | 'Template'
}

function MultipleVouchersFormFields({ isLoading, submitButtonText, formType }: Props): React.ReactElement {
  const [prefix, setPrefix] = useState('')
  const [separator, setSeparator] = useState('')
  const [suffix, setSuffix] = useState('')

  const leadingSeparator = prefix ? separator : ''
  const trailingSeparator = suffix ? separator : ''

  return (
    <>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            name="quantity"
            label="How many vouchers you want to create?"
            hasFeedback
            rules={[{ required: true, message: 'Please, inform how many vouchers you want to create' }]}
          >
            <InputNumber min={1} max={1000000} style={{ width: '100%' }} />
          </Form.Item>
        </Col>
      </Row>

      {formType === 'Campaign' && (
        <Row gutter={16}>
          <Col xs={24} md={8}>
            <Form.Item name="prefix" label="Prefix" rules={[{ required: true, message: 'Please, inform a prefix.' }]}>
              <Input onChange={({ target }) => setPrefix(target.value)} />
            </Form.Item>
          </Col>

          <Col xs={24} md={8}>
            <Form.Item name="separator" label="Separator">
              <Input onChange={({ target }) => setSeparator(target.value)} />
            </Form.Item>
          </Col>

          <Col xs={24} md={8}>
            <Form.Item name="suffix" label="Suffix">
              <Input onChange={({ target }) => setSuffix(target.value)} />
            </Form.Item>
          </Col>
        </Row>
      )}

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name={[usageLimitField]}
            label="Total usage limit"
            rules={[
              { required: true, message: 'Please define a usage limit' },
              ({ getFieldValue }) => ({
                validator(rule: RuleObject, value: number) {
                  return usageLimitValidator(usageLimitField, value, getFieldValue(customerUsageLimitField))
                },
              }),
            ]}
            hasFeedback
          >
            <InputNumber min={1} max={1000000} style={{ width: '100%' }} />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name={[customerUsageLimitField]}
            label="Usage limit per customer"
            rules={[
              { required: true, message: 'Please define a usage limit per customer' },
              ({ getFieldValue }) => ({
                validator(rule: RuleObject, value: number) {
                  return usageLimitValidator(customerUsageLimitField, value, getFieldValue(usageLimitField))
                },
              }),
            ]}
            hasFeedback
          >
            <InputNumber min={1} max={1000000} style={{ width: '100%' }} />
          </Form.Item>
        </Col>
      </Row>

      {formType === 'Campaign' && (
        <Row gutter={16}>
          <Col span={24}>
            <Card style={{ textAlign: 'center', marginBottom: '1.25rem' }}>
              <CardDescription>Here is a preview of how your code would look like:</CardDescription>

              <CodePreview>
                {prefix}
                {leadingSeparator}********{trailingSeparator}
                {suffix}
              </CodePreview>
            </Card>
          </Col>
        </Row>
      )}

      <Row gutter={16} justify="center">
        <Col span={8}>
          <PrimaryButton size="large" htmlType="submit" block loading={isLoading}>
            {submitButtonText}
          </PrimaryButton>
        </Col>
      </Row>
    </>
  )
}

MultipleVouchersFormFields.defaultProps = {
  isLoading: false,
  submitButtonText: 'Create Vouchers',
}

const CardDescription = styled.p``

const CodePreview = styled(Typography.Text).attrs({ code: true })`
  font-size: 1.2rem;
  text-transform: uppercase;
`

export default MultipleVouchersFormFields
