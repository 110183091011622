import React from 'react'
import { useParams } from 'react-router'
import { PageHeader } from '@ant-design/pro-components'
import { Space, Spin } from 'antd'

import NotFound from '../../packages/UnauthorizedComponentError'
import { ContentMargin, ContentMarginTop } from '../Layout/styles'
import {
  BlockedCampaignUsage,
  useCustomerQuery,
  Voucher,
  CustomerDocument,
  Scalars,
  useDeleteBlockedCampaignUsageMutation,
  useDeleteVoucherUsageMutation,
} from '../../apollo/generated/api'
import { handleMutationResult } from '../../apollo/mutations'
import { queryNames } from '../../apollo/client'

import BlockedCampaigns from './BlockedCampaigns'
import RedeemedVouchers from './RedeemedVouchers'

const CustomerVoucherProfile: React.FC = () => {
  const { brandCustomerId } = useParams<{ brandCustomerId: string }>()
  const { brandCode } = useParams<{ brandCode: string }>()
  const { error, loading, data } = useCustomerQuery({
    variables: { brandCustomerId: parseInt(brandCustomerId, 10), brandCode },
    fetchPolicy: 'cache-and-network',
  })
  const [deleteBlockedCampaignUsage] = useDeleteBlockedCampaignUsageMutation()
  const [deleteVoucherUsage] = useDeleteVoucherUsageMutation()

  const handleDeleteBlockedCampaign = async (id: Scalars['ID']): Promise<void> => {
    await handleMutationResult(
      deleteBlockedCampaignUsage({
        variables: { input: { id } },
        refetchQueries: queryNames(CustomerDocument),
        awaitRefetchQueries: true,
      }),
      'deleteBlockedCampaignUsage',
    )
  }

  const handleDeleteVoucherUsage = async (id: Scalars['ID']): Promise<void> => {
    await handleMutationResult(
      deleteVoucherUsage({
        variables: { input: { id } },
        refetchQueries: queryNames(CustomerDocument),
        awaitRefetchQueries: true,
      }),
      'deleteVoucherUsage',
    )
  }

  const blockedCampaigns = data?.customer?.blockedCampaigns as BlockedCampaignUsage[]

  const redeemedVouchers = data?.customer?.redeemedVouchers as Voucher[]

  if (loading) {
    return (
      <div style={{ textAlign: 'center', marginTop: 100 }}>
        <Spin size="large" tip="Loading details..." />
      </div>
    )
  }

  if (error || (data && data.customer === null)) {
    return <NotFound message="Customer not found" />
  }

  return (
    <>
      <PageHeader title={`Customer #${brandCustomerId}`} />
      <Space direction="vertical" size="large" style={{ display: 'flex' }}>
        <div style={ContentMargin} data-testid="customer-voucher-profile">
          <BlockedCampaigns blockedCampaigns={blockedCampaigns} onDelete={handleDeleteBlockedCampaign} />
        </div>
        <div style={{ ...ContentMargin, ...ContentMarginTop }} data-testid="customer-voucher-profile">
          <RedeemedVouchers redeemedVouchers={redeemedVouchers} onDelete={handleDeleteVoucherUsage} />
        </div>
      </Space>
    </>
  )
}

export default CustomerVoucherProfile
