import { Button, Card, Checkbox, Col, Collapse, Divider, FormInstance, Row, Space, Tooltip } from 'antd'
import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { useWatch } from 'antd/lib/form/Form'

import { VoucherSetting } from '../../apollo/generated/api'
import { DiscountSummary, Plan, VoucherVariantFormField } from '../../types'
import { DiscountCalculatorProps, InputCalculatorResult } from '../../utils/discountCalculatorUtils/types'
import { toFixDecimal } from '../../utils/numberUtils/numberUtils'
import PlanSelector from '../PlanSelector'

import VouchersSettingsVariantForm from './VoucherSettingsVariantForm'

interface PanelHeaderProps {
  plan: Plan
  planIndex: number
  onRemove: (planIndex: number) => void
  discountSummary?: DiscountSummary
}

interface Props {
  discountSummary?: DiscountSummary
  duplicate?: boolean
  formInstance: FormInstance<{ voucherSettings?: VoucherSetting[] | undefined }>
  voucherSettingsRowIndex?: number
  onRowChange?: (index: number) => void /** Updated whenever voucher settings variants  add/delete */
  calculateVariantParams?: (
    payload: Omit<DiscountCalculatorProps, 'planPriceData'>,
    noOfMeals: number,
    noOfPersons: number,
  ) => InputCalculatorResult | null
}

function VoucherSettingsVariantManager({
  discountSummary,
  formInstance,
  duplicate,
  voucherSettingsRowIndex = -1,
  onRowChange,
  calculateVariantParams,
}: Props): React.ReactElement {
  const [numberOfMeals, setNumberOfMeals] = useState<string>()
  const [numberOfPersons, setNumberOfPersons] = useState<string>()
  const [plans, setPlans] = useState<Plan[] | undefined>(undefined)
  const [showVoucherSettingsVariants, setShowVoucherSettingsVariants] = useState(false)
  const voucherSettings: VoucherSetting[] | undefined =
    useWatch('voucherSettings', formInstance) || formInstance.getFieldValue('voucherSettings')

  const hasPlans = () => plans && plans.length > 0

  useMemo(() => {
    if (!voucherSettings) return
    if (voucherSettings.length > 0 && voucherSettings[0]) {
      const variants = voucherSettings[0].voucherSettingVariants || []
      if (variants.length > 0) {
        setShowVoucherSettingsVariants(true)
      }

      if (!plans || !plans.length) {
        const existingVariants = voucherSettings[0].voucherSettingVariants?.filter(variant => !!variant) || []

        const existingPlans = existingVariants?.map(variant => ({
          numberOfPersons: String(variant?.numberOfPersons),
          numberOfMeals: String(variant?.numberOfMeals),
        }))

        if (existingPlans?.length) {
          setPlans(existingPlans)
        }
      }
    }
  }, [voucherSettings])

  function onAddPlan() {
    if (onRowChange) {
      onRowChange(-1)
    }
    if (isExistingPlan(numberOfMeals, numberOfPersons)) {
      return
    }

    if (numberOfMeals && numberOfPersons) {
      setPlans([...(plans || []), { numberOfMeals, numberOfPersons }])

      setNumberOfMeals(undefined)
      setNumberOfPersons(undefined)
    }
  }

  function onRemovePlan(planIndex: number) {
    if (onRowChange) {
      onRowChange(-1)
    }
    if (!plans) return

    const updatedPlans = [...plans]
    const removedPlan = updatedPlans.splice(planIndex, 1)
    setPlans(updatedPlans)

    if (!voucherSettings) return

    const voucherSettingVariants: VoucherVariantFormField[][] = formInstance.getFieldValue('voucherSettingVariants')
    const updatedVoucherSettingVariants = voucherSettingVariants.filter(
      variant =>
        String(variant?.[0].numberOfMeals) !== removedPlan[0].numberOfMeals ||
        String(variant?.[0].numberOfPersons) !== removedPlan[0].numberOfPersons,
    )

    const updatedVoucherSettings = voucherSettings.map(setting => {
      return {
        ...setting,
        voucherSettingVariants: setting.voucherSettingVariants?.filter(
          variant =>
            String(variant?.numberOfMeals) !== removedPlan[0].numberOfMeals ||
            String(variant?.numberOfPersons) !== removedPlan[0].numberOfPersons,
        ),
      }
    })

    formInstance.setFieldValue('voucherSettings', updatedVoucherSettings)
    formInstance.setFieldValue('voucherSettingVariants', updatedVoucherSettingVariants)
  }

  function isExistingPlan(currentNumberOfMeals?: string, currrentNumberOfPersons?: string) {
    return !!plans?.find(
      plan => plan.numberOfMeals === currentNumberOfMeals && plan.numberOfPersons === currrentNumberOfPersons,
    )
  }

  return (
    <Space hidden direction="vertical" size="middle" style={{ width: '100%' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Checkbox
          style={{ marginLeft: 16 }}
          onClick={() => {
            setShowVoucherSettingsVariants(value => !value)
          }}
          checked={showVoucherSettingsVariants}
        >
          Define discounts per plan
        </Checkbox>
      </div>
      {showVoucherSettingsVariants && (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Card>
            <Row gutter={16}>
              <Col xs={24} md={8} lg={10}>
                <PlanSelector.PlanPeopleDropdown
                  numberOfPersons={numberOfPersons}
                  setNumberOfPersons={setNumberOfPersons}
                />
              </Col>

              <Col xs={24} md={8} lg={10}>
                <PlanSelector.PlanMealsDropdown numberOfMeals={numberOfMeals} setNumberOfMeals={setNumberOfMeals} />
              </Col>

              <Col xs={24} md={8} lg={4}>
                <Button type="default" block disabled={!numberOfMeals || !numberOfPersons} onClick={onAddPlan}>
                  Add plan
                </Button>
              </Col>
            </Row>
          </Card>

          {hasPlans() && (
            <Collapse defaultActiveKey={['1']} onChange={() => undefined} style={{ marginTop: '8px' }}>
              {(plans as Plan[]).map((plan, index) => (
                <Collapse.Panel
                  header={
                    <PanelHeader
                      plan={plan}
                      planIndex={index}
                      onRemove={onRemovePlan}
                      discountSummary={discountSummary}
                    />
                  }
                  key={`item-${index + 1}`}
                  forceRender
                >
                  <VouchersSettingsVariantForm
                    plan={{ index, ...plan }}
                    formInstance={formInstance}
                    duplicate={duplicate}
                    voucherSettingsRowIndex={voucherSettingsRowIndex}
                    calculateVariantParams={calculateVariantParams}
                  />
                  {discountSummary && (
                    <StyledCard>
                      <Row>
                        <StyledCol span={20}>
                          <StyledLabel>Total free meals</StyledLabel>
                          <span>{discountSummary.get(`${plan.numberOfMeals}-${plan.numberOfPersons}`)?.freeMeals}</span>
                        </StyledCol>
                        <StyledCol>
                          <Divider type="vertical" style={{ height: '100%' }} />
                        </StyledCol>
                        <StyledCol span={20}>
                          <StyledLabel>Total voucher amount</StyledLabel>
                          <span>
                            {toFixDecimal(
                              discountSummary.get(`${plan.numberOfMeals}-${plan.numberOfPersons}`)?.totalDiscount || 0,
                            )}
                          </span>
                        </StyledCol>
                      </Row>
                    </StyledCard>
                  )}
                </Collapse.Panel>
              ))}
            </Collapse>
          )}
        </div>
      )}
    </Space>
  )
}

export default React.memo(VoucherSettingsVariantManager)

function PanelHeader({ plan, planIndex, onRemove, discountSummary }: PanelHeaderProps): React.ReactElement {
  const unavailablePlanTooltipText = 'This plan is unavailable for selected brand and country.'
  const planKey = `${plan.numberOfMeals}-${plan.numberOfPersons}`
  function handleRemove() {
    onRemove(planIndex)
  }

  return (
    <Row align="middle" justify="space-between">
      {!discountSummary?.get(planKey) && (
        <Tooltip title={unavailablePlanTooltipText}>
          <span style={{ color: 'red' }}> {`${plan.numberOfPersons} people, ${plan.numberOfMeals} meals`} </span>{' '}
          <ExclamationCircleOutlined style={{ color: 'red' }} />
        </Tooltip>
      )}
      {discountSummary && discountSummary.get(planKey) && (
        <span>{`${plan.numberOfPersons} people, ${plan.numberOfMeals} meals`}</span>
      )}

      <Button type="default" onClick={handleRemove}>
        Remove
      </Button>
    </Row>
  )
}
const StyledLabel = styled.div`
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 12px;
  text-align: center;
`

const StyledCard = styled(Card)`
  width: 50%;
  margin-top: 20px;
  margin-left: 25%;
  align-self: center;
`

const StyledCol = styled(Col)`
  justify-content: center;
  align-items: center;
  flex: 1;
  display: flex;
  flex-direction: column;
`
