import { ControlFilled, PlusCircleOutlined } from '@ant-design/icons'
import { Button, Col, Form, Result, Row, Select, Space, Typography } from 'antd'
import React from 'react'

import RewardCampaignsEligibilityCondition from './RewardCampaignsEligibilityCondition'

const RewardCampaignsConditionsForm: React.FC = () => {
  return (
    <Space direction="vertical" size="middle" style={{ width: '100%' }}>
      <Typography.Title level={3}>Conditions</Typography.Title>

      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            labelCol={{ span: 24 }}
            name="leadingMetric"
            label="Leading Metric"
            initialValue="ORDER_ORDINAL"
            help="Defines the metric that will be used to compute the customer's progress towards this challenge."
            rules={[{ required: true }]}
          >
            <Select
              disabled
              options={[
                {
                  value: 'ORDER_ORDINAL',
                  label: 'Order Ordinal',
                },
              ]}
            />
          </Form.Item>
        </Col>
      </Row>

      <Form.List name="eligibilityConditions">
        {(fields, { add, remove }) =>
          fields.length === 0 ? (
            <Result
              icon={<ControlFilled style={{ color: 'rgba(0, 0, 0, 0.1)' }} />}
              title="No conditions"
              subTitle="This campaign has no eligibility conditions."
              style={{ border: '1px solid rgba(0, 0, 0, 0.06)' }}
              extra={
                <Button type="default" size="large" icon={<PlusCircleOutlined />} onClick={add}>
                  Add condition
                </Button>
              }
            />
          ) : (
            <Space direction="vertical" size="middle" style={{ width: '100%' }}>
              {fields.map(({ key, ...restField }) => (
                <RewardCampaignsEligibilityCondition key={key} field={restField} onRemove={remove} />
              ))}
              <Form.Item>
                <Button type="dashed" onClick={() => add()} icon={<PlusCircleOutlined />} block>
                  Add condition
                </Button>
              </Form.Item>
            </Space>
          )
        }
      </Form.List>
    </Space>
  )
}

export default RewardCampaignsConditionsForm
