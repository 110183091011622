import React, { useEffect, useRef } from 'react'

import { FunnelVoucherResponse } from '../../utils/funnelUtils'

interface Props {
  iframeKey: number
  iframeVoucherData: FunnelVoucherResponse
  getFunnelUrl: () => string | null
}

function FunnelPreviewContainer({ iframeKey, getFunnelUrl, iframeVoucherData }: Props): JSX.Element {
  const funnelUrl = `${getFunnelUrl() as string}?preview=true`
  const iframeRef = useRef<HTMLIFrameElement>(null)

  const postMessage = (message: string) => {
    iframeRef.current?.contentWindow?.postMessage(message, funnelUrl)
  }

  useEffect(() => {
    const handler = (event: MessageEvent): void => {
      const message = JSON.stringify(iframeVoucherData)

      if (event.data.src === 'web-next-funnel' && event.data.type === 'ready') {
        postMessage(message)
      }
    }

    window.addEventListener('message', handler)

    return () => window.removeEventListener('message', handler)
  }, [iframeVoucherData])

  return (
    <iframe
      ref={iframeRef}
      key={iframeKey}
      className="funnel-preview-iframe"
      title="Funnel Preview"
      src={funnelUrl as string}
      allowFullScreen
    >
      <p>Your browser does not support iframes.</p>
    </iframe>
  )
}

export default FunnelPreviewContainer
