import { Button, Form, Table, Row, Col, Space } from 'antd'
import { Store } from 'antd/lib/form/interface'
import moment from 'moment'
import React from 'react'
import { generatePath, Link } from 'react-router-dom'

import { Campaign, CampaignFiltersInput, useCampaignsQuery } from '../../../apollo/generated/api'
import { ScreenBreakpoints } from '../../../constants/LayoutConstants'
import { Routes } from '../../../constants/RoutesConstants'
import { useNetworkStatus } from '../../../hooks'
import { handleError } from '../../../utils/errorUtils'
import { parseCampaignSearchFilters } from '../../../utils/campaignUtils'
import { getLocalStorage, persistLocalStorage } from '../../../utils/localStorageUtils'
import { LAST_SEARCHED_CAMPAIGN_FILTERS } from '../../../constants/AppConstants'

import CampaignsSearchForm from './CampaignsSearchForm'

const PAGE_SIZE = 25

type RemoteCampaign = { node: Pick<Campaign, 'id' | 'endsAt'> }

const CampaignsSearch: React.FC = () => {
  const [form] = Form.useForm()
  const lastSearchedFilters =
    (getLocalStorage({
      key: LAST_SEARCHED_CAMPAIGN_FILTERS,
      parse: true,
    }) as CampaignFiltersInput) || {}

  const { data, refetch, fetchMore, networkStatus } = useCampaignsQuery({
    variables: { first: PAGE_SIZE, filters: parseCampaignSearchFilters(lastSearchedFilters) },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  })

  const { isLoading, isLoadingMore } = useNetworkStatus(networkStatus)

  const entries = (data?.campaigns?.edges || []) as RemoteCampaign[]
  const pageInfo = data?.campaigns?.pageInfo
  const canLoadMore = !!entries?.length && pageInfo?.hasNextPage

  const handleLoadMore = () => {
    if (pageInfo?.hasNextPage && pageInfo?.endCursor) {
      void handleError(() => fetchMore?.({ variables: { after: pageInfo.endCursor } }))
    }
  }

  const handleFilter = (filters: Store) => {
    const variables = {
      first: PAGE_SIZE,
      filters: parseCampaignSearchFilters(filters),
    }

    persistLocalStorage(LAST_SEARCHED_CAMPAIGN_FILTERS, filters)

    void handleError(() => refetch?.(variables))
  }

  return (
    <>
      <CampaignsSearchForm form={form} onSubmit={handleFilter} />

      <Table
        rowKey={campaign => campaign?.node?.id as string}
        data-testid="search-voucher-results-table"
        dataSource={entries}
        loading={isLoading}
        scroll={{ x: ScreenBreakpoints.SM }}
        columns={[
          { title: '#', dataIndex: ['node', 'id'] },
          { title: 'Brand', dataIndex: ['node', 'brand', 'name'] },
          { title: 'Country', dataIndex: ['node', 'country', 'name'] },
          { title: 'Name', dataIndex: ['node', 'name'] },
          { title: 'Description', dataIndex: ['node', 'description'] },
          {
            title: 'End date',
            render: ({ node: { endsAt } }: RemoteCampaign): JSX.Element => <p>{moment(endsAt).format('LL')}</p>,
          },
          {
            title: '',
            render: ({ node: { id } }: RemoteCampaign): JSX.Element => (
              <Space size="middle">
                <Link to={generatePath(Routes.CAMPAIGN_DUPLICATE, { id })}>Duplicate</Link>
                <Link to={generatePath(Routes.CAMPAIGN_DETAIL, { id })}>View</Link>
                <Link to={generatePath(Routes.CAMPAIGN_EDIT, { id })}>Edit</Link>
              </Space>
            ),
          },
        ]}
        pagination={false}
      />

      {canLoadMore && (
        <Row justify="center" style={{ paddingTop: '1.5rem' }}>
          <Col>
            <Button onClick={handleLoadMore} loading={isLoadingMore}>
              Load More
            </Button>
          </Col>
        </Row>
      )}
    </>
  )
}

export default CampaignsSearch
