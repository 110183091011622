import React, { useEffect, useState } from 'react'
import { Route, Switch, Redirect, RouteComponentProps, Prompt, useHistory } from 'react-router-dom'
import { ConfigProvider, notification } from 'antd'

import MainContainer from '../../containers/MainContainer'
import GeneralError from '../../packages/GeneralError'
import { NOT_FOUND } from '../../constants/MessagesConstants'
import { Routes } from '../../constants/RoutesConstants'
import { getUserSession } from '../../utils'
import { PrivateRoute } from '../Routing'
import Login from '../Login'
import Campaigns from '../Campaigns'
import ReferralPrograms from '../ReferralPrograms'
import Vouchers from '../Vouchers'
import ManageUsers from '../ManageUsers'
import CampaignTemplates from '../CampaignTemplates/index'
import RewardCampaigns from '../RewardCampaigns'
import CustomerVoucherProfile from '../CustomerVoucherProfiles'

const CatchAll: React.FC<RouteComponentProps & { isAuthenticated: boolean }> = ({ location, isAuthenticated }) => {
  const isHomepage = location.pathname === Routes.ROOT
  const shouldRedirect = !isAuthenticated || isHomepage

  if (shouldRedirect) {
    const redirectPath = isAuthenticated ? Routes.CAMPAIGNS : Routes.LOGIN

    return <Redirect to={redirectPath} />
  }

  return (
    <MainContainer>
      <GeneralError message={NOT_FOUND} status={404} />
    </MainContainer>
  )
}

const shouldActivateNavigationBlocker = ['/campaigns/new']

const App: React.FC = () => {
  const { hasValidSession } = getUserSession()
  const [shouldStopNavigation, setShouldStopNavigation] = useState<boolean>(false)
  const history = useHistory()

  useEffect(() => {
    const unlisten = history.listen(location => {
      if (!shouldActivateNavigationBlocker.includes(location.pathname)) {
        setShouldStopNavigation(false)
      }
    })

    return unlisten()
  }, [])
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault()
      // eslint-disable-next-line no-param-reassign
      event.returnValue = 'Are you sure you want to leave this? Changes that you have made may not be saved.'
    }

    if (shouldStopNavigation) {
      window.addEventListener('beforeunload', handleBeforeUnload)
    }

    return () => window.removeEventListener('beforeunload', handleBeforeUnload)
  }, [shouldStopNavigation])
  notification.config({ placement: 'bottomRight' })

  return (
    <ConfigProvider
      button={{
        style: { color: '#000' },
      }}
      theme={{
        token: {
          colorPrimary: '#FADC5C',
          colorBgLayout: '#FFF',
          colorLink: '#28B88D',
          colorPrimaryText: '#000',
        },
      }}
    >
      <div className="app">
        <Prompt
          when={shouldStopNavigation}
          message={location => {
            setShouldStopNavigation(false)

            return shouldActivateNavigationBlocker.includes(location.pathname)
              ? true
              : 'Are you sure you want to leave this? Changes that you have made may not be saved.'
          }}
        />
        <Switch>
          <Route path={Routes.LOGIN} component={Login} />
          <PrivateRoute path={Routes.DASHBOARD} render={() => <div>Dashboard</div>} exact />
          <PrivateRoute path={Routes.CAMPAIGNS} component={Campaigns.List} exact />
          <PrivateRoute
            path={Routes.CAMPAIGNS_CREATE}
            render={() => (
              <Campaigns.Create shouldBlockNavigation={(value: boolean) => setShouldStopNavigation(value)} />
            )}
            exact
          />
          <PrivateRoute path={Routes.CAMPAIGN_EDIT} component={Campaigns.Update} exact />
          <PrivateRoute path={Routes.CAMPAIGN_DETAIL} component={Campaigns.Details} exact />
          <PrivateRoute path={Routes.CAMPAIGN_TEMPLATE} component={CampaignTemplates.List} exact />
          <PrivateRoute path={Routes.CAMPAIGN_DUPLICATE} component={Campaigns.Duplicate} exact />
          <PrivateRoute
            path={Routes.CAMPAIGN_TEMPLATE_CREATE}
            render={() => (
              <CampaignTemplates.Create shouldBlockNavigation={(value: boolean) => setShouldStopNavigation(value)} />
            )}
            exact
          />
          <PrivateRoute path={Routes.CAMPAIGN_TEMPLATE_DETAIL} component={CampaignTemplates.Details} exact />
          <PrivateRoute path={Routes.CAMPAIGN_TEMPLATE_EDIT} component={CampaignTemplates.Update} exact />
          <PrivateRoute path={Routes.CAMPAIGNS_GENERATOR} component={CampaignTemplates.GenerateCampaigns} exact />
          <PrivateRoute path={Routes.VOUCHER_SEARCH} component={Vouchers.Search} exact />
          <PrivateRoute path={Routes.VOUCHER_CREATE} component={Vouchers.Form} exact />
          <PrivateRoute path={Routes.VOUCHER_DETAIL} component={Vouchers.Details} exact />
          <PrivateRoute path={Routes.VOUCHER_EDIT} component={Vouchers.Form} exact />
          <PrivateRoute path={Routes.REFERRAL_PROGRAMS} component={ReferralPrograms.List} exact />
          <PrivateRoute path={Routes.REFERRAL_PROGRAM_EDIT} component={ReferralPrograms.Details} exact />
          <PrivateRoute path={Routes.REWARD_CAMPAIGNS} component={RewardCampaigns.List} exact />
          <PrivateRoute path={Routes.REWARD_CAMPAIGNS_EDIT} component={RewardCampaigns.Edit} exact />
          <PrivateRoute path={Routes.REWARD_CAMPAIGNS_NEW} component={RewardCampaigns.New} exact />
          <PrivateRoute path={Routes.REWARD_CAMPAIGNS_DETAIL} component={RewardCampaigns.Details} exact />
          <PrivateRoute path={Routes.MANAGE_USERS} component={ManageUsers} exact />
          <PrivateRoute path={Routes.CUSTOMER_VOUCHER_PROFILE} component={CustomerVoucherProfile} exact />
          <Route render={(props): React.ReactElement => <CatchAll {...props} isAuthenticated={hasValidSession()} />} />
        </Switch>
      </div>
    </ConfigProvider>
  )
}

export default App
