import Styled from 'styled-components'

export const PaginationContainer = Styled.div`
  display: flex;
  align-items: center;
  background: white;
  justify-content: end;
  border-top: 1px solid #f0f0f0;
  padding: 10px 10px 20px 10px;
`
