import { Col, Divider, Form, InputNumber, Row, Select } from 'antd'
import React from 'react'
import useFormInstance from 'antd/lib/form/hooks/useFormInstance'
import { useWatch } from 'antd/lib/form/Form'

import RewardCampaignsConditionsForm from './RewardCampaignsConditionsForm'

type Props = {
  previousValues?: { type: string }
}

const DEFAULT_REWARD_TYPE = 'market_item'

const RewardCampaignsConditionsStep: React.FC<Props> = ({ previousValues }) => {
  const form = useFormInstance()

  const rewardType = useWatch(['settings', 'rewardType'], form) || DEFAULT_REWARD_TYPE
  const discountType = useWatch(['settings', 'valueType'], form)

  const isReferralRewardCampaign = previousValues?.type === 'REFERRAL_REWARD'
  const isPercentageDiscount = discountType === 'percentage'
  const isMarketItemReward = rewardType === 'market_item'

  return (
    <>
      <Row gutter={21}>
        <Col span={24}>
          <Form.Item initialValue={DEFAULT_REWARD_TYPE} name={['settings', 'rewardType']} label="Reward Type">
            <Select
              data-testid="reward-type"
              options={[
                { value: 'market_item', label: 'Market item' },
                { value: 'order_discount', label: 'Order discount' },
              ]}
            />
          </Form.Item>
        </Col>
      </Row>

      {isMarketItemReward && (
        <Row gutter={21}>
          <Col span={24}>
            <Form.Item name={['settings', 'sku']} label="Select SKUs">
              <Select data-testid="sku" mode="tags" notFoundContent="" />
            </Form.Item>
          </Col>
        </Row>
      )}

      <Row gutter={21}>
        <Col span={10}>
          <Form.Item name={['settings', 'valueType']} label="Discount Type">
            <Select
              data-testid="discount-type"
              options={[
                {
                  value: 'percentage',
                  label: 'Percentage',
                },
                {
                  value: 'fixed',
                  label: 'Fixed',
                },
              ]}
            />
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item name={['settings', 'value']} label="Discount Value">
            <InputNumber data-testid="discount-value" style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item
            name={['settings', 'valueLimit']}
            label="Discount Limit"
            tooltip="Percentage-based discounts can have absolute limits."
            hidden={!isPercentageDiscount}
          >
            <InputNumber data-testid="discount-value-limit" style={{ width: '100%' }} min={0} max={100.0} />
          </Form.Item>
        </Col>
      </Row>

      <Divider />

      {!isReferralRewardCampaign && <RewardCampaignsConditionsForm />}
    </>
  )
}

export default RewardCampaignsConditionsStep
