/* eslint-disable no-unused-vars */
export enum Routes {
  ROOT = '/',
  DASHBOARD = '/dashboard',
  LOGIN = '/login',
  CAMPAIGNS = '/campaigns',
  CAMPAIGNS_CREATE = '/campaigns/new',
  CAMPAIGN_DETAIL = '/campaigns/:id',
  CAMPAIGN_EDIT = '/campaigns/:id/edit',
  CAMPAIGN_DUPLICATE = '/campaigns/:id/duplicate',
  CAMPAIGN_TEMPLATE = '/templates',
  CAMPAIGN_TEMPLATE_CREATE = '/templates/new',
  CAMPAIGN_TEMPLATE_EDIT = '/templates/:id/edit',
  CAMPAIGN_TEMPLATE_DETAIL = '/templates/:id',
  CAMPAIGNS_GENERATOR = '/templates/:id/generate/campaigns',
  CUSTOMER_VOUCHER_PROFILE = '/customers/:brandCode/:brandCustomerId',
  VOUCHER_CREATE = '/campaigns/:campaignId/vouchers/new',
  VOUCHER_SEARCH = '/vouchers',
  VOUCHER_DETAIL = '/vouchers/:id',
  VOUCHER_EDIT = '/vouchers/:id/edit',
  REFERRAL_PROGRAMS = '/referral-programs',
  REFERRAL_PROGRAM_EDIT = '/referral-programs/:id/edit',
  MANAGE_USERS = '/users',
  REWARD_CAMPAIGNS = '/rewards/campaigns',
  REWARD_CAMPAIGNS_NEW = '/rewards/campaigns/new',
  REWARD_CAMPAIGNS_EDIT = '/rewards/campaigns/:id/edit',
  REWARD_CAMPAIGNS_DETAIL = '/rewards/campaigns/:id',
}
