import DiscountCalculatorInputType from '../../constants/DiscountCalculatorInputConstants'

import {
  InputCalculatorResult,
  DiscountCalculatorProps,
  BoxDiscountProps,
  TotalDiscountAndFreeMeals,
  PlanType,
} from './types'
import {
  calculateByMonetaryValueOff,
  calculateByPercentageOff,
  calculateByDiscountBoxPrice,
  calculateByDiscountServingPrice,
} from './inputBasedCalculator'
import { calculateFreeMeals, calculateTotalDiscount } from './shared'

export function calculateDiscountValues(props: DiscountCalculatorProps): InputCalculatorResult | null {
  const { calculatorInputType } = props

  switch (calculatorInputType) {
    case DiscountCalculatorInputType.DiscountBoxPrice:
      return calculateByDiscountBoxPrice(props)
    case DiscountCalculatorInputType.DiscountServingPrice:
      return calculateByDiscountServingPrice(props)
    case DiscountCalculatorInputType.PercentageOff:
      return calculateByPercentageOff(props)
    case DiscountCalculatorInputType.MonetaryValueOff:
      return calculateByMonetaryValueOff(props)
    default:
      return null
  }
}

export function calculateTotalDiscountAndFreeMeals(
  planPriceData: PlanType,
  allBoxDiscounts: BoxDiscountProps[],
): TotalDiscountAndFreeMeals {
  const freeMeals = calculateFreeMeals(planPriceData, allBoxDiscounts)
  const totalDiscount = calculateTotalDiscount(planPriceData, allBoxDiscounts)

  return {
    freeMeals,
    totalDiscount,
  }
}
