import { MutableRefObject, useRef, useState } from 'react'

interface UseRelayPaginationReturn {
  currentCursor: MutableRefObject<string | null>
  cursorStack: string[]
  hasPreviousPage: boolean
  handleNextPage: (cursor?: string | null) => void
  handlePreviousPage: () => void
  resetPagination: () => void
}

const CURSOR_QUERY_PARAM = 'after'

function getCurrentCursor(search: URLSearchParams): string {
  const cursor = search.get(CURSOR_QUERY_PARAM)
  if (cursor && !Array.isArray(cursor)) {
    return cursor
  }

  return ''
}

function useRelayPagination(): UseRelayPaginationReturn {
  const { search, href } = window.location
  const parsedQuery = new URLSearchParams(search)
  const currentCursor = useRef<string>(getCurrentCursor(parsedQuery))
  const [cursorStack, setCursorStack] = useState<string[]>(currentCursor.current ? [currentCursor.current] : [])
  const hasPreviousPage = cursorStack.length > 0

  function handleNextPage(cursor?: string | null) {
    if (cursor) {
      const updatedCursorStack = [...cursorStack]

      updatedCursorStack.push(cursor)

      currentCursor.current = cursor
      setCursorStack(updatedCursorStack)

      window.history.pushState('', '', `?after=${cursor}`)
    }
  }

  function handlePreviousPage() {
    const updatedCursorStack = [...cursorStack]

    updatedCursorStack.pop()

    const cursor = updatedCursorStack[updatedCursorStack.length - 1]

    currentCursor.current = cursor
    setCursorStack(updatedCursorStack)
    const url = cursor ? `?after=${cursor}` : href.split('?')[0]

    window.history.pushState('', '', url)
  }

  function resetPagination() {
    currentCursor.current = ''
    setCursorStack([])
    window.history.replaceState('', '', href.split('?')[0])
  }

  return { currentCursor, cursorStack, hasPreviousPage, handleNextPage, handlePreviousPage, resetPagination }
}

export default useRelayPagination
