import React from 'react'
import { PageHeader } from '@ant-design/pro-components'

import { ContentMargin } from '../../Layout/styles'

import ReferralProgramsList from './ReferralProgramsList'

function Referrals(): JSX.Element {
  return (
    <>
      <PageHeader title="Referral Programs" />

      <div style={ContentMargin}>
        <ReferralProgramsList />
      </div>
    </>
  )
}

export default Referrals
