import React, { useEffect, useState } from 'react'
import { generatePath, useHistory } from 'react-router'

import { handleMutationResult } from '../../apollo'
import { useCreateCampaignMutation } from '../../apollo/generated/api'
import { Routes } from '../../constants/RoutesConstants'
import { buildCreateCampaignPayload, CampaignFormData } from '../../utils/campaignUtils'
import { ErrorPayload } from '../Error/types'

import CampaignsForm from './CampaignsForm/CampaignsForm'

interface Props {
  shouldBlockNavigation: (value: boolean) => void
}

const CampaignCreate: React.FC<Props> = ({ shouldBlockNavigation }) => {
  const history = useHistory()
  const [fieldErrors, setFieldErrors] = useState<ErrorPayload>()
  const [createCampaign, { loading: creating, data: createCampaignData }] = useCreateCampaignMutation()

  const handleSubmit = async (values: CampaignFormData) => {
    shouldBlockNavigation?.(false)

    const mutation = createCampaign({ variables: { input: buildCreateCampaignPayload(values) } })

    const { data } = await handleMutationResult(mutation, 'createCampaign', {
      notifications: {
        success: {
          title: `Campaign Created`,
        },
      },
    })

    if (data?.createCampaign?.successful) {
      const id = data.createCampaign.result?.id
      // form.resetFields()
      if (id) {
        history.push(generatePath(Routes.CAMPAIGN_DETAIL, { id }))
      }
    }
  }

  const handleValueChange = (e: any) => {
    shouldBlockNavigation?.(true)

    if (fieldErrors) {
      delete fieldErrors[Object.keys(e)[0]]
    }
  }

  useEffect(() => {
    let errorMessages: ErrorPayload = {}
    const mutationResponse = createCampaignData?.createCampaign
    if (mutationResponse && !mutationResponse.successful) {
      mutationResponse.messages?.forEach(message => {
        if (message?.__typename === 'ValidationMessage') {
          errorMessages = {
            ...errorMessages,
            [(message.field as string) || '']: message.message || '',
          }
        }
      })
      setFieldErrors(errorMessages)
    }
  }, [createCampaignData])

  return (
    <CampaignsForm<CampaignFormData, Partial<CampaignFormData>>
      onSubmitForm={handleSubmit}
      onValuesChange={handleValueChange}
      fieldErrors={fieldErrors}
      isLoading={creating}
      formType="Campaign"
    />
  )
}
export default CampaignCreate
