import { MinusCircleOutlined } from '@ant-design/icons'
import { Button, Card, Col, Form, FormListFieldData, Input, Row, Select } from 'antd'
import React from 'react'

interface Props {
  field: Omit<FormListFieldData, 'key'>
  onRemove: (name: number | number[]) => void
}

const RewardCampaignsEligibilityCondition: React.FC<Props> = ({ field: { name, ...restField }, onRemove }) => {
  const form = Form.useFormInstance()

  const eligibilityConditionType = Form.useWatch(['eligibilityConditions', name, 'targetAttributeName'], {
    form,
    preserve: true,
  })

  const renderEligibilityConditionValueInput = () => {
    switch (eligibilityConditionType) {
      case 'ORDER_PLAN':
        return <Select options={ORDER_PLAN_SEGMENTS} />
      default:
        return <Input style={{ width: '100%' }} />
    }
  }

  console.log(eligibilityConditionType)

  return (
    <Card>
      <Row gutter={16}>
        <Col span={10}>
          <Form.Item
            {...restField}
            name={[name, 'targetAttributeName']}
            label="Type"
            initialValue="ORDER_ORDINAL"
            style={{ marginBottom: 0 }}
          >
            <Select options={ELIGIBILITY_CONDITIONS} />
          </Form.Item>
        </Col>

        <Col span={10}>
          <Form.Item
            {...restField}
            name={[name, 'targetAttributeValue']}
            label="Value"
            rules={[{ required: true }]}
            style={{ marginBottom: 0 }}
          >
            {renderEligibilityConditionValueInput()}
          </Form.Item>
        </Col>

        <Col span={4} style={{ display: 'flex', alignItems: 'end' }}>
          <Button type="default" onClick={() => onRemove(name)} icon={<MinusCircleOutlined />} block>
            Remove
          </Button>
        </Col>
      </Row>
    </Card>
  )
}

const ELIGIBILITY_CONDITIONS = [
  { value: 'ORDER_ORDINAL', label: 'Order Ordinal' },
  { value: 'ORDER_PLAN', label: 'Order Plan' },
  { value: 'EXPERIMENT', label: 'Amplitude Experiment' },
]

const ORDER_PLAN_SEGMENTS = [
  { value: 'COUPLES', label: 'Couples' },
  { value: 'FAMILIES', label: 'Families' },
]

export default RewardCampaignsEligibilityCondition
