import Stage from './constants/StagesConstants'

declare global {
  interface Window {
    env: AppConfig
  }
}

type AppConfig = {
  promotionsGraphqlApiEndpoint: string
  rewardsGraphQlApiEndpoint: string
  restApiBaseUrl: string
  stage: Stage
  googleClientId: string
  rollbarAccessToken: string
  appVersion: string
  publicGraphQlApiEndpoint: string
}

export default window.env
