import { Popconfirm, Table, Tag } from 'antd'
import React, { useState } from 'react'
import { Link, generatePath } from 'react-router-dom'
import moment from 'moment'

import { Campaign, UserRoleType, Voucher, VoucherUsage } from '../../apollo/generated/api'
import { Routes } from '../../constants/RoutesConstants'
import { canAccessRoleFeatures } from '../../utils/authUtils/authUtils'

const { Column } = Table

interface RedeemedVouchersProps {
  redeemedVouchers: Voucher[]
  onDelete?: (id: string) => void
}

const getColorAndTextForTargetGroup = (targetGroup: string) => {
  switch (targetGroup) {
    case 'ALL_CUSTOMERS':
      return { color: '#1d39c4', text: 'OTHER' }
    case 'NEW_CUSTOMERS':
      return { color: '#28B88D', text: 'ACQUISITION' }
    case 'REACTIVATING_CUSTOMERS':
      return { color: '#ffbcbe', text: 'REACTIVATION' }
    default:
      return { color: 'default', text: 'UNKNOWN' }
  }
}

const RedeemedVouchers: React.FC<RedeemedVouchersProps> = ({ redeemedVouchers, onDelete }) => {
  const linkStyle = { color: 'red' }
  const columns = [
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      render: (code: string, record: Voucher) => (
        <Link to={generatePath(Routes.VOUCHER_DETAIL, { id: record.id })}>{code}</Link>
      ),
    },
    {
      title: 'Date of redemption',
      dataIndex: 'usages',
      key: 'usedAt',
      render: (usages: { usedAt: string }[]) => {
        const sortedUsages = usages.slice().sort((a, b) => (a.usedAt > b.usedAt ? 1 : -1))

        return moment(sortedUsages[0].usedAt).format('LL')
      },
    },
    {
      title: 'Campaign',
      dataIndex: 'campaign',
      key: 'campaignName',
      render: (campaign: { id: string; name: string }) => (
        <Link to={generatePath(Routes.CAMPAIGN_DETAIL, { id: campaign?.id })}>{campaign?.name}</Link>
      ),
    },
    {
      title: 'Version',
      key: 'version',
      dataIndex: 'usages',
      render: (usages: VoucherUsage[]) => {
        return usages[0]?.voucherSetting?.version
      },
    },
    {
      title: 'Voucher Type',
      dataIndex: 'campaign',
      key: 'campaignTargetGroup',
      render: (campaign: { id: string; targetGroup: string }) => {
        const { color, text } = getColorAndTextForTargetGroup(campaign.targetGroup)

        return <Tag color={color}>{text}</Tag>
      },
    },
  ]

  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([])

  const customExpandedRowRender = (voucherUsages: VoucherUsage[], campaign: Campaign) => {
    const dataSource = (voucherUsages || []) as VoucherUsage[]
    const totalStages = campaign?.voucherSettings?.length
    const onlyOneUsage = voucherUsages.length === 1

    return (
      <Table dataSource={dataSource} pagination={false}>
        <Column
          title="Voucher stage"
          key="voucherSetting"
          dataIndex="voucherSetting"
          render={record => (
            <span>
              {record.ordinality}/{totalStages}
            </span>
          )}
        />
        <Column title="Date of usage" key="usedAt" dataIndex="usedAt" render={usedAt => moment(usedAt).format('LL')} />
        <Column title="Order number" key="orderNumber" dataIndex="orderNumber" />
        {canAccessRoleFeatures(UserRoleType.SuperAdmin) && (
          <Column
            title="Actions"
            render={(record: VoucherUsage) => (
              <>
                {onlyOneUsage && (
                  <Popconfirm
                    title="Are you sure you want to delete this voucher usage?"
                    onConfirm={() => onDelete?.(record.id)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Link to="#" style={linkStyle} data-testid="delete-usage-button">
                      Remove Usage
                    </Link>
                  </Popconfirm>
                )}
              </>
            )}
          />
        )}
      </Table>
    )
  }

  return (
    <>
      <h1>Vouchers used by customer</h1>
      <Table
        dataSource={
          redeemedVouchers?.map(voucher => ({
            ...voucher,
            key: voucher.id,
          })) || []
        }
        columns={columns}
        pagination={false}
        expandable={{
          columnTitle: 'Usages',
          showExpandColumn: true,
          expandedRowRender: voucher => customExpandedRowRender(voucher.usages as VoucherUsage[], voucher.campaign),
          expandedRowKeys,
          onExpand: (expanded, record) => {
            const rowKey = record.key
            setExpandedRowKeys(prevExpandedRowKeys => {
              if (expanded) {
                return [...prevExpandedRowKeys, rowKey]
              }

              return prevExpandedRowKeys.filter(key => key !== rowKey)
            })
          },
          rowExpandable: voucher => !!voucher?.usages?.length,
        }}
      />
    </>
  )
}

export default RedeemedVouchers
