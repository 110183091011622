import { Card, Col, Form, Input, Row } from 'antd'
import { FormInstance } from 'antd/lib/form'
import { Store } from 'antd/lib/form/interface'
import React, { useEffect, useState } from 'react'
import { FilterFilled, SearchOutlined } from '@ant-design/icons'

import { FormActionsContainer, ClearSearchFiltersButton } from '../../Forms'
import BrandDropdown from '../../BrandDropdown'
import CountryDropdown from '../../CountryDropdown'
import { CampaignTypeSelect, TargetGroupSelect } from '../../Inputs'
import BooleanSelect from '../../Inputs/BooleanSelect'
import PlatformSelect from '../../Inputs/PlatformSelect'
import { getLocalStorage, deleteFromLocalStorage, persistLocalStorage } from '../../../utils/localStorageUtils'
import {
  CAMPAIGN_SEARCH_FORM_FILTERS,
  LAST_SEARCHED_CAMPAIGN_FILTERS,
  CAMPAIGN_EMPTY_SEARCH_DATA,
} from '../../../constants/AppConstants'
import { CampaignFiltersInput } from '../../../apollo/generated/api'
import { PrimaryButton, SecondaryButton } from '../../globalStyles'

interface Props {
  form?: FormInstance
  onSubmit?: (data: Store) => void
}

function CampaignsSearchForm({ form, onSubmit }: Props): JSX.Element {
  const [showsAdvancedFilters, setShowsAdvancedFilters] = useState(false)
  const cachedSearchFilters = getLocalStorage({
    key: CAMPAIGN_SEARCH_FORM_FILTERS,
    parse: true,
  }) as CampaignFiltersInput
  const [defaultSearchFilters, setDefaultSearchFilters] = useState(cachedSearchFilters)

  const validFiltersPresent = () => {
    const searchFilters = cachedSearchFilters || {}
    const filterValues = Object.values(searchFilters)
    const validFilterValues = filterValues.filter(val => val)

    return validFilterValues.length > 0
  }

  const [searchFiltersPresent, setSearchFiltersPresent] = useState(validFiltersPresent())

  const updateFilterState = () => {
    setSearchFiltersPresent(validFiltersPresent())
  }

  const toggleAdvancedFilters = () => {
    setShowsAdvancedFilters((state: boolean) => !state)
  }

  const clearSearchFilters = () => {
    deleteFromLocalStorage([CAMPAIGN_SEARCH_FORM_FILTERS, LAST_SEARCHED_CAMPAIGN_FILTERS])
    form?.setFieldsValue(CAMPAIGN_EMPTY_SEARCH_DATA)
    setDefaultSearchFilters({})
    if (onSubmit) onSubmit({})
  }

  const handleValuesChange = (_changedValues: object, filters: Store) => {
    persistLocalStorage(CAMPAIGN_SEARCH_FORM_FILTERS, filters)
    setDefaultSearchFilters(filters)
    updateFilterState()
  }

  useEffect(() => {
    updateFilterState()
  }, [defaultSearchFilters])

  const onClearField = (fieldName: string) => {
    if (form && onSubmit) {
      const formInputs = {
        ...form.getFieldsValue(),
      }
      formInputs[fieldName] = undefined
      form.setFieldsValue(formInputs)
      onSubmit(form.getFieldsValue())
    }
  }

  /**
   *
   * Input Component doesn't provide us onClear function, So we have to capture onChange separately
   */
  const onChangeInputHandler = (e: React.ChangeEvent<HTMLInputElement> | undefined) => {
    if (e?.target.value === '') {
      onClearField(e.target.name)
    }
  }

  return (
    <Card style={{ marginBottom: 25 }}>
      <Form
        form={form}
        onFinish={onSubmit}
        onValuesChange={handleValuesChange}
        initialValues={defaultSearchFilters}
        layout="vertical"
      >
        <Row gutter={16}>
          <Col xs={24} xl={16}>
            <Row gutter={16} align="top">
              <Col xs={24} md={12} lg={6}>
                <CountryDropdown onClear={onClearField} />
              </Col>

              <Col xs={24} md={12} lg={6}>
                <BrandDropdown onClear={onClearField} />
              </Col>

              <Col xs={24} md={24} lg={12}>
                <Form.Item name="name" label="Name">
                  <Input data-testid="name-input" allowClear onChange={onChangeInputHandler} />
                </Form.Item>
              </Col>
            </Row>

            {showsAdvancedFilters && (
              <Row gutter={16} align="bottom">
                <Col xs={24} md={12} lg={6}>
                  <CampaignTypeSelect label="Campaign type" onClear={onClearField} />
                </Col>

                <Col xs={24} md={12} lg={6}>
                  <TargetGroupSelect label="Target group" onClear={onClearField} />
                </Col>

                <Col xs={24} md={12} lg={6}>
                  <PlatformSelect onClear={onClearField} />
                </Col>

                <Col xs={24} md={12} lg={6}>
                  <BooleanSelect
                    label="Split Voucher"
                    name="hasSplitVouchers"
                    testId="has-split-vouchers"
                    onClear={onClearField}
                  />
                </Col>
              </Row>
            )}
          </Col>

          <Col xs={24} xl={8}>
            <FormActionsContainer gutter={[16, 16]}>
              <Col xs={24} md={12}>
                <SecondaryButton icon={<FilterFilled />} onClick={toggleAdvancedFilters} block>
                  Advanced Filters
                </SecondaryButton>
              </Col>
              <Col xs={24} md={12}>
                <PrimaryButton icon={<SearchOutlined />} htmlType="submit" data-testid="voucher-search-submit" block>
                  Search
                </PrimaryButton>
              </Col>
            </FormActionsContainer>
          </Col>

          {searchFiltersPresent && <ClearSearchFiltersButton onClick={clearSearchFilters} />}
        </Row>
      </Form>
    </Card>
  )
}

CampaignsSearchForm.defaultProps = {
  form: undefined,
  onSubmit: undefined,
}

export default CampaignsSearchForm
