export const CAMPAIGN_SEARCH_FORM_FILTERS = 'campaignSearchFormFilters'
export const REWARD_CAMPAIGN_SEARCH_FORM_FILTERS = 'rewardCampaignSearchFormFilters'
export const LAST_SEARCHED_CAMPAIGN_FILTERS = 'lastSearchedCampaignFilters'
export const LAST_SEARCHED_REWARD_CAMPAIGN_FILTERS = 'lastSearchedRewardCampaignFilters'
export const VOUCHERS_SEARCH_FORM_FILTERS = 'voucherSearchFormFilters'
export const LAST_SEARCHED_VOUCHER_FILTERS = 'lastSearchedVoucherFilters'
export const VOUCHER_CODE_SEARCH_FORM_FILTER = 'voucherCodeSearchFormFilter'
export const LAST_SEARCHED_VOUCHER_CODE_FILTER = 'lastSearchedVoucherCodeFilter'
export const CAMPAIGN_TEMPLATE_SEARCH_FORM_FILTERS = 'campaignTemplateSearchFormFilters'
export const LAST_SEARCHED_CAMPAIGN_TEMPLATE_FILTERS = 'lastSearchCampaignTemplateFilters'

export const CAMPAIGN_EMPTY_SEARCH_DATA = {
  brandId: undefined,
  countryId: undefined,
  name: undefined,
  campaignTypeId: undefined,
  targetGroup: undefined,
  platform: undefined,
  hasSplitVouchers: undefined,
}

export const REWARD_CAMPAIGN_EMPTY_SEARCH_DATA = {
  brandId: undefined,
  countryId: undefined,
  type: undefined,
  name: undefined,
}

export const CAMPAIGN_TEMPLATE_EMPTY_SEARCH_DATA = {
  name: undefined,
  campaignTypeId: undefined,
  targetGroup: undefined,
  platform: undefined,
  isActive: undefined,
}

export const VOUCHER_EMPTY_SEARCH_DATA = {
  brandId: undefined,
  countryId: undefined,
  code: undefined,
}

export const VOUCHER_CODE_EMPTY_SEARCH_DATA = {
  code: undefined,
}

export const REDIRECT_PATH = 'REDIRECT_PATH'
