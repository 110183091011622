import axios from 'axios'

import config from '../config'
import { getUserSession } from '../utils'

export const downloadCSV = async (campaignId: string): Promise<void> => {
  const userSession = getUserSession()
  const { data } = await axios.get(`${config.restApiBaseUrl}/campaigns/${campaignId}/vouchers/export`, {
    headers: {
      Authorization: `Bearer ${userSession.session?.token.value}`,
      'Content-Type': 'application/json',
    },
    responseType: 'blob',
  })

  const url = window.URL.createObjectURL(new Blob([data]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', `vouchers-${campaignId}-${new Date().getTime()}.csv`)
  document.body.appendChild(link)
  link.click()
}
