import React from 'react'
import { useParams } from 'react-router'
import { Descriptions } from 'antd'

import { useCampaignQuery } from '../../../apollo/generated/rewards'

const RewardCampaignsDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const labelStyle = { fontWeight: 500 }
  const { data } = useCampaignQuery({ variables: { id } })

  const campaign = data?.campaign

  return (
    <Descriptions layout="vertical" bordered>
      <Descriptions.Item label="Name" labelStyle={labelStyle}>
        {campaign?.name}
      </Descriptions.Item>
      <Descriptions.Item label="Brand" labelStyle={labelStyle}>
        {campaign?.brand?.code}
      </Descriptions.Item>
      <Descriptions.Item label="Country" labelStyle={labelStyle}>
        {campaign?.country?.code}
      </Descriptions.Item>
    </Descriptions>
  )
}

export default RewardCampaignsDetail
