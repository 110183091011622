import { Form, Select } from 'antd'
import React from 'react'

import { useBrandsQuery } from '../../apollo/generated/api'

interface Props {
  label?: string | null
  required?: boolean
  disabled?: boolean
  onClear?: (fieldName: string) => void
  index?: number
}

const PAGE_SIZE = 25

function BrandDropdown({ label, required, disabled, onClear, index }: Props): JSX.Element {
  const { data } = useBrandsQuery({ variables: { first: PAGE_SIZE }, fetchPolicy: 'cache-first' })

  const options = data?.brands?.edges || []
  const onClearHandler = () => onClear?.('brandId')

  return (
    <Form.Item
      labelCol={{ span: 24 }}
      name={index ? `brandId_${index}` : 'brandId'}
      label={label}
      rules={[{ required, message: 'Please select a brand!' }]}
    >
      <Select
        data-testid="brand-dropdown"
        style={{ width: '100%' }}
        placeholder="Select Brand"
        optionFilterProp="children"
        disabled={disabled}
        showSearch
        allowClear
        onClear={onClearHandler}
      >
        {options.map(option => (
          <Select.Option value={option?.node?.id || ''} key={option?.node?.id}>
            {option?.node?.name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}

BrandDropdown.defaultProps = {
  label: 'Brand',
  required: false,
  disabled: false,
  onClear: undefined,
  index: undefined,
}

export default BrandDropdown
