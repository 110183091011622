import React, { useEffect, useState } from 'react'
import { Form, Input, Row, Col, FormInstance, Card } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { Store } from 'antd/lib/form/interface'

import BrandDropdown from '../BrandDropdown'
import CountryDropdown from '../CountryDropdown'
import TypeDropdown from '../TypeDropdown'
import { PrimaryButton } from '../../globalStyles'
import { ClearSearchFiltersButton, FormActionsContainer } from '../../Forms'
import { deleteFromLocalStorage, getLocalStorage, persistLocalStorage } from '../../../utils/localStorageUtils'
import {
  LAST_SEARCHED_REWARD_CAMPAIGN_FILTERS,
  REWARD_CAMPAIGN_EMPTY_SEARCH_DATA,
  REWARD_CAMPAIGN_SEARCH_FORM_FILTERS,
} from '../../../constants/AppConstants'
import { CampaignFiltersInput } from '../../../apollo/generated/rewards'

interface Props {
  form?: FormInstance
  onSubmit?: (data: Store) => void
}
const RewardCampaignSearchForm = ({ onSubmit, form }: Props) => {
  const cachedSearchFilters = getLocalStorage({
    key: REWARD_CAMPAIGN_SEARCH_FORM_FILTERS,
    parse: true,
  }) as CampaignFiltersInput
  const [defaultSearchFilters, setDefaultSearchFilters] = useState(cachedSearchFilters)
  const validFiltersPresent = () => {
    const searchFilters = cachedSearchFilters || {}
    const filterValues = Object.values(searchFilters)
    const validFilterValues = filterValues.filter(val => val)

    return validFilterValues.length > 0
  }

  const [searchFiltersPresent, setSearchFiltersPresent] = useState(validFiltersPresent())
  const onClearField = (fieldName: string) => {
    if (form && onSubmit) {
      const formInputs = {
        ...form.getFieldsValue(),
      }
      formInputs[fieldName] = undefined
      form.setFieldsValue(formInputs)
      onSubmit(form.getFieldsValue())
    }
  }

  const updateFilterState = () => {
    setSearchFiltersPresent(validFiltersPresent())
  }

  const clearSearchFilters = () => {
    deleteFromLocalStorage([REWARD_CAMPAIGN_SEARCH_FORM_FILTERS, LAST_SEARCHED_REWARD_CAMPAIGN_FILTERS])
    form?.setFieldsValue(REWARD_CAMPAIGN_EMPTY_SEARCH_DATA)
    setDefaultSearchFilters({})
    if (onSubmit) onSubmit({})
  }

  const handleValuesChange = (_changedValues: object, filters: Store) => {
    persistLocalStorage(REWARD_CAMPAIGN_SEARCH_FORM_FILTERS, filters)
    setDefaultSearchFilters(filters)
    updateFilterState()
  }

  useEffect(() => {
    updateFilterState()
  }, [defaultSearchFilters])

  /**
   *
   * Input Component doesn't provide us onClear function, So we have to capture onChange separately
   */
  const onChangeInputHandler = (e: React.ChangeEvent<HTMLInputElement> | undefined) => {
    if (e?.target.value === '') {
      onClearField(e.target.name)
    }
  }

  return (
    <Card style={{ marginBottom: 25 }}>
      <Form
        form={form}
        onFinish={onSubmit}
        layout="vertical"
        onValuesChange={handleValuesChange}
        initialValues={defaultSearchFilters}
      >
        <Row gutter={16}>
          <Col xs={24} xl={20}>
            <Row gutter={16} align="top">
              <Col xs={24} md={12} lg={6}>
                <CountryDropdown onClear={onClearField} />
              </Col>

              <Col xs={24} md={12} lg={6}>
                <BrandDropdown onClear={onClearField} />
              </Col>

              <Col xs={24} md={12} lg={6}>
                <TypeDropdown onClear={onClearField} />
              </Col>

              <Col xs={24} md={12} lg={6}>
                <Form.Item name="name" label="Name">
                  <Input data-testid="name-input" allowClear onChange={onChangeInputHandler} />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col xs={24} xl={4}>
            <FormActionsContainer gutter={[16, 16]}>
              <Col xs={24} md={12} xl={24}>
                <PrimaryButton icon={<SearchOutlined />} htmlType="submit" data-testid="voucher-search-submit" block>
                  Search
                </PrimaryButton>
              </Col>
            </FormActionsContainer>
          </Col>

          {searchFiltersPresent && <ClearSearchFiltersButton onClick={clearSearchFilters} />}
        </Row>
      </Form>
    </Card>
  )
}

export default RewardCampaignSearchForm
