import { PageHeader } from '@ant-design/pro-components'
import { Divider, Spin, Table, Tabs, Typography } from 'antd'
import React from 'react'
import { generatePath, useHistory, useParams } from 'react-router'
import { Link } from 'react-router-dom'

import NotFound from '../../../packages/UnauthorizedComponentError'
import { CampaignTemplate, useCampaignTemplateQuery, VoucherSetting } from '../../../apollo/generated/api'
import Breadcrumbs from '../../Layout/breadcrumbs'
import { PrimaryButton, SecondaryButton } from '../../globalStyles'
import { Routes } from '../../../constants/RoutesConstants'
import { ContentMargin } from '../../Layout/styles'
import VoucherSettings from '../../VoucherSettings'

import CampaignTemplateSummary from './CampaigTemplateSummary'

const { Text } = Typography
const { TabPane } = Tabs

const CampaignTemplateDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()

  const {
    data: campaignTemplateData,
    loading: campaignTemplateLoading,
    error: campaignTemplateError,
  } = useCampaignTemplateQuery({
    variables: { id },
    fetchPolicy: 'cache-first',
  })

  if (campaignTemplateLoading) {
    return (
      <div className="campaign-template-details-loading" style={{ textAlign: 'center' }}>
        <Spin size="large" tip="Loading campaign details..." />
      </div>
    )
  }

  if (campaignTemplateError || (campaignTemplateData && campaignTemplateData.campaignTemplate === null)) {
    return <NotFound message="Campaign not found" />
  }

  const campaignTemplate = campaignTemplateData?.campaignTemplate
  const voucher = campaignTemplate?.voucher

  const voucherBatch =
    campaignTemplate?.voucherBatch &&
    [campaignTemplate?.voucherBatch].map(vb => ({
      quantity: vb?.quantity,
      totalRedemptionLimit: vb?.totalRedemptionLimit,
      redemptionLimitPerCustomer: vb?.redemptionLimitPerCustomer,
    }))

  const pageHeaderButtons = campaignTemplateData?.campaignTemplate?.isActive
    ? [
        // eslint-disable-next-line react/jsx-indent
        <Link key="build-template" to={generatePath(Routes.CAMPAIGNS_GENERATOR, { id: campaignTemplate?.id })}>
          <SecondaryButton>Build Campaigns Form</SecondaryButton>
        </Link>,
        // eslint-disable-next-line react/jsx-indent
        <Link key="edit-template" to={generatePath(Routes.CAMPAIGN_TEMPLATE_EDIT, { id: campaignTemplate?.id })}>
          <PrimaryButton>Edit Campaign Template</PrimaryButton>
        </Link>,
      ]
    : []

  return (
    <>
      <PageHeader title={campaignTemplate?.name} onBack={history.goBack} extra={pageHeaderButtons}>
        <Breadcrumbs param={{ name: 'id', value: id }} />
      </PageHeader>

      <div style={ContentMargin} data-testid="campaign-details">
        <CampaignTemplateSummary campaignTemplate={campaignTemplate as CampaignTemplate} />

        <Divider />
        <Tabs defaultActiveKey="vouchers">
          {voucher && (
            <TabPane tab={<span>Vouchers</span>} key="vouchers">
              <Table
                rowKey="id"
                dataSource={[voucher]}
                pagination={false}
                columns={[
                  {
                    title: 'Voucher Code',
                    dataIndex: 'code',
                    render: (value: string) => (
                      <Text code copyable>
                        {value}
                      </Text>
                    ),
                  },
                  {
                    title: 'Redemption limit per customer',
                    dataIndex: 'redemptionLimitPerCustomer',
                  },
                  {
                    title: 'Total Redemption limit',
                    dataIndex: 'totalRedemptionLimit',
                  },
                ]}
              />
            </TabPane>
          )}
          {voucherBatch && (
            <TabPane tab={<span>Voucher Batch</span>} key="voucherBatch">
              <Table
                rowKey="id"
                dataSource={voucherBatch}
                pagination={false}
                columns={[
                  {
                    title: 'Quantity',
                    dataIndex: 'quantity',
                  },
                  {
                    title: 'Redemption limit per customer',
                    dataIndex: 'redemptionLimitPerCustomer',
                  },
                  {
                    title: 'Total Redemption limit',
                    dataIndex: 'totalRedemptionLimit',
                  },
                ]}
              />
            </TabPane>
          )}
          <TabPane tab={<span>Voucher Settings</span>} key="voucher-settings">
            <VoucherSettings voucherSettings={campaignTemplate?.voucherSettings as VoucherSetting[]} />
          </TabPane>
        </Tabs>
      </div>
    </>
  )
}

export default CampaignTemplateDetails
