import { DownloadOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import React from 'react'

import { SecondaryButton } from '../globalStyles'

type Props = {
  loading: boolean
  label: string
  onClick: () => void
}

const VoucherExportButton: React.FC<Props> = ({ loading, label, onClick }) => {
  return (
    <div>
      {loading && <Spin size="default" />}
      <SecondaryButton
        style={{ marginRight: 10, marginLeft: 20 }}
        icon={<DownloadOutlined style={{ fontSize: 18 }} />}
        onClick={onClick}
      >
        {label}
      </SecondaryButton>
    </div>
  )
}

export default VoucherExportButton
