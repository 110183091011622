import { Form, Select } from 'antd'
import React, { useEffect } from 'react'

import { useBrandsQuery } from '../../../apollo/generated/rewards'

interface Props {
  label?: string | null
  required?: boolean
  disabled?: boolean
  onClear?: (fieldName: string) => void
}

const BrandDropdown: React.FC<Props> = ({ label, required, disabled, onClear }: Props) => {
  const [isMounted, setIsMounted] = React.useState(false)
  const { data } = useBrandsQuery({ fetchPolicy: 'cache-first' })

  const options = data?.brands || []
  const onClearHandler = () => onClear?.('brandId')

  useEffect(() => {
    setIsMounted(true)

    return () => {
      setIsMounted(false)
    }
  }, [])

  return (
    <Form.Item
      labelCol={{ span: 24 }}
      name="brandId"
      label={label}
      rules={[{ required, message: 'Please select a brand!' }]}
    >
      <Select
        data-testid="brand-dropdown"
        style={{ width: '100%' }}
        placeholder="Select Brand"
        optionFilterProp="children"
        disabled={disabled}
        showSearch
        allowClear
        onClear={onClearHandler}
      >
        {isMounted &&
          options.map(option => (
            <Select.Option value={option?.id || ''} key={option?.id}>
              {option?.name}
            </Select.Option>
          ))}
      </Select>
    </Form.Item>
  )
}

BrandDropdown.defaultProps = {
  label: 'Brand',
  required: false,
  disabled: false,
  onClear: undefined,
}

export default BrandDropdown
