import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * The `DateTime` scalar type represents a date and time in the UTC
   * timezone. The DateTime appears in a JSON response as an ISO8601 formatted
   * string, including UTC timezone ("Z"). The parsed date and time string will
   * be converted to UTC if there is an offset.
   */
  DateTime: string;
};

export type Brand = {
  __typename?: 'Brand';
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type Campaign = {
  __typename?: 'Campaign';
  brand?: Maybe<Brand>;
  country?: Maybe<Country>;
  description?: Maybe<Scalars['String']>;
  eligibilityConditions?: Maybe<Array<Maybe<EligibilityCondition>>>;
  endsAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  internalName?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  leadingMetric?: Maybe<LeadingMetric>;
  name?: Maybe<Scalars['String']>;
  reward?: Maybe<Reward>;
  startsAt: Scalars['DateTime'];
  type?: Maybe<CampaignType>;
};

export type CampaignConnection = {
  __typename?: 'CampaignConnection';
  edges?: Maybe<Array<Maybe<CampaignEdge>>>;
  pageInfo: PageInfo;
};

export type CampaignEdge = {
  __typename?: 'CampaignEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Campaign>;
};

/** These are the supported filters for searching campaigns. */
export type CampaignFiltersInput = {
  /** The brand ID. */
  brand?: InputMaybe<Scalars['ID']>;
  /** The country ID. */
  country?: InputMaybe<Scalars['ID']>;
  /** A case-insensitive campaign name */
  name?: InputMaybe<Scalars['String']>;
  /** The type of the campaign */
  type?: InputMaybe<CampaignType>;
};

/** Input type for campaigns */
export type CampaignInput = {
  /** The campaign's brand ID */
  brandId: Scalars['ID'];
  /** The campaign's country ID */
  countryId: Scalars['ID'];
  /** The campaign's description */
  description?: InputMaybe<Scalars['String']>;
  /** The campaign's eligibility conditions */
  eligibilityConditions?: InputMaybe<Array<InputMaybe<EligibilityConditionsInput>>>;
  /** The campaign's end date */
  endsAt?: InputMaybe<Scalars['DateTime']>;
  /** The campaign's internal name */
  internalName?: InputMaybe<Scalars['String']>;
  /** The campaign's leading metric */
  leadingMetric?: InputMaybe<LeadingMetric>;
  /** The campaign's name */
  name: Scalars['String'];
  reward?: InputMaybe<RewardInput>;
  /**
   * The campaign's settings on the offered rewards.
   * @deprecated Use `reward` instead
   */
  settings?: InputMaybe<SettingsInput>;
  /** The campaign's start date */
  startsAt: Scalars['DateTime'];
  /** The campaign's type */
  type?: InputMaybe<CampaignType>;
};

export type CampaignPayload = {
  __typename?: 'CampaignPayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<Campaign>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean'];
};

/** The supported sorting options for campaigns. */
export type CampaignSorterInput = {
  /** The campaign ID. */
  id?: InputMaybe<Sort>;
  /** The name of the campaign. */
  name?: InputMaybe<Sort>;
};

/** Possible campaign types for rewards */
export enum CampaignType {
  ProgressBased = 'PROGRESS_BASED',
  ReferralReward = 'REFERRAL_REWARD'
}

export type Country = {
  __typename?: 'Country';
  code?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
};

export type EligibilityCondition = {
  __typename?: 'EligibilityCondition';
  id: Scalars['ID'];
  targetAttributeName: TargetAttribute;
  targetAttributeValue: Scalars['String'];
};

export type EligibilityConditionsInput = {
  /** The eligibility condition ID, if it exists */
  id?: InputMaybe<Scalars['ID']>;
  targetAttributeName: TargetAttribute;
  targetAttributeValue: Scalars['String'];
};

export type Incentive = {
  __typename?: 'Incentive';
  campaign?: Maybe<Campaign>;
  claimedAt?: Maybe<Scalars['DateTime']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
};

/** Input type for incentives */
export type IncentiveInput = {
  /** ID for the campaign */
  campaignId: Scalars['ID'];
  /** Customer ID */
  customerId: Scalars['ID'];
  /** Date when the incentive expires */
  expiresAt: Scalars['DateTime'];
};

export type IncentivePayload = {
  __typename?: 'IncentivePayload';
  /** A list of failed validations. May be blank or null if mutation succeeded. */
  messages?: Maybe<Array<Maybe<ValidationMessage>>>;
  /** The object created/updated/deleted by the mutation. May be null if mutation failed. */
  result?: Maybe<Incentive>;
  /** Indicates if the mutation completed successfully or not. */
  successful: Scalars['Boolean'];
};

/** Leading metric for progress-based campaigns */
export enum LeadingMetric {
  OrderOrdinal = 'ORDER_ORDINAL'
}

export type MarketItemReward = {
  __typename?: 'MarketItemReward';
  /** SKUs */
  sku?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Discount value */
  value?: Maybe<Scalars['String']>;
  /** Discount limit */
  valueLimit?: Maybe<Scalars['String']>;
  /** Type of discount, percentage of fixed */
  valueType?: Maybe<Scalars['String']>;
};

export type MarketItemRewardInput = {
  /** SKUs */
  sku?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** The discount value (its meaning depends on the `value_type` field) */
  value?: InputMaybe<Scalars['Float']>;
  /** The maximum discount value */
  valueLimit?: InputMaybe<Scalars['Float']>;
  /** The discount type (e.g. `PERCENTAGE` or `FIXED`) */
  valueType?: InputMaybe<Scalars['String']>;
};

export type OrderDiscountReward = {
  __typename?: 'OrderDiscountReward';
  /** Discount value */
  value?: Maybe<Scalars['String']>;
  /** Discount limit */
  valueLimit?: Maybe<Scalars['String']>;
  /** Type of discount, percentage of fixed */
  valueType?: Maybe<Scalars['String']>;
};

export type OrderDiscountRewardInput = {
  /** The discount value (its meaning depends on the `value_type` field) */
  value?: InputMaybe<Scalars['Float']>;
  /** The maximum discount value */
  valueLimit?: InputMaybe<Scalars['Float']>;
  /** The discount type (e.g. `PERCENTAGE` or `FIXED`) */
  valueType?: InputMaybe<Scalars['String']>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

export type Reward = MarketItemReward | OrderDiscountReward;

export type RewardInput = {
  marketItem?: InputMaybe<MarketItemRewardInput>;
  orderDiscount?: InputMaybe<OrderDiscountRewardInput>;
};

export type RootMutationType = {
  __typename?: 'RootMutationType';
  /** Creates a campaign */
  createCampaign?: Maybe<CampaignPayload>;
  /** Creates an incentive */
  createIncentive?: Maybe<IncentivePayload>;
  /** Updates a campaign */
  updateCampaign?: Maybe<CampaignPayload>;
};


export type RootMutationTypeCreateCampaignArgs = {
  campaign: CampaignInput;
};


export type RootMutationTypeCreateIncentiveArgs = {
  incentive: IncentiveInput;
};


export type RootMutationTypeUpdateCampaignArgs = {
  campaign: CampaignInput;
  id: Scalars['ID'];
};

export type RootQueryType = {
  __typename?: 'RootQueryType';
  /** List all brands */
  brands?: Maybe<Array<Maybe<Brand>>>;
  /** Get campaign details */
  campaign?: Maybe<Campaign>;
  /** Lists campaigns */
  campaigns?: Maybe<CampaignConnection>;
  /** List all countries */
  countries?: Maybe<Array<Maybe<Country>>>;
};


export type RootQueryTypeCampaignArgs = {
  id: Scalars['ID'];
};


export type RootQueryTypeCampaignsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<CampaignFiltersInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sorter?: InputMaybe<CampaignSorterInput>;
};

export type SettingsInput = {
  /** SKUs */
  sku?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** The discount value (its meaning depends on the `value_type` field) */
  value?: InputMaybe<Scalars['Float']>;
  /** The maximum discount value */
  valueLimit?: InputMaybe<Scalars['Float']>;
  /** The discount type (e.g. `PERCENTAGE` or `FIXED`) */
  valueType?: InputMaybe<Scalars['String']>;
};

/** The possible sort order for a list of campaigns */
export enum Sort {
  /** Ascending */
  Asc = 'ASC',
  /** Descending */
  Desc = 'DESC'
}

/** Target attributes applicable to eligibility conditions */
export enum TargetAttribute {
  Experiment = 'EXPERIMENT',
  OrderOrdinal = 'ORDER_ORDINAL',
  OrderPlan = 'ORDER_PLAN'
}

/**
 * Validation messages are returned when mutation input does not meet the requirements.
 *   While client-side validation is highly recommended to provide the best User Experience,
 *   All inputs will always be validated server-side.
 *
 *   Some examples of validations are:
 *
 *   * Username must be at least 10 characters
 *   * Email field does not contain an email address
 *   * Birth Date is required
 *
 *   While GraphQL has support for required values, mutation data fields are always
 *   set to optional in our API. This allows 'required field' messages
 *   to be returned in the same manner as other validations. The only exceptions
 *   are id fields, which may be required to perform updates or deletes.
 */
export type ValidationMessage = {
  __typename?: 'ValidationMessage';
  /** A unique error code for the type of validation used. */
  code: Scalars['String'];
  /**
   * The input field that the error applies to. The field can be used to
   * identify which field the error message should be displayed next to in the
   * presentation layer.
   *
   * If there are multiple errors to display for a field, multiple validation
   * messages will be in the result.
   *
   * This field may be null in cases where an error cannot be applied to a specific field.
   */
  field?: Maybe<Scalars['String']>;
  /**
   * A friendly error message, appropriate for display to the end user.
   *
   * The message is interpolated to include the appropriate variables.
   *
   * Example: `Username must be at least 10 characters`
   *
   * This message may change without notice, so we do not recommend you match against the text.
   * Instead, use the *code* field for matching.
   */
  message?: Maybe<Scalars['String']>;
  /** A list of substitutions to be applied to a validation message template */
  options?: Maybe<Array<Maybe<ValidationOption>>>;
  /**
   * A template used to generate the error message, with placeholders for option substiution.
   *
   * Example: `Username must be at least {count} characters`
   *
   * This message may change without notice, so we do not recommend you match against the text.
   * Instead, use the *code* field for matching.
   */
  template?: Maybe<Scalars['String']>;
};

export type ValidationOption = {
  __typename?: 'ValidationOption';
  /** The name of a variable to be subsituted in a validation message template */
  key: Scalars['String'];
  /** The value of a variable to be substituted in a validation message template */
  value: Scalars['String'];
};

export type CreateCampaignMutationVariables = Exact<{
  campaign: CampaignInput;
}>;


export type CreateCampaignMutation = { __typename?: 'RootMutationType', createCampaign?: { __typename?: 'CampaignPayload', successful: boolean, result?: { __typename?: 'Campaign', id?: string | null, name?: string | null } | null, messages?: Array<{ __typename?: 'ValidationMessage', message?: string | null } | null> | null } | null };

export type UpdateCampaignMutationVariables = Exact<{
  id: Scalars['ID'];
  campaign: CampaignInput;
}>;


export type UpdateCampaignMutation = { __typename?: 'RootMutationType', updateCampaign?: { __typename?: 'CampaignPayload', successful: boolean, result?: { __typename?: 'Campaign', id?: string | null, name?: string | null } | null, messages?: Array<{ __typename?: 'ValidationMessage', message?: string | null } | null> | null } | null };

export type BrandsQueryVariables = Exact<{ [key: string]: never; }>;


export type BrandsQuery = { __typename?: 'RootQueryType', brands?: Array<{ __typename?: 'Brand', id?: string | null, name?: string | null, code?: string | null } | null> | null };

export type CampaignQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CampaignQuery = { __typename?: 'RootQueryType', campaign?: { __typename?: 'Campaign', id?: string | null, name?: string | null, internalName?: string | null, description?: string | null, type?: CampaignType | null, leadingMetric?: LeadingMetric | null, startsAt: string, endsAt?: string | null, eligibilityConditions?: Array<{ __typename?: 'EligibilityCondition', id: string, targetAttributeName: TargetAttribute, targetAttributeValue: string } | null> | null, reward?: { __typename: 'MarketItemReward', sku?: Array<string | null> | null, value?: string | null, valueLimit?: string | null, valueType?: string | null } | { __typename: 'OrderDiscountReward', value?: string | null, valueLimit?: string | null, valueType?: string | null } | null, country?: { __typename?: 'Country', id?: string | null, code?: string | null, name?: string | null, timezone?: string | null } | null, brand?: { __typename?: 'Brand', id?: string | null, code?: string | null, name?: string | null } | null } | null };

export type CountriesQueryVariables = Exact<{ [key: string]: never; }>;


export type CountriesQuery = { __typename?: 'RootQueryType', countries?: Array<{ __typename?: 'Country', id?: string | null, name?: string | null, code?: string | null, timezone?: string | null } | null> | null };

export type CampaignsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<CampaignFiltersInput>;
  sorter?: InputMaybe<CampaignSorterInput>;
}>;


export type CampaignsQuery = { __typename?: 'RootQueryType', campaigns?: { __typename?: 'CampaignConnection', edges?: Array<{ __typename?: 'CampaignEdge', node?: { __typename?: 'Campaign', id?: string | null, name?: string | null, isActive?: boolean | null, type?: CampaignType | null, country?: { __typename?: 'Country', code?: string | null, name?: string | null } | null, brand?: { __typename?: 'Brand', code?: string | null, name?: string | null } | null } | null } | null> | null, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean } } | null };


export const CreateCampaignDocument = gql`
    mutation createCampaign_Rewards($campaign: CampaignInput!) {
  createCampaign(campaign: $campaign) {
    successful
    result {
      id
      name
    }
    messages {
      message
    }
  }
}
    `;
export type CreateCampaignMutationFn = Apollo.MutationFunction<CreateCampaignMutation, CreateCampaignMutationVariables>;
export function useCreateCampaignMutation(baseOptions?: Apollo.MutationHookOptions<CreateCampaignMutation, CreateCampaignMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCampaignMutation, CreateCampaignMutationVariables>(CreateCampaignDocument, options);
      }
export type CreateCampaignMutationHookResult = ReturnType<typeof useCreateCampaignMutation>;
export type CreateCampaignMutationResult = Apollo.MutationResult<CreateCampaignMutation>;
export type CreateCampaignMutationOptions = Apollo.BaseMutationOptions<CreateCampaignMutation, CreateCampaignMutationVariables>;
export const UpdateCampaignDocument = gql`
    mutation updateCampaign_Rewards($id: ID!, $campaign: CampaignInput!) {
  updateCampaign(id: $id, campaign: $campaign) {
    successful
    result {
      id
      name
    }
    messages {
      message
    }
  }
}
    `;
export type UpdateCampaignMutationFn = Apollo.MutationFunction<UpdateCampaignMutation, UpdateCampaignMutationVariables>;
export function useUpdateCampaignMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCampaignMutation, UpdateCampaignMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCampaignMutation, UpdateCampaignMutationVariables>(UpdateCampaignDocument, options);
      }
export type UpdateCampaignMutationHookResult = ReturnType<typeof useUpdateCampaignMutation>;
export type UpdateCampaignMutationResult = Apollo.MutationResult<UpdateCampaignMutation>;
export type UpdateCampaignMutationOptions = Apollo.BaseMutationOptions<UpdateCampaignMutation, UpdateCampaignMutationVariables>;
export const BrandsDocument = gql`
    query brands_Rewards {
  brands {
    id
    name
    code
  }
}
    `;
export function useBrandsQuery(baseOptions?: Apollo.QueryHookOptions<BrandsQuery, BrandsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BrandsQuery, BrandsQueryVariables>(BrandsDocument, options);
      }
export function useBrandsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BrandsQuery, BrandsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BrandsQuery, BrandsQueryVariables>(BrandsDocument, options);
        }
export type BrandsQueryHookResult = ReturnType<typeof useBrandsQuery>;
export type BrandsLazyQueryHookResult = ReturnType<typeof useBrandsLazyQuery>;
export type BrandsQueryResult = Apollo.QueryResult<BrandsQuery, BrandsQueryVariables>;
export const CampaignDocument = gql`
    query campaign_Rewards($id: ID!) {
  campaign(id: $id) {
    id
    name
    internalName
    description
    type
    leadingMetric
    startsAt
    endsAt
    eligibilityConditions {
      id
      targetAttributeName
      targetAttributeValue
    }
    reward {
      __typename
      ... on MarketItemReward {
        sku
        value
        valueLimit
        valueType
      }
      ... on OrderDiscountReward {
        value
        valueLimit
        valueType
      }
    }
    country {
      id
      code
      name
      timezone
    }
    brand {
      id
      code
      name
    }
  }
}
    `;
export function useCampaignQuery(baseOptions: Apollo.QueryHookOptions<CampaignQuery, CampaignQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CampaignQuery, CampaignQueryVariables>(CampaignDocument, options);
      }
export function useCampaignLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CampaignQuery, CampaignQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CampaignQuery, CampaignQueryVariables>(CampaignDocument, options);
        }
export type CampaignQueryHookResult = ReturnType<typeof useCampaignQuery>;
export type CampaignLazyQueryHookResult = ReturnType<typeof useCampaignLazyQuery>;
export type CampaignQueryResult = Apollo.QueryResult<CampaignQuery, CampaignQueryVariables>;
export const CountriesDocument = gql`
    query countries_Rewards {
  countries {
    id
    name
    code
    timezone
  }
}
    `;
export function useCountriesQuery(baseOptions?: Apollo.QueryHookOptions<CountriesQuery, CountriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CountriesQuery, CountriesQueryVariables>(CountriesDocument, options);
      }
export function useCountriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountriesQuery, CountriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CountriesQuery, CountriesQueryVariables>(CountriesDocument, options);
        }
export type CountriesQueryHookResult = ReturnType<typeof useCountriesQuery>;
export type CountriesLazyQueryHookResult = ReturnType<typeof useCountriesLazyQuery>;
export type CountriesQueryResult = Apollo.QueryResult<CountriesQuery, CountriesQueryVariables>;
export const CampaignsDocument = gql`
    query campaigns_Rewards($first: Int = 25, $after: String, $filters: CampaignFiltersInput, $sorter: CampaignSorterInput) {
  campaigns(first: $first, after: $after, filters: $filters, sorter: $sorter) {
    edges {
      node {
        id
        name
        isActive
        type
        country {
          code
          name
        }
        brand {
          code
          name
        }
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    `;
export function useCampaignsQuery(baseOptions?: Apollo.QueryHookOptions<CampaignsQuery, CampaignsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CampaignsQuery, CampaignsQueryVariables>(CampaignsDocument, options);
      }
export function useCampaignsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CampaignsQuery, CampaignsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CampaignsQuery, CampaignsQueryVariables>(CampaignsDocument, options);
        }
export type CampaignsQueryHookResult = ReturnType<typeof useCampaignsQuery>;
export type CampaignsLazyQueryHookResult = ReturnType<typeof useCampaignsLazyQuery>;
export type CampaignsQueryResult = Apollo.QueryResult<CampaignsQuery, CampaignsQueryVariables>;