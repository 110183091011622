import React, { useCallback, useRef, useState } from 'react'
import { PageHeader } from '@ant-design/pro-components'
import { Button, Space, Table, Tooltip, Form } from 'antd'
import { Link, generatePath } from 'react-router-dom'
import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons'
import { Store } from 'antd/lib/form/interface'
import { FilterValue, SortOrder, SorterResult, TablePaginationConfig } from 'antd/es/table/interface'

import { Routes } from '../../../constants/RoutesConstants'
import { ContentMargin } from '../../Layout/styles'
import { Container } from '../../Campaigns/styles'
import { CampaignEdge, CampaignSorterInput, Sort, useCampaignsQuery } from '../../../apollo/generated/rewards'
import useRelayPagination from '../../../hooks/useRelayPagination'
import Pagination from '../../ManageUsers/Pagination'
import GeneralError from '../../../packages/GeneralError'
import RewardCampaignSearchForm from '../RewardCampaignSearchForm'
import { getLocalStorage, persistLocalStorage } from '../../../utils/localStorageUtils'
import { LAST_SEARCHED_REWARD_CAMPAIGN_FILTERS } from '../../../constants/AppConstants'
import { parseEmpty } from '../../../utils/textUtils'
import { CAMPAIGN_TYPES } from '../TypeDropdown/TypeDropdown'
import { CampaignFiltersInput } from '../../../apollo/generated/api'

type MapperKey = 'ascend' | 'descend' | Sort

const sorterMapper: Record<MapperKey, string> = {
  ascend: Sort.Asc,
  descend: Sort.Desc,
  [Sort.Asc]: 'ascend',
  [Sort.Desc]: 'descend',
}

const RewardCampaignsList: React.FC = () => {
  const { currentCursor, hasPreviousPage, handleNextPage, handlePreviousPage, resetPagination } = useRelayPagination()
  const [form] = Form.useForm()
  const [sort, setSort] = useState<CampaignSorterInput>({})
  const cachedSearchFilters = getLocalStorage({
    key: LAST_SEARCHED_REWARD_CAMPAIGN_FILTERS,
    parse: true,
  }) as CampaignFiltersInput
  const parseSearchFilters = (filters: Store) => ({
    name: parseEmpty(filters.name),
    brand: filters.brandId,
    country: filters.countryId,
    type: filters.type,
  })
  const currentFilters = useRef(cachedSearchFilters && parseSearchFilters(cachedSearchFilters))
  const { data, loading, error } = useCampaignsQuery({
    variables: { first: 20, after: currentCursor.current || null, filters: currentFilters.current || {}, sorter: sort },
    fetchPolicy: 'no-cache',
  })
  const entries = data?.campaigns?.edges || []
  const pageInfo = data?.campaigns?.pageInfo

  const onNextPage = () => {
    if (pageInfo?.hasNextPage) {
      handleNextPage(pageInfo.endCursor)
    }
  }
  const handleFilter = (filters: Store) => {
    persistLocalStorage(LAST_SEARCHED_REWARD_CAMPAIGN_FILTERS, filters)

    currentFilters.current = parseSearchFilters(filters)
    resetPagination()
  }

  const handleSort = useCallback(
    (
      _: TablePaginationConfig,
      __: Record<string, FilterValue | null>,
      sorter: SorterResult<CampaignEdge> | SorterResult<CampaignEdge>[],
    ) => {
      const sorters = Array.isArray(sorter) ? sorter : [sorter]
      const sortCriteria = sorters.reduce(
        (prev, current) => ({
          ...prev,
          [current.columnKey as string]: sorterMapper[(current.order as MapperKey) || ''],
        }),
        {},
      )

      setSort(sortCriteria as CampaignSorterInput)
      resetPagination()
    },
    [resetPagination],
  )

  if (error) return <GeneralError message={error.message} />

  return (
    <>
      <PageHeader
        title="Reward Campaigns"
        extra={[
          <Button className="new-campaign-btn" type="primary" key="newCampaignButton" style={{ float: 'right' }}>
            <Link to={Routes.REWARD_CAMPAIGNS_NEW}>New campaign</Link>
          </Button>,
        ]}
      />

      <Container style={ContentMargin}>
        <RewardCampaignSearchForm form={form} onSubmit={handleFilter} />
        <Table
          dataSource={entries as CampaignEdge[]}
          loading={loading}
          rowKey={campaign => campaign?.node?.id as string}
          style={{ minHeight: 500 }}
          pagination={false}
          onChange={handleSort}
          columns={[
            {
              title: '#',
              dataIndex: ['node', 'id'],
              key: 'id',
              sorter: true,
              sortOrder: (sorterMapper[sort.id || ('' as MapperKey)] || null) as SortOrder,
              render: (_, { node }) =>
                node?.id && <Link to={generatePath(Routes.REWARD_CAMPAIGNS_DETAIL, { id: node.id })}>{node.id}</Link>,
            },
            {
              title: 'Name',
              dataIndex: ['node', 'name'],
              key: 'name',
              sorter: true,
              sortOrder: (sorterMapper[sort.name || ('' as MapperKey)] || null) as SortOrder,
            },
            {
              title: 'Brand',
              dataIndex: ['node', 'brand', 'name'],
              key: 'brand',
              render: (_, { node }: CampaignEdge) => node?.brand?.name,
            },
            {
              title: 'Country',
              dataIndex: ['node', 'country', 'name'],
              key: 'country',
              render: (_, { node }: CampaignEdge) => node?.country?.name,
            },
            {
              title: 'Type',
              dataIndex: ['node', 'type'],
              key: 'type',
              render: (_, { node }: CampaignEdge) => node?.type && CAMPAIGN_TYPES[node.type],
            },
            {
              title: 'Active',
              dataIndex: ['node', 'isActive'],
              key: 'isActive',
              render: (isActive: boolean) =>
                isActive ? (
                  <CheckCircleTwoTone twoToneColor="#52c41a" />
                ) : (
                  <CloseCircleTwoTone twoToneColor="#ff383c" />
                ),
            },
            {
              title: 'Actions',
              key: 'actions',
              render: (_, { node }: CampaignEdge) => (
                <Space size="middle">
                  {node?.id && (
                    <Link to={generatePath(Routes.REWARD_CAMPAIGNS_EDIT, { id: node.id })}>
                      <Tooltip title="Edit">Edit</Tooltip>
                    </Link>
                  )}
                </Space>
              ),
            },
          ]}
        />
        <Pagination
          hasNextPage={pageInfo?.hasNextPage}
          hasPreviousPage={hasPreviousPage}
          onNextPage={onNextPage}
          onPreviousPage={handlePreviousPage}
        />
      </Container>
    </>
  )
}

export default RewardCampaignsList
