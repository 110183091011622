import React from 'react'
import { PageHeader } from '@ant-design/pro-components'
import { Button } from 'antd'
import { Link } from 'react-router-dom'

import { Routes } from '../../constants/RoutesConstants'
import { ContentMargin } from '../Layout/styles'

import CampaignsSearch from './CampaignsSearch'
import { Container } from './styles'

function Campaigns(): JSX.Element {
  return (
    <>
      <PageHeader
        title="Campaigns"
        extra={[
          <Button className="new-campaign-btn" type="primary" key="newCampaignButton" style={{ float: 'right' }}>
            <Link to={Routes.CAMPAIGNS_CREATE}>New campaign</Link>
          </Button>,
        ]}
      />

      <Container style={ContentMargin}>
        <CampaignsSearch />
      </Container>
    </>
  )
}

export default Campaigns
