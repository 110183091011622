import React, { useState, useEffect } from 'react'
import { generatePath, useHistory, useParams } from 'react-router'
import { Modal } from 'antd'

import {
  useCampaignTemplateQuery,
  useUpdateCampaignTemplateMutation,
  VoucherBatchTemplateInput,
  VoucherInput,
} from '../../apollo/generated/api'
import { handleMutationResult } from '../../apollo'
import NotFoundError from '../../packages/UnauthorizedComponentError'
import {
  buildCampaignTemplateDefaultValues,
  buildUpdateCampaignTemplatePayload,
  CampaignTemplateFormData,
} from '../../utils/campaignTemplateUtils'
import { ErrorPayload } from '../Error/types'
import { Routes } from '../../constants/RoutesConstants'
import CampaignsForm from '../Campaigns/CampaignsForm'
import VoucherTemplate, { DefaultValueType } from '../Vouchers/VoucherTemplate'

interface Props {
  shouldBlockNavigation: (value: boolean) => void
}

const CampaignTemplateUpdate: React.FC<Props> = ({ shouldBlockNavigation }) => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const [fieldErrors, setFieldErrors] = useState<ErrorPayload>()
  const [showModal, setShowModal] = useState<boolean>(false)
  const [defaultValues, setDefaultValues] = useState<Partial<CampaignTemplateFormData>>()
  const [campaignTemplateData, setCampaignTemplateData] = useState<CampaignTemplateFormData>()
  const [multipleVouchers, setMultipleVouchers] = useState(false)
  const [voucherTemplateDefaultValues, setVoucherTemplateDefaultValues] = useState<DefaultValueType>()
  const { data: campaignTemplateQueryData, error: campaignTemplateQueryError } = useCampaignTemplateQuery({
    variables: { id },
    skip: !id,
  })
  const [updateCampaignTemplate, { loading: updating, data: updateCampaignTemplateData }] =
    useUpdateCampaignTemplateMutation()

  const handleSubmit = (values: CampaignTemplateFormData) => {
    shouldBlockNavigation?.(false)
    setCampaignTemplateData(values)
    setShowModal(true)
  }

  useEffect(() => {
    setDefaultValues(buildCampaignTemplateDefaultValues(campaignTemplateQueryData))
    setMultipleVouchers(!!campaignTemplateQueryData?.campaignTemplate?.voucherBatch)
  }, [campaignTemplateQueryData])

  if (campaignTemplateQueryError) {
    return <NotFoundError />
  }

  const handleValueChange = (e: any) => {
    shouldBlockNavigation?.(true)

    if (fieldErrors) {
      delete fieldErrors[Object.keys(e)[0]]
    }
  }

  const update = async (values: CampaignTemplateFormData) => {
    shouldBlockNavigation?.(false)

    const mutation = updateCampaignTemplate({ variables: { input: buildUpdateCampaignTemplatePayload(id, values) } })
    const { data } = await handleMutationResult(mutation, 'updateCampaignTemplate', {
      notifications: {
        success: {
          title: `Template Updated`,
        },
      },
    })
    if (data?.updateCampaignTemplate?.successful) {
      const id = data.updateCampaignTemplate.result?.id
      // form.resetFields()
      if (id) {
        history.push(generatePath(Routes.CAMPAIGN_TEMPLATE_DETAIL, { id }))
      }
    }
  }

  const onVoucherCreateHandler = (values: VoucherInput | VoucherBatchTemplateInput, hasMultipleVouchers: boolean) => {
    if (campaignTemplateData) {
      if (hasMultipleVouchers) {
        void update({ ...campaignTemplateData, voucherBatch: values as VoucherBatchTemplateInput })
      } else {
        void update({ ...campaignTemplateData, voucher: values as VoucherInput })
      }
    }
  }

  useEffect(() => {
    let errorMessages: ErrorPayload = {}
    const mutationResponse = updateCampaignTemplateData?.updateCampaignTemplate
    if (mutationResponse && !mutationResponse.successful) {
      mutationResponse.messages?.forEach(message => {
        if (message?.__typename === 'ValidationMessage') {
          errorMessages = {
            ...errorMessages,
            [(message.field as string) || '']: message.message || '',
          }
        }
      })
      setFieldErrors(errorMessages)
    }
  }, [updateCampaignTemplateData])

  useEffect(() => {
    setVoucherTemplateDefaultValues(
      multipleVouchers ? defaultValues?.voucherBatch : defaultValues?.voucher && defaultValues?.voucher,
    )
  }, [multipleVouchers, defaultValues])

  return (
    <>
      <CampaignsForm<CampaignTemplateFormData, Partial<CampaignTemplateFormData>>
        onSubmitForm={handleSubmit}
        onValuesChange={handleValueChange}
        fieldErrors={fieldErrors}
        isLoading={updating}
        defaultValues={defaultValues}
        formType="Template"
      />
      <Modal title="Add vouchers" open={showModal} onCancel={() => setShowModal(false)} footer={null} width="70%">
        <VoucherTemplate
          handleSubmit={onVoucherCreateHandler}
          multipleVouchers={multipleVouchers}
          defaultValues={voucherTemplateDefaultValues}
        />
      </Modal>
    </>
  )
}

export default CampaignTemplateUpdate
