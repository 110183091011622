import Styled from 'styled-components'
import { Breadcrumb, Layout } from 'antd'

const { Sider } = Layout

export const SidebarContainer = Styled(Sider)`
  && {
    background-color: white;
    border-right: 1px solid #f0f0f0;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 100;

    .ant-image.logo img {
      object-fit: contain;
    }

    .ant-menu-inline {
      border: none;
      background-color: white;
    }

    .ant-menu {
      .ant-menu-item {
        .anticon {
          font-size: 14px;
        }
        font-size: 14px;
        font-weight: 400;
        color: #646464;
      }

      .ant-menu-submenu-active:hover {
        color: black;
      }

      .ant-menu-item-selected {
        background-color: #F0F0F0;
        font-size: 14px;
        font-weight: 600;
      }
      .ant-menu-submenu-selected {
        color: black;
        font-weight: 700;
      }

      .ant-menu-item-selected a, .ant-menu-item a:hover {
        color: black !important;
        font-weight: 600;
      }
    }

    .ant-menu-inline .ant-menu-item::after {
      border-right-color: #28B88D;
    }
  }
`
export const LogoTitle = Styled.div`
  color: #FADC5C;
  font-size: 30px;
  font-family: Zhi Mang Xing;
`

export const HeaderContainer = Styled(Layout.Header)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  border-bottom: 1px solid #f0f0f0;
  position: sticky;
  top: 0;
  z-index: 100;
`

export const SideBarMenuIconStyle = { fontSize: 20, color: 'rgba(0, 0, 0, 0.4)' }

export const StyledBreadCrumb = Styled(Breadcrumb)`
  && {
    padding-bottom: 12px;
  }
`

export const ContentMargin = { marginRight: 24, marginLeft: 24 }
export const ContentMarginTop = { marginTop: 24 }
