import React, { useEffect, useState } from 'react'
import { Input, InputNumber, Table, Select, Checkbox, Button, Form, FormInstance } from 'antd'
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { FormListFieldData, FormListOperation } from 'antd/lib/form/FormList'
import humanizeString from 'humanize-string'

import { DiscountValueType, VoucherSetting } from '../../apollo/generated/api'

const { Column } = Table
const { Option } = Select
export const defaultSetting = {
  ordinality: 1,
  value: 10,
  valueType: DiscountValueType.Percentage,
  valueLimit: null,
  freeShipping: false,
}
const MAX_SPLIT_VOUCHERS = 8
const defaultStyle = { marginBottom: 0 }

type VoucherSettingForm = FormInstance<{ voucherSettings: VoucherSetting[] | undefined }>

type FieldVisibilityMap = {
  [key: number]: boolean
}

interface Props {
  formInstance: VoucherSettingForm
  formlistFields: FormListFieldData[]
  formlistOperation: FormListOperation
  duplicated?: boolean
  onRowChange?: (index: number) => void /** Updated whenever voucher settings  add/delete */
}

function VouchersSettingsForm(props: Props): React.ReactElement {
  const { formInstance, duplicated, formlistFields, formlistOperation, onRowChange } = props
  const [discountLimitVisibilityState, setDiscountLimitVisibilityState] = useState<FieldVisibilityMap>({})
  const { voucherSettings } = formInstance.getFieldsValue()

  useEffect(() => {
    if (!voucherSettings) return

    const defaultDiscountLimitInputs: FieldVisibilityMap = {}
    voucherSettings.forEach((voucherSetting, index) => {
      if (voucherSetting.valueType === DiscountValueType.Fixed) {
        defaultDiscountLimitInputs[index] = true
      } else if (!duplicated) {
        defaultDiscountLimitInputs[index] = false
      }
    })
    setDiscountLimitVisibilityState({ ...defaultDiscountLimitInputs })
  }, [voucherSettings])

  const updateOrdinality = (formInstance: VoucherSettingForm) => {
    const formFields: VoucherSetting[] = formInstance.getFieldValue('voucherSettings')
    formInstance.setFieldValue(
      'voucherSettings',
      formFields.map((value, index) => {
        return { ...value, ordinality: index + 1 }
      }),
    )
  }

  const onDiscountValueTypeChange = (row: number, discountValueType: DiscountValueType) => {
    setDiscountLimitVisibilityState({
      ...discountLimitVisibilityState,
      [row]: discountValueType === DiscountValueType.Fixed,
    })
    updateDiscountLimitValue(row, discountValueType)
  }

  const updateDiscountLimitValue = (row: number, discountValueType: DiscountValueType) => {
    const { voucherSettings } = formInstance.getFieldsValue()
    if (!voucherSettings) return
    const selectedRow = voucherSettings[row]

    if (discountValueType === DiscountValueType.Fixed) {
      selectedRow.valueLimit = null
    }
    voucherSettings[row] = selectedRow as VoucherSetting
    // formInstance.setFieldValue('voucherSettings', voucherSettings)
  }

  const onDeleteRow = (index: number, form: VoucherSettingForm, remove: (index: number | number[]) => void) => {
    const updatedDiscountLimits = { ...discountLimitVisibilityState }
    delete updatedDiscountLimits[index]
    setDiscountLimitVisibilityState(updatedDiscountLimits)
    remove(index)
    if (onRowChange) {
      onRowChange(index)
    }
    updateOrdinality(form)
  }

  const onAddRow = () => {
    if (onRowChange) {
      onRowChange(-1) // -1 means add
    }
    formlistOperation.add(buildDefaultSettings(formlistFields.length))
  }

  const { remove } = formlistOperation
  function buildDefaultSettings(count: number): Omit<VoucherSetting, 'id'> {
    return {
      ...defaultSetting,
      ordinality: count + 1,
    }
  }

  return (
    <div style={{ width: '100%' }}>
      <Table dataSource={formlistFields} pagination={false}>
        <Column
          title="Ordinal"
          dataIndex="ordinal"
          key="ordinal"
          render={(_, value: FormListFieldData, index: number) => {
            return (
              <>
                <Form.Item
                  {...value}
                  name={[value.name, 'ordinality']}
                  fieldKey={[value.key, 'ordinality']}
                  style={defaultStyle}
                  hidden
                >
                  <Input style={{ width: '100%' }} />
                </Form.Item>

                <span>Order #{index + 1}</span>
              </>
            )
          }}
        />

        <Column
          title="Discount Value"
          dataIndex="value"
          key="value"
          render={(_, value: FormListFieldData) => (
            <Form.Item {...value} name={[value.name, 'value']} fieldKey={[value.key, 'value']} style={defaultStyle}>
              <InputNumber min={0} style={{ width: '100%' }} />
            </Form.Item>
          )}
        />

        <Column
          title="Type of discount"
          dataIndex="valueType"
          key="valueType"
          render={(_, value: FormListFieldData, row) => (
            <Form.Item
              {...value}
              name={[value.name, 'valueType']}
              fieldKey={[value.key, 'valueType']}
              style={defaultStyle}
            >
              <Select style={{ width: '100%' }} onChange={value => onDiscountValueTypeChange(row, value)}>
                {Object.values(DiscountValueType).map(type => (
                  <Option value={type} key={type}>
                    {humanizeString(type)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}
        />

        <Column
          title="Discount limit"
          dataIndex="valueLimit"
          key="valueLimit"
          render={(_, value: FormListFieldData, row) => (
            <Form.Item
              {...value}
              name={[value.name, 'valueLimit']}
              fieldKey={[value.key, 'valueLimit']}
              style={defaultStyle}
            >
              <InputNumber
                disabled={(discountLimitVisibilityState && discountLimitVisibilityState[row]) ?? false}
                min={0}
                style={{ width: '100%' }}
              />
            </Form.Item>
          )}
        />

        <Column
          title="Free shipping"
          dataIndex="freeShipping"
          key="freeShipping"
          align="center"
          render={(_, value: FormListFieldData) => (
            <Form.Item
              {...value}
              name={[value.name, 'freeShipping']}
              fieldKey={[value.key, 'freeShipping']}
              style={defaultStyle}
              valuePropName="checked"
            >
              <Checkbox />
            </Form.Item>
          )}
        />

        {formlistFields.length > 1 && (
          <Column
            title="Actions"
            align="center"
            render={value => (
              <Button type="link" onClick={() => onDeleteRow(value.name, formInstance, remove)} danger>
                <DeleteOutlined style={{ color: 'red' }} />
              </Button>
            )}
          />
        )}
      </Table>
      {formlistFields.length < MAX_SPLIT_VOUCHERS && (
        <Button
          // eslint-disable-next-line react/prop-types
          onClick={() => onAddRow()}
          icon={<PlusOutlined />}
          style={{ marginTop: 10, float: 'right' }}
        >
          Add
        </Button>
      )}
    </div>
  )
}

export default VouchersSettingsForm
