import { Col, Form, Row, Switch } from 'antd'
import React, { useEffect, useState } from 'react'

import { Maybe, VoucherBatchTemplateInput, VoucherInput } from '../../apollo/generated/api'
import { usageLimitField, customerUsageLimitField } from '../../constants/VoucherFormConstants'

import MultipleVouchersFormFields from './VoucherForm/MultipleVouchersFormFields'
import SingleVoucherFormFields from './VoucherForm/SingleVoucherFormFields'
import { SwitchWrapper, SwitchLabel } from './VoucherForm/style'

export type DefaultValueType = Maybe<VoucherInput> | VoucherBatchTemplateInput

type Props = {
  handleSubmit: (values: VoucherInput | VoucherBatchTemplateInput, hasMultipleVouchers: boolean) => void
  defaultValues?: DefaultValueType
  multipleVouchers?: boolean
}

const VoucherTemplate: React.FC<Props> = ({ handleSubmit, defaultValues, multipleVouchers = false }) => {
  const [form] = Form.useForm()
  const [hasMultipleVouchers, setHasMultipleVouchers] = useState(multipleVouchers)
  const submitButtonText = defaultValues ? 'Update' : 'Create'
  const buildDefaultValues = (data?: DefaultValueType) => {
    if (data) {
      return {
        ...(hasMultipleVouchers && { quantity: (data as VoucherBatchTemplateInput).quantity }),
        ...(!hasMultipleVouchers && { code: (data as VoucherInput).code }),
        redemptionLimitPerCustomer: data.redemptionLimitPerCustomer,
        totalRedemptionLimit: data.totalRedemptionLimit,
      }
    }

    return {}
  }

  useEffect(() => {
    form.setFieldsValue(buildDefaultValues(defaultValues))
  }, [defaultValues])

  function toggleHasMultipleVouchers() {
    setHasMultipleVouchers(state => !state)
  }

  return (
    <div>
      <Row justify="center">
        <Col md={14} lg={12} xl={10}>
          <Form.Provider>
            <Form
              form={form}
              onFinish={values => handleSubmit(values, hasMultipleVouchers)}
              name="voucher-form"
              layout="vertical"
              initialValues={{ [usageLimitField]: 1, [customerUsageLimitField]: 1 }}
            >
              <Row gutter={16}>
                <Col span={24}>
                  <SwitchWrapper>
                    <SwitchLabel onClick={toggleHasMultipleVouchers}>
                      Do you want to create multiple vouchers?
                    </SwitchLabel>
                    <Switch checked={hasMultipleVouchers} onChange={setHasMultipleVouchers} />
                  </SwitchWrapper>
                </Col>
              </Row>

              {hasMultipleVouchers ? (
                <MultipleVouchersFormFields submitButtonText={submitButtonText} formType="Template" />
              ) : (
                <SingleVoucherFormFields submitButtonText={submitButtonText} formType="Template" />
              )}
            </Form>
          </Form.Provider>
        </Col>
      </Row>
    </div>
  )
}

export default VoucherTemplate
