import React, { useState } from 'react'
import { useGoogleLogout } from 'react-google-login'
import { Redirect } from 'react-router-dom'
import { Button, message } from 'antd'
import { LogoutOutlined } from '@ant-design/icons'

import config from '../../../config'
import { Routes } from '../../../constants/RoutesConstants'
import { destroyUserSession, SessionMessages } from '../../../utils'
import { GOOGLE_COOKIE_POLICY } from '../../../constants/AuthConstants'
import { GOOGLE_LOGOUT_BUTTON_ERROR } from '../../../constants/MessagesConstants'

const Logout: React.FC = () => {
  const [loggedOut, setLoggedOut] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const { signOut, loaded } = useGoogleLogout({
    clientId: config.googleClientId,
    cookiePolicy: GOOGLE_COOKIE_POLICY,
    onLogoutSuccess: () => {
      destroyUserSession()
      setLoggedOut(true)
    },
    onFailure: (): void => {
      void message.error(GOOGLE_LOGOUT_BUTTON_ERROR)
    },
  })

  if (loggedOut) {
    return (
      <Redirect
        to={{
          pathname: Routes.LOGIN,
          state: { message: SessionMessages.UserLoggedOut },
        }}
      />
    )
  }

  return (
    <Button
      type="text"
      data-testid="logout-button"
      icon={<LogoutOutlined />}
      onClick={signOut}
      disabled={!loaded}
      style={{ opacity: 0.6 }}
    >
      Logout
    </Button>
  )
}

export default Logout
