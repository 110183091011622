import { Card, Col, Divider, Form, InputNumber, Row, Switch, Typography } from 'antd'
import React, { useEffect } from 'react'

import { PrimaryButton } from '../../globalStyles'

export interface FormValues {
  maxReferralCredits: number
  minCreditToRefer: number
  expiresIn: number
  referralRewardExpiresIn: number
  referralRewardEnabled: boolean
}

interface Props {
  disabled?: boolean
  initialValues?: Partial<FormValues>
  onSubmit?: (values: FormValues) => void
}

function ReferralProgramForm({ disabled, initialValues, onSubmit }: Props): JSX.Element {
  const [form] = Form.useForm()

  const rewardsEnabled = Form.useWatch('referralRewardEnabled', form)

  useEffect(() => {
    form.setFieldsValue(initialValues)
  }, [form, initialValues])

  return (
    <Card>
      <Form form={form} onFinish={onSubmit} disabled={disabled} layout="vertical">
        <Row gutter={16}>
          <Col md={24} lg={8}>
            <Form.Item
              name="maxReferralCredits"
              label="Maximum referral credits"
              help="The max. number of referral credits a customer can accumulate (1 point given per completed order)."
              rules={[{ required: true, type: 'number', min: 0 }]}
            >
              <InputNumber style={{ width: '100%' }} />
            </Form.Item>
          </Col>

          <Col md={24} lg={8}>
            <Form.Item
              name="minCreditToRefer"
              label="Minimum credits to refer"
              help="The min. number of referral credits a customer must have earned to start refering."
              rules={[{ required: true, type: 'number', min: 0 }]}
            >
              <InputNumber style={{ width: '100%' }} />
            </Form.Item>
          </Col>

          <Col md={24} lg={8}>
            <Form.Item
              name="expiresIn"
              label="Referral expires in (days)"
              help="The validity, in days, for new referrals."
              rules={[{ required: true, type: 'number', min: 0 }]}
            >
              <InputNumber style={{ width: '100%' }} />
            </Form.Item>
          </Col>
        </Row>

        <Typography.Title level={5} style={{ marginTop: 50, marginBottom: 5 }}>
          Rewards
        </Typography.Title>

        <Typography.Paragraph type="secondary" style={{ marginBottom: 20 }}>
          The rewards program grants a benefit to the referrer once their referral gets consumed.
        </Typography.Paragraph>

        <Row gutter={16}>
          <Col md={24} lg={8}>
            <Form.Item name="referralRewardEnabled" label="Rewards enabled" valuePropName="checked">
              <Switch />
            </Form.Item>
          </Col>

          <Col md={24} lg={8}>
            <Form.Item
              name="referralRewardExpiresIn"
              label="Reward expires in (days)"
              help="The validity, in days, for new referral rewards."
              rules={[{ type: 'number', min: 0, required: rewardsEnabled }]}
            >
              <InputNumber style={{ width: '100%' }} disabled={!rewardsEnabled} />
            </Form.Item>
          </Col>
        </Row>

        <Row justify="center">
          <Divider />

          <Col sm={24} md={8}>
            <PrimaryButton htmlType="submit" block>
              Save
            </PrimaryButton>
          </Col>
        </Row>
      </Form>
    </Card>
  )
}

ReferralProgramForm.defaultProps = {
  disabled: false,
  initialValues: undefined,
  onSubmit: undefined,
}

export default ReferralProgramForm
