import React from 'react'
import { Layout as AntLayout } from 'antd'

import Layout from '../../components/Layout'

const { Content } = AntLayout
function MainContainer({ children }: React.PropsWithChildren<unknown>): JSX.Element {
  return (
    <Layout>
      <Content style={{ height: '100%' }}>{children}</Content>
    </Layout>
  )
}
export default MainContainer
