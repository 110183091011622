import { Table } from 'antd'
import React from 'react'
import humanizeString from 'humanize-string'

import { VoucherSetting, VoucherSettingVariant } from '../../apollo/generated/api'

const { Column } = Table

interface VoucherSettingsProps {
  voucherSettings: VoucherSetting[]
}

const VoucherSettings: React.FC<VoucherSettingsProps> = ({ voucherSettings }): JSX.Element => {
  return (
    <Table
      rowKey="id"
      dataSource={voucherSettings || []}
      pagination={false}
      expandable={{
        columnTitle: 'Plans',
        showExpandColumn: true,
        expandedRowRender: setting => {
          const dataSource = (setting?.voucherSettingVariants || []) as VoucherSettingVariant[]

          return (
            <Table rowKey="id" dataSource={dataSource} pagination={false}>
              <Column
                title="Plan"
                key="plan"
                render={record => (
                  <span>
                    {' '}
                    {record.numberOfPersons} People / {record.numberOfMeals} Meals{' '}
                  </span>
                )}
              />
              <Column width="200" title="Discount value" key="value" dataIndex="value" />
              <Column
                title="Type of discount"
                key="discount-value-type"
                dataIndex="valueType"
                render={value => humanizeString(value)}
              />
              <Column title="Discount limit" key="discount-limit" dataIndex="valueLimit" />
              <Column
                title="Free shipping"
                key="free-shipping"
                dataIndex="freeShipping"
                align="center"
                render={value => (value ? 'Yes' : 'No')}
              />
            </Table>
          )
        },
        rowExpandable: setting => !!setting?.voucherSettingVariants?.length,
      }}
    >
      <Column title="Ordinal" key="order-ordinal" dataIndex="ordinality" />
      <Column title="Discount Value" key="discount-value" dataIndex="value" />
      <Column
        title="Type of discount"
        key="discount-value-type"
        dataIndex="valueType"
        render={value => humanizeString(value)}
      />
      <Column title="Discount limit" key="value-limit" dataIndex="valueLimit" />
      <Column
        title="Free shipping"
        key="free-shipping"
        dataIndex="freeShipping"
        align="center"
        render={value => (value ? 'Yes' : 'No')}
      />
    </Table>
  )
}

export default VoucherSettings
