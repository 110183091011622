import { Form, Select } from 'antd'
import React, { useEffect } from 'react'

import { useCountriesQuery } from '../../../apollo/generated/rewards'

interface Props {
  label?: string | null
  required?: boolean
  disabled?: boolean
  onClear?: (fieldName: string) => void
}

const CountryDropdown: React.FC<Props> = ({ label, required, disabled, onClear }: Props) => {
  const [isMounted, setIsMounted] = React.useState(false)
  const { data } = useCountriesQuery({ fetchPolicy: 'cache-first' })

  const options = data?.countries || []
  const onClearHandler = () => {
    if (onClear) {
      onClear('countryId')
    }
  }

  useEffect(() => {
    setIsMounted(true)

    return () => {
      setIsMounted(false)
    }
  }, [])

  return (
    <Form.Item
      labelCol={{ span: 24 }}
      name="countryId"
      label={label}
      rules={[{ required, message: 'Please select a country!' }]}
    >
      <Select
        style={{ width: '100%' }}
        placeholder="Select Country"
        optionFilterProp="children"
        data-testid="country-dropdown"
        disabled={disabled}
        showSearch
        allowClear
        onClear={onClearHandler}
      >
        {isMounted &&
          options.map(country => (
            <Select.Option value={country?.id || ''} key={country?.id}>
              {country?.name}
            </Select.Option>
          ))}
      </Select>
    </Form.Item>
  )
}

CountryDropdown.defaultProps = {
  label: 'Country',
  required: false,
  disabled: false,
  onClear: undefined,
}

export default CountryDropdown
