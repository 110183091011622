import List from './Campaigns'
import Create from './CampaignCreate'
import Update from './CampaignUpdate'
import Details from './CampaignsDetails/CampaignDetails'
import Duplicate from './CampaignDuplicate'

const Campaigns = {
  List,
  Create,
  Update,
  Details,
  Duplicate,
}

export default Campaigns
