import { PageHeader } from '@ant-design/pro-components'
import { Collapse, Divider, Tabs, Spin } from 'antd'
import React, { useState } from 'react'
import { useParams, generatePath, useHistory } from 'react-router'
import { Link } from 'react-router-dom'

import { Campaign, useCampaignQuery, VoucherSetting } from '../../../apollo/generated/api'
import NotFound from '../../../packages/UnauthorizedComponentError'
import { Routes } from '../../../constants/RoutesConstants'
import VouchersTab from '../../Vouchers/VouchersTab'
import VoucherSettings from '../../VoucherSettings'
import Breadcrumbs from '../../Layout/breadcrumbs'
import { ContentMargin } from '../../Layout/styles'
import { PrimaryButton, SecondaryButton } from '../../globalStyles'
import VoucherExportButton from '../../Vouchers/VoucherExportButton'
import { downloadCSV } from '../../../services'
import CampaignTemplateCreateModal from '../CampaignTemplateCreateModal'

import ManagedCampaignDetails from './ManagedCampaignDetails'

const { TabPane } = Tabs
const { Panel } = Collapse

const CampaignDetails = () => {
  const { id } = useParams<{ id: string }>()

  const history = useHistory()
  const [isExportingVouchers, setIsExportingVouchers] = useState(false)
  const [showCreateTemplateModal, setShowCreateTemplateModal] = useState(false)
  const {
    // eslint-disable-next-line prettier/prettier
    data: campaignData,
    loading: campaignLoading,
    error: campaignError,
  } = useCampaignQuery({
    variables: { id },
  })

  const campaign = campaignData?.campaign
  const onExportCSV = async () => {
    setIsExportingVouchers(true)
    await downloadCSV(id)
    setIsExportingVouchers(false)
  }
  if (campaignLoading) {
    return (
      <div className="campaign-details-loading" style={{ textAlign: 'center' }}>
        <Spin data-testid="loading-data" size="large" tip="Loading campaign details..." />
      </div>
    )
  }
  if (campaignError || (campaignData && campaignData.campaign === null)) {
    return <NotFound message="Campaign not found" />
  }

  const historicVoucherSettings = (campaign?.historicVoucherSettings as VoucherSetting[]) || []

  const groupedHistoricVoucherSettings: { version: number; settings: VoucherSetting[] }[] = []

  historicVoucherSettings.forEach(setting => {
    const { version } = setting

    let entry = groupedHistoricVoucherSettings.find(entry => entry.version === version)

    if (!entry && version != null) {
      entry = { version, settings: [] }
      groupedHistoricVoucherSettings.push(entry)
    }

    if (entry) {
      entry.settings.push(setting)
    }
  })

  return (
    <>
      <PageHeader
        title={campaign?.name}
        onBack={history.goBack}
        extra={[
          <Link key="duplicate-campaign-link" to={generatePath(Routes.CAMPAIGN_DUPLICATE, { id: campaign?.id })}>
            <PrimaryButton>Duplicate Campaign</PrimaryButton>
          </Link>,
          <Link key="edit-campaign-link" to={generatePath(Routes.CAMPAIGN_EDIT, { id: campaign?.id })}>
            <PrimaryButton>Edit Campaign</PrimaryButton>
          </Link>,
          <SecondaryButton key="create-campaign" onClick={() => setShowCreateTemplateModal(true)}>
            Create Template
          </SecondaryButton>,
        ]}
      >
        <Breadcrumbs param={{ name: 'id', value: id }} />
      </PageHeader>

      <div style={ContentMargin} data-testid="campaign-details">
        <ManagedCampaignDetails campaign={campaign as Campaign} columnCount={4} />

        <Divider />
        <Tabs
          defaultActiveKey="vouchers"
          tabBarExtraContent={
            <div style={{ display: 'flex' }}>
              <VoucherExportButton onClick={onExportCSV} label="Export as CSV" loading={isExportingVouchers} />
              <PrimaryButton>
                <Link to={generatePath(Routes.VOUCHER_CREATE, { campaignId: campaign?.id })}>Create Voucher</Link>
              </PrimaryButton>
            </div>
          }
        >
          <TabPane tab={<span>Vouchers</span>} key="vouchers">
            <VouchersTab campaignId={id} />
          </TabPane>
          <TabPane tab={<span>Voucher Settings</span>} key="voucher-settings">
            <VoucherSettings voucherSettings={campaign?.voucherSettings as VoucherSetting[]} />
          </TabPane>
          {historicVoucherSettings.length > 0 && (
            <TabPane tab={<span>Voucher Settings History</span>} key="historic-voucher-settings">
              <Collapse defaultActiveKey={groupedHistoricVoucherSettings[0].version.toString()}>
                {groupedHistoricVoucherSettings.map(({ version, settings }) => (
                  <Panel header={`Version ${version}`} key={version.toString()}>
                    <VoucherSettings voucherSettings={settings} />
                  </Panel>
                ))}
              </Collapse>
            </TabPane>
          )}
        </Tabs>
      </div>
      <CampaignTemplateCreateModal
        onClose={() => setShowCreateTemplateModal(false)}
        visible={showCreateTemplateModal}
        campaign={campaignData?.campaign as Campaign}
      />
    </>
  )
}
export default CampaignDetails
