import { Checkbox } from 'antd'
import React from 'react'
import type { CheckboxValueType } from 'antd/es/checkbox/Group'

import { DeliveryDayType } from '../../apollo/generated/api'
import { capitalize } from '../../utils'

interface Props {
  selectedDeliveryDays?: any[]
  onChange: (checkedValues: CheckboxValueType[]) => void
}

const weekDays = Object.values(DeliveryDayType).map(day => ({ label: capitalize(day), value: day }))

function DeliveryDaysCheckbox({ selectedDeliveryDays, onChange }: Props): JSX.Element {
  return <Checkbox.Group options={weekDays} defaultValue={selectedDeliveryDays} onChange={onChange} />
}

DeliveryDaysCheckbox.defaultProps = {
  selectedDeliveryDays: [],
}

export default DeliveryDaysCheckbox
