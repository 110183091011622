import { Button, Checkbox, Col, Form, Input, notification, Row } from 'antd'
import { Store } from 'antd/lib/form/interface'
import React, { useEffect, useState } from 'react'

import { handleMutationResult } from '../../apollo'
import { queryNames } from '../../apollo/client'
import {
  Account,
  AccountsDocument,
  CreateAccountMutationVariables,
  UpdateAccountMutationVariables,
  useCreateAccountMutation,
  useUpdateAccountMutation,
  UserRoleType,
  UpdateAccountMutation,
  CreateAccountMutation,
} from '../../apollo/generated/api'
import { FIELD_REQUIRED_ERROR } from '../../constants/ErrorConstants'
import { canAccessRoleFeatures } from '../../utils/authUtils/authUtils'

interface FormStore extends Store {
  email: string
}

const UserForm: React.FC<{ onToggle: Function; isEditing: boolean; userData: Account | null }> = ({
  onToggle,
  isEditing,
  userData,
}) => {
  const [form] = Form.useForm()
  const userRoleOptions = [
    { label: 'Referrals Admin', value: 'REFERRALS_ADMIN' },
    { label: 'Rewards Admin', value: 'REWARDS_ADMIN' },
    { label: 'Super Admin', value: 'SUPER_ADMIN', disabled: true },
  ]

  const [createAccount, { loading: creatingUser }] = useCreateAccountMutation()
  const [updateAccount, { loading: updatingUser }] = useUpdateAccountMutation()

  const [mutationErrors, setMutationErrors] = useState<any>()

  useEffect(() => {
    if (isEditing && userData) {
      form.setFieldsValue({
        email: userData.email,
        userRoles: userData.userRoles || [],
      })
    }
  }, [isEditing, userData])

  useEffect(() => {
    if (!isEditing) {
      form.resetFields()
    }

    return () => form.resetFields()
  }, [isEditing])

  useEffect(() => {
    if (mutationErrors) {
      notification.error({ message: mutationErrors.map((err: any) => err.message) })
    }
  }, [mutationErrors])

  const onFinish = async (values: FormStore): Promise<void> => {
    const createVariables: CreateAccountMutationVariables = {
      input: {
        email: values.email,
        userRoles: values.userRoles || [],
      },
    }

    const updateVariables: UpdateAccountMutationVariables = {
      input: {
        id: userData?.id as string,
        email: values.email,
        userRoles: values.userRoles || [],
      },
    }

    const getCreateMutation = () =>
      createAccount({
        variables: createVariables,
        refetchQueries: queryNames(AccountsDocument),
      })

    const getUpdateMutation = () =>
      updateAccount({
        variables: updateVariables,
        refetchQueries: queryNames(AccountsDocument),
      })

    const { data } = isEditing
      ? await handleMutationResult(getUpdateMutation(), 'updateAccount')
      : await handleMutationResult(getCreateMutation(), 'createAccount')

    const mutationResult = isEditing
      ? (data as UpdateAccountMutation)?.updateAccount
      : (data as CreateAccountMutation)?.createAccount

    if (mutationResult?.successful) {
      notification.success({ message: `User ${isEditing ? 'updated' : 'added'} successfully` })
      form.resetFields()
      onToggle()
      setMutationErrors(undefined)
    }
  }

  return (
    <Form form={form} layout="vertical" name="userForm" onFinish={onFinish}>
      <Row gutter={16}>
        <Col md={24}>
          <Form.Item
            label="Email Address"
            name="email"
            rules={[
              {
                required: !isEditing,
                message: FIELD_REQUIRED_ERROR,
              },
            ]}
            hasFeedback={!isEditing}
          >
            <Input
              type="email"
              size="large"
              placeholder="Email address"
              disabled={isEditing}
              style={{ width: '100%' }}
              autoFocus
            />
          </Form.Item>
          {canAccessRoleFeatures(UserRoleType.SuperAdmin) && (
            <Form.Item label="User Roles" name="userRoles">
              <Checkbox.Group options={userRoleOptions} />
            </Form.Item>
          )}
        </Col>
      </Row>
      <Row>
        <Col md={24}>
          <Button
            data-testid="submit-admin-user-form"
            htmlType="submit"
            type="primary"
            size="large"
            disabled={creatingUser || updatingUser}
            loading={creatingUser || updatingUser}
            block
          >
            {isEditing ? 'Update User' : 'Create User'}
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

export default UserForm
