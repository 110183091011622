/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { ExecutionResult } from 'graphql'
import { notification } from 'antd'
import { ApolloError } from '@apollo/client'

import * as messages from '../constants/MessagesConstants'
import Stage from '../constants/StagesConstants'
import config from '../config'

import { Maybe, ValidationMessage } from './generated/api'

type MutationResponse<MutationData, MutationName extends keyof MutationData> = {
  readonly [K in MutationName]?: ({ __typename?: string } & unknown) | null
}

type ChilliMutationResponse = {
  messages: Maybe<Array<Maybe<ValidationMessage>>>
  result: unknown | null
  successful: boolean
}

export type MutationResultNotification = {
  title?: string
  description?: string
}

export type MutationHandlerOptions = {
  notifications?: {
    success?: { title?: string; description?: string }
    error?: { title?: string }
  }
}

export type HandlerResult<MutationData> = {
  data?: MutationData | null
  error?: ApolloError
}

const errorNotification = (description: string, options?: MutationHandlerOptions): void =>
  notification.error({
    message: options?.notifications?.error?.title ?? messages.ERROR_TITLE,
    description,
  })
const handleSuccess = (options?: MutationHandlerOptions): void =>
  notification.success({
    message: options?.notifications?.success?.title || messages.MUTATION_SUCCESS,
    description: options?.notifications?.success?.description || messages.KEEP_GOING,
  })

const handleDomainError = (errors: ValidationMessage[], options?: MutationHandlerOptions): void =>
  errorNotification(
    errors?.map(error => `${error.field}: ${error.message}`).join('\n') || messages.UNKNOWN_ERROR,
    options,
  )

const handleMutationException = (error: Error, options?: MutationHandlerOptions): void => {
  let { message } = error

  if ('networkError' in error || 'graphQLErrors' in error) {
    const apolloError = error as ApolloError

    if (apolloError.networkError) {
      message = messages.NETWORK_ERROR
    } else if (apolloError.graphQLErrors) {
      message = apolloError.graphQLErrors.map(e => e.message).join('\n')
    }
  }

  if (![Stage.TEST, Stage.PRODUCTION].includes(config.stage)) {
    console.error(error)
  }

  return errorNotification(message, options)
}

export async function handleMutationResult<
  MutationData extends MutationResponse<MutationData, ResultKey>,
  ResultKey extends keyof MutationData,
>(
  mutation: Promise<ExecutionResult<MutationData>>,
  resultKey: ResultKey,
  options?: MutationHandlerOptions,
): Promise<HandlerResult<MutationData>> {
  let response

  try {
    response = await mutation
  } catch (ex) {
    handleMutationException(ex as Error, options)

    return { error: ex as ApolloError }
  }
  const result = response.data?.[resultKey] as unknown as ChilliMutationResponse
  if (result.successful) {
    handleSuccess(options)
  } else {
    handleDomainError(result.messages as ValidationMessage[])
  }

  return response
}
