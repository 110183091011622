import { Col, Form, Input, Row, Spin } from 'antd'
import React, { useEffect } from 'react'
import { Store } from 'antd/lib/form/interface'

import { useCampaignQuery, useVouchersQuery, VoucherEdge, VoucherFiltersInput } from '../../apollo/generated/api'
import { useNetworkStatus } from '../../hooks'
import { handleError } from '../../utils/errorUtils'
import { parseEmpty } from '../../utils/textUtils/textUtils'
import { LAST_SEARCHED_VOUCHER_CODE_FILTER, VOUCHER_CODE_SEARCH_FORM_FILTER } from '../../constants/AppConstants'
import { cacheVoucherCodeFilter, getCachedVoucherCodeFilter } from '../../utils/voucherUtils'

import VouchersList from './VouchersSearch/VoucherList'

const PAGE_SIZE = 25
const { Search } = Input

const VouchersTab: React.FC<VoucherFiltersInput> = ({ campaignId }): JSX.Element => {
  const [form] = Form.useForm()

  const lastFormFilter = getCachedVoucherCodeFilter(VOUCHER_CODE_SEARCH_FORM_FILTER, campaignId as string)
  const lastSearchedFilters = getCachedVoucherCodeFilter(LAST_SEARCHED_VOUCHER_CODE_FILTER, campaignId as string)

  const {
    // eslint-disable-next-line prettier/prettier
    data: vouchersData,
    fetchMore: fetchMoreVouchers,
    refetch: refetchVouchers,
    networkStatus,
  } = useVouchersQuery({
    variables: { filters: { campaignId, ...lastSearchedFilters }, first: PAGE_SIZE },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  })

  const {
    data: campaignData,
    stopPolling: stopPollingForCampaignQuery,
    startPolling: startPollingForCampaignQuery,
  } = useCampaignQuery({
    variables: { id: campaignId || '' },
    fetchPolicy: 'cache-and-network',
  })

  const vouchers = vouchersData?.vouchers?.edges as VoucherEdge[]

  const { isLoading, isLoadingMore } = useNetworkStatus(networkStatus)

  const pageInfo = vouchersData?.vouchers?.pageInfo
  const canLoadMore = !!vouchers?.length && pageInfo?.hasNextPage

  const handleFilters = (filters: Store) => {
    const variables = {
      first: PAGE_SIZE,
      filters: {
        campaignId,
        code: parseEmpty(filters.code),
      },
    }

    cacheVoucherCodeFilter(LAST_SEARCHED_VOUCHER_CODE_FILTER, variables.filters)
    cacheVoucherCodeFilter(VOUCHER_CODE_SEARCH_FORM_FILTER, variables.filters)

    void handleError(() => refetchVouchers?.(variables))
  }

  const handleSearch = (code: string) => handleFilters({ code })

  const handleLoadMore = () => {
    if (pageInfo?.hasNextPage && pageInfo?.endCursor) {
      void handleError(() => fetchMoreVouchers({ variables: { after: pageInfo.endCursor } }))
    }
  }

  const handleValuesChange = (_changedValues: object, filters: Store) => {
    cacheVoucherCodeFilter(VOUCHER_CODE_SEARCH_FORM_FILTER, { campaignId, ...filters })
  }

  useEffect(() => {
    const isCreatingVouchers = campaignData?.campaign?.isCreatingVouchers || false
    if (isCreatingVouchers) {
      startPollingForCampaignQuery(3000)
    } else {
      stopPollingForCampaignQuery()
      void handleError(() =>
        refetchVouchers({
          first: PAGE_SIZE,
          filters: {
            campaignId,
          },
        }),
      )
    }
  }, [campaignData?.campaign?.isCreatingVouchers])

  return (
    <>
      <Row gutter={16}>
        <Col span={12}>
          <Form
            form={form}
            initialValues={lastFormFilter}
            onValuesChange={handleValuesChange}
            onFinish={handleFilters}
            layout="vertical"
          >
            <Form.Item name="code">
              <Search data-testid="code-search" placeholder="input voucher code" allowClear onSearch={handleSearch} />
            </Form.Item>
          </Form>
        </Col>
        <Col span={12}>
          {campaignData?.campaign?.isCreatingVouchers && (
            <Row gutter={10} justify="end">
              <Col>
                <Spin size="small" />
              </Col>
              <Col style={{ color: '#ff383c' }}>Creating Vouchers...</Col>
            </Row>
          )}
        </Col>
      </Row>

      <VouchersList vouchers={vouchers} connectionData={{ isLoading, canLoadMore, handleLoadMore, isLoadingMore }} />
    </>
  )
}

export default VouchersTab
