import { List, Modal } from 'antd'
import React, { useEffect } from 'react'
import { generatePath, useHistory } from 'react-router'
import { Link } from 'react-router-dom'

import { Maybe, ReturnMessageTemplate } from '../../apollo/generated/api'
import { Routes } from '../../constants/RoutesConstants'

const CampaignsGeneratorErrorModal: React.FC<{
  title?: string
  errorMessages?: Maybe<Array<Maybe<ReturnMessageTemplate>>>
}> = ({ title, errorMessages }) => {
  const [modal, contextHolder] = Modal.useModal()
  const history = useHistory()

  const config = {
    title,
    width: '60%',
    content: (
      <List
        size="default"
        bordered
        dataSource={errorMessages || []}
        renderItem={item => (
          <List.Item>
            {item?.description}{' '}
            {item?.campaignId && (
              <Link
                target="_blank"
                rel="noopener noreferrer"
                to={generatePath(Routes.CAMPAIGN_DETAIL, { id: item?.campaignId ?? 0 })}
              >
                View Campaign
              </Link>
            )}
          </List.Item>
        )}
      />
    ),
    onOk: () => history.push(generatePath(Routes.CAMPAIGNS)),
  }

  useEffect(() => {
    void modal.error(config)
  }, [])

  return <div>{contextHolder}</div>
}

export default CampaignsGeneratorErrorModal
