import moment from 'moment-timezone'

import { FormData } from '../../components/RewardCampaigns/RewardCampaignsForm/types'
import { Campaign, LeadingMetric, TargetAttribute } from '../../apollo/generated/rewards'

export function buildInitialValues(campaign: Campaign) {
  if (!campaign) {
    return {}
  }

  const timezone = campaign.country?.timezone ?? 'UTC'

  return {
    name: campaign.name,
    internalName: campaign.internalName,
    description: campaign.description,
    type: campaign.type,
    leadingMetric: campaign.leadingMetric,
    brandId: campaign.brand?.id,
    countryId: campaign.country?.id,
    validity: [moment.tz(campaign.startsAt, timezone), campaign.endsAt && moment.tz(campaign.endsAt, timezone)],
    eligibilityConditions: (campaign.eligibilityConditions || []).map(condition => ({
      targetAttributeName: condition?.targetAttributeName as TargetAttribute,
      targetAttributeValue: String(condition?.targetAttributeValue),
    })),
    settings: buildSettingsInitialValues(campaign.reward),
  }
}

function buildSettingsInitialValues(reward: Campaign['reward']) {
  if (reward?.__typename === 'MarketItemReward') {
    return {
      rewardType: 'market_item',
      value: Number(reward.value),
      valueLimit: Number(reward.valueLimit),
      valueType: reward.valueType,
      sku: reward.sku,
    }
  }

  return {
    rewardType: 'order_discount',
    value: Number(reward?.value),
    valueLimit: Number(reward?.valueLimit),
    valueType: reward?.valueType,
  }
}

export function buildPayload(values: FormData, timezone: string) {
  return {
    name: values.name,
    internalName: values.internalName,
    description: values.description,
    type: values.type,
    leadingMetric: values.leadingMetric as LeadingMetric,
    brandId: values.brandId,
    countryId: values.countryId,
    startsAt: moment(values.validity[0]).tz(timezone).startOf('day').format(),
    endsAt: !values.validity[1] ? null : moment(values.validity[1]).tz(timezone).endOf('day').format(),
    eligibilityConditions: (values.eligibilityConditions || []).map(condition => ({
      targetAttributeName: condition.targetAttributeName as TargetAttribute,
      targetAttributeValue: String(condition.targetAttributeValue),
    })),
    reward: buildRewardPayload(values.settings),
  }
}

function buildRewardPayload(reward: FormData['settings']) {
  if (reward.rewardType === 'market_item') {
    return {
      marketItem: {
        value: Number(reward.value),
        valueLimit: Number(reward.valueLimit),
        valueType: reward.valueType,
        sku: reward.sku,
      },
    }
  }

  return {
    orderDiscount: {
      value: Number(reward?.value),
      valueLimit: Number(reward?.valueLimit),
      valueType: reward?.valueType,
    },
  }
}
