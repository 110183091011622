import React from 'react'
import { PageHeader } from '@ant-design/pro-components'
import { Descriptions, Divider, Button, Spin, Typography } from 'antd'
import { useHistory, useParams } from 'react-router'
import { Link, generatePath } from 'react-router-dom'
import moment from 'moment'

import { useVoucherQuery, VoucherSetting, Campaign } from '../../apollo/generated/api'
import NotFound from '../../packages/UnauthorizedComponentError'
import { Routes } from '../../constants/RoutesConstants'
import CampaignDetails from '../Campaigns/CampaignsDetails/ManagedCampaignDetails'
import VoucherSettings from '../VoucherSettings'
import BreadCrumbs from '../Layout/breadcrumbs'
import { ContentMargin } from '../Layout/styles'

function VoucherDetails(): React.ReactElement {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()

  const {
    // eslint-disable-next-line prettier/prettier
    data: voucherData,
    loading,
    error,
  } = useVoucherQuery({
    variables: { id },
    fetchPolicy: 'cache-and-network',
  })

  const voucher = voucherData?.voucher

  if (error) return <NotFound message="Voucher not found" />

  return (
    <div>
      <PageHeader
        title={voucher?.code}
        onBack={history.goBack}
        extra={[
          <Link key="edit-voucher" to={generatePath(Routes.VOUCHER_EDIT, { id })}>
            <Button>Edit voucher</Button>
          </Link>,
        ]}
      >
        <BreadCrumbs param={{ name: 'id', value: id }} />
      </PageHeader>

      <div style={ContentMargin}>
        {loading ? (
          <div className="voucher-details-loading" style={{ textAlign: 'center' }}>
            <Spin size="large" tip="Loading voucher details..." />
          </div>
        ) : (
          <>
            <Descriptions column={4} style={{ fontWeight: 'bold' }} layout="vertical" bordered>
              <Descriptions.Item label="Current number of redemptions">{voucher?.redemptionsCount}</Descriptions.Item>
              <Descriptions.Item label="Usage limit (total)">{voucher?.totalRedemptionLimit}</Descriptions.Item>
              <Descriptions.Item label="Usage limit (per customer)">
                {voucher?.redemptionLimitPerCustomer}
              </Descriptions.Item>
              <Descriptions.Item label="Expiration date">
                {voucher?.expiresAt ? (
                  moment.utc(voucher?.expiresAt).format('LL')
                ) : (
                  <Typography.Text type="secondary">Limited by campaign expiration date</Typography.Text>
                )}
              </Descriptions.Item>
            </Descriptions>

            <Divider />

            <CampaignDetails campaign={voucher?.campaign as Campaign} includeMarketingCopy={false} />

            <Divider />

            <Descriptions title="Voucher settings" style={{ fontWeight: 'bold' }} />
            <VoucherSettings voucherSettings={voucher?.campaign?.voucherSettings as VoucherSetting[]} />
          </>
        )}
      </div>
    </div>
  )
}

export default VoucherDetails
