import React from 'react'
import { Spin } from 'antd'
import styled from 'styled-components'

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  justify-content: center;
`

const StyledText = styled.span`
  margin-left: 1rem;
`

export const LoadingIndicator = ({ text }: { text?: string }) => (
  <StyledContainer>
    <Spin tip={text || 'Loading'} />
    {text ? <StyledText>Loading plans</StyledText> : null}
  </StyledContainer>
)
