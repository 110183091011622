import { Table, Button, Space, Row, Col, Typography } from 'antd'
import React from 'react'
import { generatePath, Link } from 'react-router-dom'
import { Store } from 'antd/lib/form/interface'
import moment from 'moment'

import { Voucher, VoucherEdge } from '../../../apollo/generated/api'
import { ScreenBreakpoints } from '../../../constants/LayoutConstants'
import { Routes } from '../../../constants/RoutesConstants'

const { Text } = Typography

interface VouchersListProps {
  vouchers: VoucherEdge[] | { node: Voucher }[]
  connectionData: {
    isLoading: boolean
    canLoadMore?: boolean
    handleLoadMore: (data: Store) => void
    isLoadingMore: boolean
  }
}

const VouchersList: React.FC<VouchersListProps> = ({
  vouchers,
  connectionData: { isLoading, canLoadMore, handleLoadMore, isLoadingMore },
}): JSX.Element => {
  return (
    <>
      <Table
        rowKey={voucher => voucher?.node?.id as string}
        dataSource={vouchers || []}
        loading={isLoading}
        pagination={false}
        scroll={{ x: ScreenBreakpoints.SM }}
        columns={[
          { title: '#', key: 'voucher-id', dataIndex: ['node', 'id'] },
          {
            title: 'Voucher Code',
            key: 'code',
            dataIndex: ['node', 'code'],
            render: (value: string) => (
              <Text code copyable>
                {value}
              </Text>
            ),
          },
          {
            title: 'Usage limit (total)',
            key: 'voucher-usage-limit',
            dataIndex: ['node'],
            render: ({ totalRedemptionLimit, redemptionsCount }) => `${redemptionsCount}/${totalRedemptionLimit}`,
          },
          {
            title: 'Usage limit (per customer)',
            key: 'voucher-usage-limit-per-customer',
            dataIndex: ['node', 'redemptionLimitPerCustomer'],
          },
          {
            title: 'Expires at',
            key: 'voucher-expires-at',
            dataIndex: ['node', 'expiresAt'],
            render: expiresAt =>
              expiresAt ? (
                moment.utc(expiresAt).format('LL')
              ) : (
                <Typography.Text type="secondary">Limited by campaign expiration date</Typography.Text>
              ),
          },
          {
            title: 'Campaign',
            key: 'voucher-campaign-link',
            render: ({
              node: {
                campaign: { id, name },
              },
            }): JSX.Element => (
              <Space size="middle">
                <Link key="voucher_campaign_link" to={generatePath(Routes.CAMPAIGN_DETAIL, { id })}>
                  {name}
                </Link>
              </Space>
            ),
          },
          {
            title: '',
            key: 'voucher-list-action-links',
            render: ({ node: { id } }): JSX.Element => (
              <Space size="middle">
                <Link key="voucher_details_link" to={generatePath(Routes.VOUCHER_DETAIL, { id })}>
                  View
                </Link>
                <Link key="edit_voucher_link" to={generatePath(Routes.VOUCHER_EDIT, { id })}>
                  Edit
                </Link>
              </Space>
            ),
          },
        ]}
      />

      {canLoadMore && (
        <Row justify="center" style={{ paddingTop: '1.5rem' }}>
          <Col>
            <Button onClick={handleLoadMore} loading={isLoadingMore}>
              Load More
            </Button>
          </Col>
        </Row>
      )}
    </>
  )
}

export default VouchersList
