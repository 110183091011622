import { Button, Col, Space } from 'antd'
import { ClearOutlined } from '@ant-design/icons'
import React from 'react'
import { Store } from 'antd/lib/form/interface'

interface Props {
  onClick?: (data: Store) => void
}

function ClearFormFiltersButton({ onClick }: Props): JSX.Element {
  return (
    <Col xs={24} style={{ textAlign: 'center' }}>
      <Button style={{ display: 'inline-block', margin: '0 8px' }} onClick={onClick}>
        <Space align="center">
          <ClearOutlined />
          <span>Clear search filters</span>
        </Space>
      </Button>
    </Col>
  )
}

ClearFormFiltersButton.defaultProps = {
  onClick: undefined,
}

export default ClearFormFiltersButton
