import { Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import { generatePath, useHistory } from 'react-router'

import { handleMutationResult } from '../../apollo'
import {
  Campaign,
  CreateCampaignTemplateInput,
  DiscountValueType,
  TargetGroupType,
  useCreateCampaignTemplateMutation,
  useVouchersQuery,
  VoucherBatch,
  VoucherBatchTemplateInput,
  VoucherEdge,
  VoucherInput,
} from '../../apollo/generated/api'
import { Routes } from '../../constants/RoutesConstants'
import VoucherTemplate from '../Vouchers/VoucherTemplate'

type Props = {
  campaign: Campaign
  visible: boolean
  onClose: () => void
}

const VOUCHERS_PAGE_SIZE = 100

const CampaignTemplateCreateModal: React.FC<Props> = ({ campaign, visible, onClose }) => {
  const history = useHistory()
  const [voucher, setVoucher] = useState<VoucherInput>()
  const { data: vouchersQueryResult } = useVouchersQuery({
    variables: { filters: { campaignId: campaign.id }, first: VOUCHERS_PAGE_SIZE },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  })

  const [createCampaignTemplate, { loading: creating }] = useCreateCampaignTemplateMutation()

  useEffect(() => {
    if (vouchersQueryResult) {
      const voucherEdges = vouchersQueryResult?.vouchers?.edges as VoucherEdge[]
      if (voucherEdges.length === 1) {
        const voucherNode = voucherEdges[0].node
        // eslint-disable-next-line no-unused-expressions
        voucherNode && setVoucher(voucherNode)
      }
    }
  }, [vouchersQueryResult])

  const templateFromCampaignBuilder = (
    campaign: Campaign,
    voucher?: VoucherInput,
    voucherBatch?: VoucherBatch,
  ): CreateCampaignTemplateInput | undefined => {
    if (campaign) {
      return {
        name: campaign?.name || '',
        description: campaign?.description,
        targetGroup: campaign?.targetGroup || TargetGroupType.AllCustomers,
        platform: campaign?.platform,
        voucherSettings: campaign?.voucherSettings?.map(settings => ({
          freeShipping: settings?.freeShipping,
          ordinality: settings?.ordinality ?? 1,
          value: settings?.value,
          valueLimit: settings?.valueLimit,
          valueType: settings?.valueType ?? DiscountValueType.Fixed,
        })),
        campaignTypeId: campaign?.campaignType.id,
        deliveryDays: campaign?.deliveryDays,
        ...(voucher && {
          voucher: {
            code: voucher.code,
            totalRedemptionLimit: voucher.totalRedemptionLimit,
            redemptionLimitPerCustomer: voucher.redemptionLimitPerCustomer,
          },
        }),
        ...(voucherBatch && {
          voucherBatch: {
            quantity: voucherBatch.quantity,
            totalRedemptionLimit: voucherBatch.totalRedemptionLimit,
            redemptionLimitPerCustomer: voucherBatch.redemptionLimitPerCustomer,
          },
        }),
      }
    }

    return undefined
  }

  const onCreateTemplateHandler = () => {
    if (voucher) {
      void insert(campaign, voucher)
    }
  }

  const onVoucherCreateHandler = (value: VoucherInput | VoucherBatchTemplateInput, hasMultipleVouchers: boolean) => {
    if (campaign) {
      if (hasMultipleVouchers) {
        void insert(campaign, undefined, value as VoucherBatch)
      } else {
        void insert(campaign, value as VoucherInput, undefined)
      }
    }
  }

  const insert = async (campaign: Campaign, voucher?: VoucherInput, voucherBatch?: VoucherBatch) => {
    const templateFromCampaign = templateFromCampaignBuilder(campaign, voucher, voucherBatch)
    if (templateFromCampaign) {
      const mutation = createCampaignTemplate({
        variables: {
          input: {
            ...templateFromCampaign,
          },
        },
      })

      const { data } = await handleMutationResult(mutation, 'createCampaignTemplate', {
        notifications: {
          success: {
            title: `Template Created`,
          },
        },
      })

      if (data?.createCampaignTemplate?.successful) {
        const id = data.createCampaignTemplate.result?.id

        if (id) {
          history.push(generatePath(Routes.CAMPAIGN_TEMPLATE_DETAIL, { id }))
          onClose()
        }
      }
    }
  }

  return (
    <>
      {voucher ? (
        <Modal
          title="Are you sure?"
          open={visible}
          confirmLoading={creating}
          okText="Create Template"
          onOk={onCreateTemplateHandler}
          onCancel={onClose}
        >
          <p>New Campaign template will be created from selected campaign</p>
        </Modal>
      ) : (
        <Modal title="Add vouchers" open={visible} onCancel={onClose} footer={null} width="70%">
          <p>Add Voucher to Template</p>
          <VoucherTemplate handleSubmit={onVoucherCreateHandler} />
        </Modal>
      )}
    </>
  )
}

export default CampaignTemplateCreateModal
