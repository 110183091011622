import React from 'react'
import { PageHeader } from '@ant-design/pro-components'
import { Button } from 'antd'
import { Link } from 'react-router-dom'

import { Container } from '../Campaigns/styles'
import { ContentMargin } from '../Layout/styles'
import { Routes } from '../../constants/RoutesConstants'

import CampaignTemplateList from './CampaignTemplateList'

function CampaignTemplates(): JSX.Element {
  return (
    <>
      <PageHeader
        title="Campaign Templates"
        extra={[
          <Button className="new-template-btn" type="primary" key="newTemplateButton" style={{ float: 'right' }}>
            <Link to={Routes.CAMPAIGN_TEMPLATE_CREATE}>New Template</Link>
          </Button>,
        ]}
      />

      <Container style={ContentMargin}>
        <CampaignTemplateList />
      </Container>
    </>
  )
}

export default CampaignTemplates
