import { GoldOutlined } from '@ant-design/icons'
import { Select } from 'antd'
import React from 'react'

interface Props {
  numberOfMeals: string | undefined
  setNumberOfMeals: (value: string) => void
}

function PlanMealsDropdown({ numberOfMeals, setNumberOfMeals }: Props): JSX.Element {
  const defaultMealsList = ['2', '3', '4', '5', '6']

  return (
    <Select
      data-testid="number-meals-dropdown"
      style={{ width: '100%' }}
      placeholder={[
        <GoldOutlined style={{ color: 'black' }} key="number-meals-placeholder-icon" />,
        <span style={{ marginLeft: '5px' }} key="number-meals-placeholder-text">
          Number of meals
        </span>,
      ]}
      value={numberOfMeals}
      onChange={setNumberOfMeals}
      allowClear
    >
      {defaultMealsList.map(option => (
        <Select.Option value={option} key={option}>
          {`${option} meals`}
        </Select.Option>
      ))}
    </Select>
  )
}

export default PlanMealsDropdown
