import React from 'react'
import { Col, Form, Input, InputNumber, Row } from 'antd'
import { RuleObject } from 'antd/lib/form'

import { DatePicker } from '../../Inputs'
import { usageLimitField, customerUsageLimitField } from '../../../constants/VoucherFormConstants'
import { usageLimitValidator } from '../../../utils/voucherUtils'
import { PrimaryButton } from '../../globalStyles'

interface Props {
  isLoading?: boolean
  submitButtonText?: string
  formType: 'Campaign' | 'Template'
}

function SingleVoucherFormFields({ isLoading, submitButtonText, formType }: Props): React.ReactElement {
  return (
    <>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            name="code"
            label="Voucher code"
            rules={[{ required: true, message: 'Please type a code for your voucher.' }]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name={[usageLimitField]}
            label="Total usage limit"
            rules={[
              { required: true, message: 'Please define a usage limit' },
              ({ getFieldValue }) => ({
                validator(rule: RuleObject, value: number) {
                  return usageLimitValidator(usageLimitField, value, getFieldValue(customerUsageLimitField))
                },
              }),
            ]}
          >
            <InputNumber min={0} style={{ width: '100%' }} />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name={[customerUsageLimitField]}
            label="Usage limit per customer"
            rules={[
              { required: true, message: 'Please define a usage limit per customer' },
              ({ getFieldValue }) => ({
                validator(rule: RuleObject, value: number) {
                  return usageLimitValidator(customerUsageLimitField, value, getFieldValue(usageLimitField))
                },
              }),
            ]}
          >
            <InputNumber min={0} style={{ width: '100%' }} />
          </Form.Item>
        </Col>
      </Row>

      {formType === 'Campaign' && (
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="expiresAt" label="Expiration date">
              <DatePicker
                style={{ width: '100%' }}
                data-testid="expiration-date-calendar"
                className="expiration-date-calendar"
                placeholder="Select an Expiration Date (optional)"
                format="YYYY-MM-DD"
              />
            </Form.Item>
          </Col>
        </Row>
      )}

      <Row gutter={16} justify="center">
        <Col span={8}>
          <PrimaryButton size="large" htmlType="submit" block loading={isLoading}>
            {submitButtonText}
          </PrimaryButton>
        </Col>
      </Row>
    </>
  )
}

SingleVoucherFormFields.defaultProps = {
  isLoading: false,
  submitButtonText: 'Create Voucher',
}

export default SingleVoucherFormFields
