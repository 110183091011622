import { Breadcrumb } from 'antd'
import React from 'react'
import { useLocation } from 'react-router'

import { StyledBreadCrumb } from './styles'

type Props = {
  // eslint-disable-next-line react/require-default-props
  param?: { name: string; value: string }
}

const BreadCrumbs: React.FC<Props> = ({ param = { name: '_', value: '_' } }) => {
  const { pathname } = useLocation()

  const BreadcrumbMapping: { [key: string]: string } = {
    '/campaigns': 'Campaigns',
    '/campaigns/new': 'Create Campaign',
    '/campaigns/:id': 'Campaign Details',
    '/campaigns/:id/edit': 'Edit Campaign',
    '/campaigns/:id/vouchers/new': 'Create Voucher',
    '/templates': 'Templates',
    '/templates/:id': 'Template Details',
    '/templates/:id/generate/campaigns': 'Campaigns Generator',
    '/vouchers/:id': 'Voucher Details',
    '/vouchers': 'Vouchers',
    '/vouchers/:id/edit': 'Edit Voucher',
    '/referral-programs': 'Referral Programs',
    '/referral-programs/:id/edit': 'Edit Program',
    '/rewards/campaigns': 'Reward Campaigns',
    '/rewards/campaigns/new': 'New',
    '/rewards/campaigns/:id/edit': 'Edit',
    '/users': 'Manage Users',
  }

  const splitPath = pathname
    .replace(param.value, `:${param.name}`)
    .split('/')
    .filter(path => path !== '')
  let redirect = ''

  return (
    <StyledBreadCrumb>
      {splitPath.map((path, index) => {
        redirect += `/${path}`
        const isValidPath = BreadcrumbMapping[redirect]

        return isValidPath ? (
          // eslint-disable-next-line react/no-array-index-key
          <Breadcrumb.Item key={index}>
            <a href={redirect.replace(`:${param.name}`, param.value)}>{isValidPath}</a>
          </Breadcrumb.Item>
        ) : undefined
      })}
    </StyledBreadCrumb>
  )
}

export default BreadCrumbs
