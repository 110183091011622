import { SearchOutlined } from '@ant-design/icons'
import { Card, Col, Form, Row } from 'antd'
import React from 'react'

import BrandDropdown from '../../BrandDropdown'
import CountryDropdown from '../../CountryDropdown'
import { PrimaryButton } from '../../globalStyles'

export interface FormData {
  brandId?: string
  countryId?: string
}

interface Props {
  onSubmit?: (data: FormData) => void
}

function ReferralProgramsFilter({ onSubmit }: Props): JSX.Element {
  return (
    <Card style={{ marginBottom: 25 }}>
      <Form onFinish={values => onSubmit?.(values)} layout="vertical">
        <Row gutter={16} align="bottom">
          <Col span={10}>
            <CountryDropdown />
          </Col>

          <Col span={10}>
            <BrandDropdown />
          </Col>

          <Col span={4}>
            <Form.Item>
              <PrimaryButton type="primary" htmlType="submit" icon={<SearchOutlined />} block>
                Search
              </PrimaryButton>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  )
}

ReferralProgramsFilter.defaultProps = {
  onSubmit: undefined,
}

export default ReferralProgramsFilter
