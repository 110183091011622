import { Form, Select } from 'antd'
import React, { useEffect } from 'react'

import { CampaignType } from '../../../apollo/generated/rewards'

interface Props {
  label?: string | null
  required?: boolean
  disabled?: boolean
  onClear?: (fieldName: string) => void
}

export const CAMPAIGN_TYPES = {
  [CampaignType.ProgressBased]: 'Progress based',
  [CampaignType.ReferralReward]: 'Refferal reward',
}

const TypeDropdown: React.FC<Props> = ({ label, required, disabled, onClear }: Props) => {
  const [isMounted, setIsMounted] = React.useState(false)
  const onClearHandler = () => onClear?.('type')

  useEffect(() => {
    setIsMounted(true)

    return () => {
      setIsMounted(false)
    }
  }, [])

  return (
    <Form.Item
      labelCol={{ span: 24 }}
      name="type"
      label={label}
      rules={[{ required, message: 'Please select a type!' }]}
    >
      <Select
        data-testid="type-dropdown"
        style={{ width: '100%' }}
        placeholder="Select Type"
        optionFilterProp="children"
        disabled={disabled}
        showSearch
        allowClear
        onClear={onClearHandler}
      >
        {isMounted && (
          <>
            <Select.Option value={CampaignType.ProgressBased} key={CampaignType.ProgressBased}>
              {CAMPAIGN_TYPES[CampaignType.ProgressBased]}
            </Select.Option>
            <Select.Option value={CampaignType.ReferralReward} key={CampaignType.ReferralReward}>
              {CAMPAIGN_TYPES[CampaignType.ReferralReward]}
            </Select.Option>
          </>
        )}
      </Select>
    </Form.Item>
  )
}

TypeDropdown.defaultProps = {
  label: 'Type',
  required: false,
  disabled: false,
  onClear: undefined,
}

export default TypeDropdown
