import { Col, Divider, Form, Input, Row, Select, Typography } from 'antd'
import React from 'react'
import TextArea from 'antd/lib/input/TextArea'

import { DatePicker } from '../../Inputs'
import CountryDropdown from '../CountryDropdown'
import BrandDropdown from '../BrandDropdown'

import RewardCardPreview from './RewardCardPreview'

type Props = {
  timezone: string
}

const RewardCampaignsGeneralStep: React.FC<Props> = ({ timezone }) => {
  const formInstance = Form.useFormInstance()

  const campaignName = Form.useWatch('name', formInstance)
  const campaignDescription = Form.useWatch('description', formInstance)

  return (
    <>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item labelCol={{ span: 24 }} name="internalName" label="Internal Name">
            <Input placeholder="Internal name" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <BrandDropdown required />
        </Col>

        <Col span={12}>
          <CountryDropdown required />
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="validity"
            label="Validity"
            help={
              'It will be active between the selected dates (beginning of the day to ' +
              `the end of the day, respectively). The timezone is ${timezone}.`
            }
            rules={[{ required: true, message: 'Please select a validity period.' }]}
          >
            <DatePicker.RangePicker style={{ width: '100%' }} allowEmpty={[false, true]} />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item name="type" label="Challenge type" rules={[{ required: true, message: 'Please select a type.' }]}>
            <Select
              placeholder="Select Type"
              options={[
                {
                  value: 'REFERRAL_REWARD',
                  label: 'Referral Reward',
                },
                {
                  value: 'PROGRESS_BASED',
                  label: 'Progress-Based',
                },
              ]}
            />
          </Form.Item>
        </Col>
      </Row>

      <Divider style={{ marginTop: 40, marginBottom: 40 }} />

      <Typography.Title level={3} style={{ marginBottom: 20 }}>
        Public view
      </Typography.Title>

      <Row gutter={32}>
        <Col span={24} lg={10}>
          <RewardCardPreview name={campaignName} description={campaignDescription} />
        </Col>

        <Col span={24} lg={14}>
          <Form.Item
            labelCol={{ span: 24 }}
            name="name"
            label="Name"
            rules={[{ required: true, message: 'Please type a name for your campaign.' }]}
          >
            <Input placeholder="Name" maxLength={30} showCount />
          </Form.Item>

          <Form.Item
            labelCol={{ span: 24 }}
            name="description"
            label="Description"
            rules={[{ required: true, message: 'Please add a description to this campaign.' }]}
          >
            <TextArea maxLength={75} showCount />
          </Form.Item>
        </Col>
      </Row>
    </>
  )
}

export default RewardCampaignsGeneralStep
