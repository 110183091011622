import { UserOutlined } from '@ant-design/icons'
import { Select } from 'antd'
import React from 'react'

interface Props {
  numberOfPersons: string | undefined
  setNumberOfPersons: (value: string) => void
}

function PlanPeopleDropdown({ numberOfPersons, setNumberOfPersons }: Props): JSX.Element {
  const defaultPersonsList = ['2', '4']

  return (
    <Select
      data-testid="number-people-dropdown"
      style={{ width: '100%' }}
      placeholder={[
        <UserOutlined style={{ color: 'black' }} key="number-people-placeholder-icon" />,
        <span style={{ marginLeft: '5px' }} key="number-people-placeholder-text">
          Number of people
        </span>,
      ]}
      value={numberOfPersons}
      onChange={setNumberOfPersons}
      allowClear
    >
      {defaultPersonsList.map(option => (
        <Select.Option value={option} key={option}>
          {`${option} people`}
        </Select.Option>
      ))}
    </Select>
  )
}

export default PlanPeopleDropdown
