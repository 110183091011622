import React from 'react'
import { Card, Col, Divider, Radio, Space } from 'antd'
import styled from 'styled-components'

type PropType = {
  onChange: (value: string) => void
  value?: string
}

const CampaignCommunicationParameters = ({ onChange, value }: PropType) => {
  const OPTIONS: { [label: string]: string }[] = [
    // all options except % off and $ off will be commented out for now.
    // as soon as the funnel is able to handle more formats, those will be added back

    // {
    //   label: 'Discounted box Price',
    //   value: 'DISCOUNT_BOX_PRICE',
    // },
    // { label: 'Discounted serving price', value: 'DISCOUNT_SERVING_PRICE' },
    {
      label: '% Off',
      value: 'PERCENTAGE_OFF',
    },
    {
      label: '$ Off',
      value: 'MONETARY_VALUE_OFF',
    },
    // {
    //   label: 'Total free meals',
    //   value: 'TOTAL_FREE_MEALS',
    // },
    // {
    //   label: 'Total voucher amount',
    //   value: 'TOTAL_VOUCHER_AMOUNT',
    // },
  ]

  return (
    <Space
      direction="vertical"
      size="small"
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <p style={{ marginTop: '20px' }}>Select how the banner will display the promotion</p>
      <StyledCard className="campaign-communication-radio-wrapper">
        <Radio.Group
          style={{ display: 'flex', justifyContent: 'center' }}
          onChange={e => onChange(e.target.value)}
          value={value}
        >
          {OPTIONS.map((parameter, index) => {
            return (
              <span style={{ display: 'flex', justifyContent: 'center' }} key={parameter.value}>
                <StyledCol>
                  <Radio value={parameter.value}>
                    <StyledLabel>{parameter.label}</StyledLabel>
                  </Radio>
                </StyledCol>

                {Number(index) < OPTIONS.length - 1 && (
                  <StyledCol>
                    <Divider type="vertical" style={{ height: '100%' }} />
                  </StyledCol>
                )}
              </span>
            )
          })}
        </Radio.Group>
      </StyledCard>
    </Space>
  )
}

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: row;
  justify-content: center;
`

const StyledLabel = styled.div`
  font-weight: 600;
  font-size: 14px;
  text-align: center;
`

const StyledCol = styled(Col)`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  .ant-form-item-control-input {
    .ant-form-item-control-input-content {
      .ant-checkbox-wrapper {
        position: absolute;
        bottom: 0;
      }
    }
  }
`
export default CampaignCommunicationParameters
